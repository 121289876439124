import { memo } from "react";

import cx from "classnames";

import NoImage from "@app/assets/images/no_image_card.png";
import Image from "@app/components/atoms/Image/Image";

import styles from "./GachaCardImage.module.scss";

interface GachaCardImageProps {
  imageCard: string | null;
  type: string;
  className?: string;
}

const GachaCardImage = ({
  imageCard,
  type,
  className,
}: GachaCardImageProps) => {
  return (
    <div className={cx(styles[`card-${type}`], className)}>
      <div className={styles.content}>
        <Image className={styles.content} src={imageCard ?? NoImage} />
      </div>
    </div>
  );
};

export default memo(GachaCardImage);
