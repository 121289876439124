import { memo } from "react";

import cx from "classnames";

import styles from "./StatusPack.module.scss";

const StatusPack = ({
  status,
  className,
  text,
}: {
  status?: string;
  className?: string;
  text?: string;
}) => {
  const getStatus = () => {
    switch (status) {
      case "on_sale":
        return {
          text: "開催中",
          style: "onSale",
        };
      default:
        return {
          text: "開催終了",
          style: "soldOut",
        };
    }
  };
  const getText = () => {
    switch (text) {
      case "美品":
        return {
          text: "美品",
          style: "beauty",
        };
      case "良品":
        return {
          text: "良品",
          style: "good",
        };
      case "キズあり":
        return {
          text: "キズあり",
          style: "scratched",
        };
      case "取扱店舗":
        return {
          text: "取扱店舗",
          style: "scratched",
        };
      default:
        return {
          text: "",
          style: "scratched",
        };
    }
  };
  return (
    <div
      className={cx(
        styles.status,
        styles[getStatus().style],
        styles[getText()?.style],
        className
      )}
    >
      {status && getStatus().text}
      {text && getText()?.text}
    </div>
  );
};

export default memo(StatusPack);
