import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";

import { AuthPathsEnum } from "@app/features/auth/auth";

import { UserAddress } from "../../auth/types/auth.types";
import api from "../api/user-addresses.api";
import { USER_ADDRESSES_FEATURE_KEY } from "../constants/user-addresses.keys";
import { UserAddressList } from "../types/user-addresses.types";

export type UserAddressesState = {
  userAddressList: UserAddressList | null;
  userAddressDetail: UserAddress | null;
};

const initialState: UserAddressesState = {
  userAddressList: null,
  userAddressDetail: null,
};

export const getUserAddressList = createAsyncThunk(
  `${USER_ADDRESSES_FEATURE_KEY}/getUserAddressList`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.userAddressListApi();
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const updateUserAddress = createAsyncThunk(
  `${USER_ADDRESSES_FEATURE_KEY}/updateUserAddress`,
  async (
    { userAddressId, data }: { userAddressId: string; data: UserAddress },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.updateUserAddressApi({ userAddressId, data });
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const deleteUserAddress = createAsyncThunk(
  `${USER_ADDRESSES_FEATURE_KEY}/deleteUserAddress`,
  async (userAddressId: string, { rejectWithValue }) => {
    try {
      const response = await api.deleteUserAddressApi(userAddressId);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const createUserAddress = createAsyncThunk(
  `${USER_ADDRESSES_FEATURE_KEY}/createUserAddress`,
  async ({ data }: { data: UserAddress }, { rejectWithValue }) => {
    try {
      const response = await api.createUserAddressApi({ data });
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getUserAddressDetail = createAsyncThunk(
  `${USER_ADDRESSES_FEATURE_KEY}/getUserAddressDetail`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.userAddressDetailApi(id);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const userAddressesSlice = createSlice({
  name: USER_ADDRESSES_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getUserAddressList.pending, state => {
      state.userAddressList = null;
    });
    builder.addCase(getUserAddressList.fulfilled, (state, action) => {
      state.userAddressList = action.payload;
    });
    builder.addCase(getUserAddressList.rejected, state => {
      state.userAddressList = null;
    });

    builder.addCase(getUserAddressDetail.pending, state => {
      state.userAddressDetail = null;
    });
    builder.addCase(getUserAddressDetail.fulfilled, (state, action) => {
      if (isEmpty(action.payload)) {
        window.location.href = AuthPathsEnum.NOT_FOUND;
      } else {
        state.userAddressDetail = action.payload;
      }
    });
    builder.addCase(getUserAddressDetail.rejected, state => {
      state.userAddressDetail = null;
    });
  },
});

export const userAddressesReducer = userAddressesSlice.reducer;
