import { memo, useMemo } from "react";

import { Col, Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";

import PointChip from "@app/components/atoms/PointChip/PointChip";
import ConfirmDialog from "@app/components/molecules/ConfirmDialog/ConfirmDialog";
import { RootState } from "@app/redux/root-reducer";
import { useAppSelector } from "@app/redux/store";

import GachaCardImage from "../GachaCardImage/GachaCardImage";
import styles from "./ExchangeGachaModal.module.scss";

interface ExchangeGachaModalProps {
  open: boolean;
  isDisableBtnSubmit?: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const ExchangeGachaModal = ({
  open,
  isDisableBtnSubmit,
  onClose,
  onSubmit,
}: ExchangeGachaModalProps) => {
  const {
    gacha_histories: { gachaHistoriesChecked },
    auth: { currentUser },
  } = useAppSelector((state: RootState) => state);

  const { md } = useBreakpoint();

  const estimatePoint = useMemo(() => {
    const currentPoint = currentUser?.total_point ?? 0;
    const totalPointChecked = gachaHistoriesChecked.reduce(
      (total, curr) => total + curr.point,
      0
    );
    return totalPointChecked + currentPoint;
  }, [currentUser?.total_point, gachaHistoriesChecked]);

  return (
    <ConfirmDialog
      okText="変換"
      title="ポイントへ変換します"
      open={open}
      width={md ? 885 : 333}
      isDisableBtnSubmit={isDisableBtnSubmit}
      isLoading={isDisableBtnSubmit}
      handleCancel={onClose}
      handleSubmit={onSubmit}
      className={styles.gachaConfirm}
    >
      <>
        <div className={cx(styles.gachaBox)}>
          <Row gutter={[16, 13]}>
            {gachaHistoriesChecked.map(item => (
              <Col key={item.id} md={6} xs={8}>
                <GachaCardImage
                  imageCard={item.image_url}
                  type={item.type}
                  className={styles.gachaImg}
                />
              </Col>
            ))}
          </Row>
        </div>
        <div className={cx(styles.label)}>
          {`${gachaHistoriesChecked.length}枚のカードを変換します。\nよろしいですか？`}
        </div>
        <div className={cx(styles.exchangeBox)}>
          <PointChip
            label={currentUser?.total_point}
            className={styles.pointChip}
          />
          <div className={`text-center my-${md ? 3 : 2}`}>▼</div>
          <PointChip label={estimatePoint} className={styles.pointChip} />
        </div>
      </>
    </ConfirmDialog>
  );
};

export default memo(ExchangeGachaModal);
