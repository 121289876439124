/* eslint-disable no-irregular-whitespace */
import { Typography } from "antd";
import cx from "classnames";

import Helmet from "@app/components/molecules/Helmet/Helmet";
import { PAGE_INFO } from "@app/constants/constants";

import styles from "../StaticPage.module.scss";

const CommercialLawScreen = () => {
  return (
    <>
      <Helmet
        title={PAGE_INFO["UA-0052"].title}
        description={PAGE_INFO["UA-0052"].description}
      />
      <div className={cx(styles.root, "container pt-5 pb-10")}>
        <Typography.Title level={2}>特定商取引法に基づく表記</Typography.Title>

        <Typography.Title level={3}>送料・手数料について</Typography.Title>
        <Typography>全商品、送料無料です。</Typography>

        <Typography.Title level={3}>
          役務または商品の引き渡し時期
        </Typography.Title>
        <Typography>
          ・POINTについて、お支払い手続き完了後、直ちにご利用いただけます。
        </Typography>
        <Typography>
          ・発送ご依頼商品について、ご注文確認後、即時、受領確認メールをお送りいたします。
        </Typography>
        <Typography>
          ・出庫依頼の完了から14営業日以内に発送いたします。
        </Typography>

        <Typography.Title level={3}>お支払い・お支払い期限</Typography.Title>
        <Typography>
          クレジットカード/銀行入金が使用できます。
          <br />
          クレジットカード決済はご注文時に決済が完了します。
        </Typography>

        <Typography.Title level={3}>販売価格</Typography.Title>
        <Typography>
          Pt購入画面に表示されておりますのでご参照ください。
        </Typography>

        <Typography.Title level={3}>
          契約の申込みの撤回又は契約の解除について
        </Typography.Title>
        <Typography>
          お客様都合による契約の申込みの撤回又は契約の解除はできません。
        </Typography>

        <Typography.Title level={3}>返品について</Typography.Title>
        <Typography>
          ・サービスの性質上、POINTの返金はお受けしておりません。
          <br />
          ・商品について、基本的に返品はお受けしておりません。
          <br />
          ・返品の対象となるケース。
          <br />
          商品ページにお客様にお送りする実物の写真を掲載しております。
          万が一、商品ページに掲載されている品目と異なる商品が送られてきた場合は、返品対応させていただきます。
          商品到着から3日以内にメールもしくはお問い合わせフォームにてご連絡ください。返品の際の送料は、弊社にて負担させていただきます。
          それ以外の返品のご依頼については、対応しかねる場合がございます。予めご了承下さい。
        </Typography>

        <Typography.Title level={3}>商品の状態確認について</Typography.Title>
        <Typography>
          商品の状態につきましては、必ずご確認をお願い致します。
          <br />
          原則、発送のご依頼完了時点で状態についてはご了承済みと見なしております。
          その為、状態による返品交換・返金対応は致しかねます。予めご了承ください。
        </Typography>

        <Typography.Title level={3}>商品の保管期間について</Typography.Title>
        <Typography>
          商品の保有期間は、原則獲得日から計算して２週間となります。
          <br />
          ２週間が経過したカードは自動的に
          POINTへ変換されますのでご了承ください。
        </Typography>

        <Typography.Title level={3}>POINTの保有期間について</Typography.Title>
        <Typography>
          POINTの保有期間は、原則３ヶ月となります。
          <br />
          ３ヶ月が経過したPOINTは自動的に破棄されますのでご了承ください。
        </Typography>

        <Typography.Title level={3}>資格・免許</Typography.Title>
        <Typography>古物商　埼玉県公安委員会許可第431040058906 号</Typography>

        <Typography.Title level={3}>サービス名</Typography.Title>
        <Typography>トレカボールガチャ</Typography>

        <Typography.Title level={3}>事業者の名称および連絡先</Typography.Title>
        <Typography>
          会社名： 株式会社Aja State
          <br />
          代表者（又は業務の責任者）：大橋　陽介
          <br />
          本社住所： 351-0115
          <br />
          埼玉県和光市新倉1-8-7-2
          <br />
          メールアドレス： info@ajastate.com
          <br />
          TEL: 03-5817-4332
        </Typography>
      </div>
    </>
  );
};

export default CommercialLawScreen;
