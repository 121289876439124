import { memo } from "react";

import cx from "classnames";
import { Link } from "react-router-dom";

import Logo from "@app/assets/images/logo.png";
import Image from "@app/components/atoms/Image/Image";

import styles from "./TemplateAuth.module.scss";

interface TemplateAuthProps {
  title?: string;
  className?: string;
  children?: React.ReactNode;
}

const TemplateAuth = ({ title, children, className }: TemplateAuthProps) => {
  return (
    <div className={cx(styles.container, className)}>
      <div className="text-center">
        <Link to="/">
          <Image className={styles.logo} src={Logo} />
        </Link>
      </div>
      {title && <div className={styles.textTitle}>{title}</div>}
      {children}
    </div>
  );
};

export default memo(TemplateAuth);
