import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";

import { ParamsDef } from "@app/types/route.types";

import api from "../api/point-exchanges.api";
import { POINT_EXCHANGES_FEATURE_KEY } from "../point-exchanges";
import {
  HistoriesDetailEpsilonParams,
  PaymentLinkDef,
  PointExchangesList,
} from "../types/point-exchanges.types";

export type PointExchangesState = {
  pointList: PointExchangesList | null;
};

const initialState: PointExchangesState = {
  pointList: null,
};

export const getListPoint = createAsyncThunk(
  `${POINT_EXCHANGES_FEATURE_KEY}/getListPoint`,
  async (params: ParamsDef, { rejectWithValue }) => {
    try {
      const response = await api.getPointExchangesApi(params);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const createPaymentLink = createAsyncThunk<
  { url: string },
  {
    id: string;
    data: PaymentLinkDef;
  }
>(
  `${POINT_EXCHANGES_FEATURE_KEY}/createPaymentLink`,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await api.createPaymentLinkApi(id, data);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const paymentHistoriesDetail = createAsyncThunk(
  `${POINT_EXCHANGES_FEATURE_KEY}/paymentHistoriesDetail`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.paymentHistoriesDetailApi(id);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const paymentHistoriesDetailPaypal = createAsyncThunk(
  `${POINT_EXCHANGES_FEATURE_KEY}/paymentHistoriesDetailPaypal`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.paymentHistoriesDetailPaypalApi(id);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const paymentHistoriesDetailEpsilon = createAsyncThunk(
  `${POINT_EXCHANGES_FEATURE_KEY}/paymentHistoriesDetailPaypal`,
  async (data: HistoriesDetailEpsilonParams, { rejectWithValue }) => {
    try {
      const response = await api.paymentHistoriesDetailEpsilonApi(data);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const createPaymentBankTransfer = createAsyncThunk(
  `${POINT_EXCHANGES_FEATURE_KEY}/createPaymentBankTransfer`,
  async (pointExchangeId: string, { rejectWithValue }) => {
    try {
      const response = await api.createPaymentBankTransferApi(pointExchangeId);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const updatePaymentHistories = createAsyncThunk(
  `${POINT_EXCHANGES_FEATURE_KEY}/updatePaymentHistories`,
  async (
    {
      id,
      data,
    }: {
      id: string;
      data: {
        image: File;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.updatePaymentHistoriesApi({ id, data });
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const pointExchangesSlice = createSlice({
  name: POINT_EXCHANGES_FEATURE_KEY,
  reducers: {},
  initialState,
  extraReducers: builder => {
    builder.addCase(getListPoint.pending, state => {
      state.pointList = null;
    });
    builder.addCase(getListPoint.fulfilled, (state, action) => {
      state.pointList = action.payload;
    });
    builder.addCase(getListPoint.rejected, state => {
      state.pointList = null;
    });
  },
});

export const pointExchangesReducer = pointExchangesSlice.reducer;
