import cx from "classnames";
import { Link } from "react-router-dom";

import Logo from "@app/assets/images/logo.png";
import Button from "@app/components/atoms/Button/Button";
import Image from "@app/components/atoms/Image/Image";

import { AuthPathsEnum } from "../../auth";
import styles from "./TokenExpirationScreen.module.scss";

const TokenExpirationScreen = () => {
  return (
    <>
      <div className={cx(styles.container)}>
        <div className="text-center">
          <Link to="/">
            <Image className={cx(styles.logo)} src={Logo} />
          </Link>
        </div>
        <div className="text-center">
          <div className={cx(styles.title)}>
            <span className={cx(styles.usableContent)}>
              URLの有効期限が切れました。
            </span>
            <br />
            <span className={cx(styles.usableContent)}>
              ログイン画面からやり直してください。
            </span>
          </div>
        </div>

        <div className={cx(styles.btnGroup)}>
          <Button
            type="primary"
            danger
            className={cx(styles.btn)}
            htmlType="button"
            withRightArrow
            to={AuthPathsEnum.LOGIN}
          >
            ログイン
          </Button>
        </div>
      </div>
    </>
  );
};

export default TokenExpirationScreen;
