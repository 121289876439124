import { memo } from "react";

import { Typography } from "antd";
import cx from "classnames";

import styles from "./GuideTitle.module.scss";

interface GuideTitleProps {
  title: string;
  subTitle: string;
  className?: string;
}

const GuideTitle = ({ title, subTitle, className }: GuideTitleProps) => {
  return (
    <div className={cx("text-center font-weight-bold", className)}>
      <Typography className={cx(styles.title)}>{title}</Typography>
      <Typography className={cx(styles.subTitle, "mt-1")}>
        {subTitle}
      </Typography>
    </div>
  );
};

export default memo(GuideTitle);
