import { AxiosPromise } from "axios";

import { api } from "@app/api/api";
import { ParamsDef } from "@app/types/route.types";

import { PointExchangesEndpoints } from "../constants/point-exchanges.endpoints";
import {
  HistoriesDetailEpsilonParams,
  PaymentLinkDef,
} from "../types/point-exchanges.types";

const getPointExchangesApi = (params?: ParamsDef): Promise<AxiosPromise> => {
  const url = PointExchangesEndpoints.POINT_EXCHANGES;
  return api.get(url, { params });
};

const createPaymentLinkApi = (
  id: string,
  data: PaymentLinkDef
): Promise<AxiosPromise> => {
  const url = PointExchangesEndpoints.CREATE_PAYMENT_LINK.replace(":id", id);
  return api.post(url, data);
};

const paymentHistoriesDetailEpsilonApi = (
  data: HistoriesDetailEpsilonParams
): Promise<AxiosPromise> => {
  const url = PointExchangesEndpoints.PAYMENT_HISTORY_DETAIL_EPSILON;
  return api.post(url, data);
};

const paymentHistoriesDetailApi = (id: string): Promise<AxiosPromise> => {
  const url = PointExchangesEndpoints.PAYMENT_HISTORY_DETAIL.replace(
    ":paymentId",
    id
  );
  return api.get(url);
};

const paymentHistoriesDetailPaypalApi = (id: string): Promise<AxiosPromise> => {
  const url = PointExchangesEndpoints.PAYMENT_HISTORY_DETAIL_PAYPAL.replace(
    ":paymentId",
    id
  );
  return api.post(url);
};

const createPaymentBankTransferApi = (
  pointExchangeId: string
): Promise<AxiosPromise> => {
  const url = PointExchangesEndpoints.PAYMENT_BANK_TRANSFER_CREATE.replace(
    ":pointExchangeId",
    pointExchangeId
  );
  return api.post(url);
};

const updatePaymentHistoriesApi = ({
  id,
  data,
}: {
  id: string;
  data: {
    image: File;
  };
}): Promise<AxiosPromise> => {
  const url = PointExchangesEndpoints.PAYMENT_HISTORY_EDIT.replace(
    ":paymentId",
    id
  );

  const formData = new FormData();
  formData.append("image", data.image);

  return api.post(url, formData, {
    headers: {
      "Content-Type": `multipart/form-data; boundary=${Math.random()
        .toString()
        .substr(2)}`,
      "cache-control": "no-cache",
    },
    params: {
      _method: "PATCH",
    },
    transformRequest: () => {
      return formData;
    },
  });
};

const pointExchangesApi = {
  getPointExchangesApi,
  createPaymentLinkApi,
  paymentHistoriesDetailApi,
  paymentHistoriesDetailPaypalApi,
  paymentHistoriesDetailEpsilonApi,
  updatePaymentHistoriesApi,
  createPaymentBankTransferApi,
};

export default pointExchangesApi;
