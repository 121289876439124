import { useEffect } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { getQueryParams } from "@app/helpers/queryParams/queryParams";
import { useAppDispatch } from "@app/redux/store";

import {
  PAYMENT_STATUS,
  PointExchangesPathsEnum,
  paymentHistoriesDetailEpsilon,
} from "../../point-exchanges";

const EpsilonPointExchangeScreenSuccess = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { order_number, trans_code, user_id } = getQueryParams(
    window.location.search
  );

  useEffect(() => {
    if (order_number && trans_code && user_id) {
      dispatch(
        paymentHistoriesDetailEpsilon({
          order_number: String(order_number),
          trans_code: String(trans_code),
          user_id: String(user_id),
        })
      )
        .then(unwrapResult)
        .then(data => {
          if (data.status === PAYMENT_STATUS.SUCCESS) {
            history.push(PointExchangesPathsEnum.POINT_EXCHANGES, data.point);
          } else {
            history.push(PointExchangesPathsEnum.POINT_EXCHANGES);
          }
        })
        .catch(() => history.push(PointExchangesPathsEnum.POINT_EXCHANGES));
    } else {
      history.push(PointExchangesPathsEnum.POINT_EXCHANGES);
    }
  }, [dispatch, history, order_number, trans_code, user_id]);

  return <LoadingSpinner />;
};

export default EpsilonPointExchangeScreenSuccess;
