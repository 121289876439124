import { memo } from "react";

import { Checkbox } from "antd";

import Button from "@app/components/atoms/Button/Button";
import Card from "@app/components/molecules/Card/Card";

const Template = () => {
  return (
    <div>
      <div className="mt-10">
        <Button width="200px" type="primary">
          <div>ログイン</div>
        </Button>
        <Button shape="round">default round</Button>
        <Button>default</Button>
        <Button danger>default danger</Button>
        <Button className="yellow">default yellow</Button>
        <Button type="primary" danger>
          ログイン
        </Button>
        <Button type="primary" withRightArrow danger>
          danger
        </Button>

        <Checkbox>s</Checkbox>

        <div className="py-10 flex-space-between flex-wrap gap-20">
          <Card type="vip" />
          <Card type="vip" />
          <Card type="vip" />
          <Card type="vip" />
          <Card type="vip" />
          <Card type="vip" />
          <Card type="sliver" />
          <Card type="normal" />
        </div>
      </div>
    </div>
  );
};

export default memo(Template);
