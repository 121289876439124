import { memo } from "react";

import { Modal, ModalProps } from "antd";
import cx from "classnames";

import Button from "@app/components/atoms/Button/Button";

import styles from "./ConfirmDialog.module.scss";

interface ConfirmDialogProps extends ModalProps {
  open: boolean;
  handleSubmit?: () => void;
  handleCancel?: () => void;
  isDisableBtnSubmit?: boolean;
  className?: string;
  isHideButtonCancel?: boolean;
  isLoading?: boolean;
}

const ConfirmDialog = ({
  okText = "OK",
  cancelText = "キャンセル",
  open,
  handleSubmit,
  handleCancel,
  children,
  isDisableBtnSubmit,
  className,
  isHideButtonCancel,
  isLoading,
  ...rest
}: ConfirmDialogProps) => {
  return (
    <Modal
      open={open}
      onOk={handleSubmit}
      onCancel={handleCancel}
      centered
      maskClosable={false}
      className={cx(styles.dialog, className)}
      closeIcon={<span className="icon-close-icon font-18" />}
      footer={[
        isHideButtonCancel ? (
          <></>
        ) : (
          <Button
            borderRadius="26px"
            onClick={handleCancel}
            className={cx(styles.btn, "gray")}
          >
            {cancelText}
          </Button>
        ),
        <Button
          type="primary"
          danger
          borderRadius="26px"
          disabled={isDisableBtnSubmit}
          loading={isLoading}
          onClick={() => {
            handleSubmit?.();
          }}
          className={styles.btn}
        >
          {okText}
        </Button>,
      ]}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default memo(ConfirmDialog);
