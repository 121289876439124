export enum ShopsPathsEnum {
  SHOP_DETAILS = "/shops/:id",
  SHOP_LIST = "/",
  PACK_DETAILS = "/shops/:shopId/packs/:packId",
  LIST_CARD = "/shops/:id/packs/:packId/cards",
  SHOP_LIST_USER = "/users/shops",
  SHOP_LIST_USER_DETAILS = "/users/shops/:id",
  DELIVERY_SUCCESS = "/shops/:id/delivery-success",
  CONTACT = "/contact",
  CONTACT_CONFIRM = "/contact/confirm",
  CONTACT_SUCCESS = "/contact/success",
  INFO_PAGE = "/infopage/index.html",
}
