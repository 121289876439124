import { memo, ReactNode, useEffect, useState } from "react";

import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { useParams } from "react-router-dom";

import ButtonBackPage from "@app/components/atoms/ButtonBackPage/ButtonBackPage";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { textOverflow } from "@app/helpers/util.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import { getShopDetail } from "../../shops";
import ShopInfo from "../ShopInfo/ShopInfo";
import styles from "./ShopDetailsLayout.module.scss";

const ShopDetailsLayout = ({ children }: { children: ReactNode }) => {
  const [isHiddenContent, setIsHiddenContent] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { md } = useBreakpoint();

  const { shopDetail } = useAppSelector((state: RootState) => state.shops);

  useEffect(() => {
    if (id) {
      dispatch(getShopDetail(id));
    }
  }, [dispatch, id]);

  return (
    <div className={cx(styles.root)}>
      {!shopDetail ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className={cx(styles.container)}>
            <ButtonBackPage className={cx(md ? "mb-4" : "mb-2")} />
            <div
              className={cx(styles.info, {
                [styles.showInfo]: isHiddenContent,
              })}
            >
              <div
                aria-hidden="true"
                onClick={() => setIsHiddenContent(!isHiddenContent)}
                className={cx(styles.input)}
              >
                <div className={cx(styles.inputContent, "flex-align-center")}>
                  <span className="icon-map-icon font-14" />
                  <span className="font-weight-medium font-12 ml-2">
                    {textOverflow(shopDetail?.name, 12)}
                  </span>
                </div>
                <div
                  className={cx(styles.icon, {
                    [styles.show]: isHiddenContent,
                  })}
                >
                  <div className="icon-arrow-up font-7" />
                </div>
              </div>
              <div className={cx(styles.infoShop)}>
                <ShopInfo shopDetail={shopDetail} />
              </div>
            </div>
          </div>
          <div className={cx(styles.tabs)}>{children}</div>
        </>
      )}
    </div>
  );
};

export default memo(ShopDetailsLayout);
