import { useEffect, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { Link, useHistory, useParams } from "react-router-dom";

import Logo from "@app/assets/images/logo.png";
import Button from "@app/components/atoms/Button/Button";
import Image from "@app/components/atoms/Image/Image";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { useAppDispatch } from "@app/redux/store";

import {
  AuthPathsEnum,
  checkTokenValid,
  deleteLogout,
  resendConfirmation,
  TOKEN_STATUS,
  verifyEmail,
} from "../../auth";
import styles from "../RegisterOfficialMemberScreen/RegisterOfficialMemberScreen.module.scss";

const VerifyUserSuccessEmailScreen = () => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { md } = useBreakpoint();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    dispatch(deleteLogout());
    dispatch(checkTokenValid({ token }))
      .then(unwrapResult)
      .then(res => {
        if (res.auth === TOKEN_STATUS.EXPIRED) {
          dispatch(resendConfirmation(token));
          history.push(AuthPathsEnum.TOKEN_EXPIRATION);
        } else {
          dispatch(verifyEmail(token))
            .then(unwrapResult)
            .then(() => setIsLoading(false))
            .catch(() => history.push(AuthPathsEnum.TOKEN_EXPIRATION));
        }
      })
      .catch(() => {
        history.push(AuthPathsEnum.TOKEN_EXPIRATION);
      });
  }, [dispatch, history, token]);

  if (isLoading) return <LoadingSpinner />;

  return (
    <div className="text-center">
      <div className="text-center">
        <Link to="/">
          <Image className={cx(styles.logo, "mt-15")} src={Logo} />
        </Link>
      </div>
      <div className={cx(md ? "font-20" : "font-16", "font-weight-bold my-10")}>
        メールアドレス変更
      </div>
      <div
        className={cx(
          md ? "font-14" : "font-12",
          "text-center mt-5 mb-15 font-12"
        )}
      >
        <div>
          <span>登録が完了しました。</span>
        </div>
        <div>
          <span>ログインからやり直してください。</span>
        </div>
      </div>
      <div>
        <Button
          type="primary"
          danger
          className={cx(styles.btn, "mt-15")}
          htmlType="button"
          withRightArrow
          to={AuthPathsEnum.LOGIN}
        >
          ログイン
        </Button>
      </div>
    </div>
  );
};

export default VerifyUserSuccessEmailScreen;
