import { useEffect, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { useHistory, useParams } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { MODE_ACTIONS } from "@app/constants/constants";
import { UserAddress } from "@app/features/auth/auth";
import FormAddress from "@app/features/auth/components/FormAddress/FormAddress";
import { useAppDispatch } from "@app/redux/store";

import { getUserAddressDetail } from "../../user-addresses";
import styles from "./CreateUserAddressScreen.module.scss";

const CreateUserAddressScreen = () => {
  const { md } = useBreakpoint();
  const dispatch = useAppDispatch();
  const { mode, id } = useParams<{ mode: string; id: string }>();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [userAddress, setUserAddress] = useState<UserAddress | undefined>(
    undefined
  );
  const [triggerSubmitFormAddress, setTriggerSubmitFormAddress] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (mode === MODE_ACTIONS.EDIT && !!id) {
      setIsLoading(true);
      dispatch(getUserAddressDetail(id))
        .then(unwrapResult)
        .then(data => {
          setUserAddress(data);
        })
        .catch(err => {
          !!err && openNotification({ type: "warning", message: err.errors });
        })
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, id, mode]);

  if (isLoading) return <LoadingSpinner />;

  return (
    <div className={cx(styles.root)}>
      <div className={cx(styles.container)}>
        <div className={cx(styles.content)}>
          <div className={`${md ? "font-18" : "font-13 "} font-weight-bold`}>
            発送先を入力してください。
          </div>
          <FormAddress
            userAddress={userAddress}
            triggerSubmitFormAddress={triggerSubmitFormAddress}
            onSubmitFinish={() => {
              history.goBack();
              setIsSubmitting(false);
            }}
            isScreen
            setIsSubmitting={setIsSubmitting}
          />
        </div>
      </div>
      <div className={cx(styles.btnBox)}>
        <Button
          className={cx(styles.btn, md ? "mr-3" : "mr-2")}
          height={md ? "50px" : "38px"}
          type="primary"
          withLeftArrow
          shape="round"
          onClick={() => history.goBack()}
        >
          戻る
        </Button>
        <Button
          height={md ? "50px" : "38px"}
          className={styles.btn}
          type="primary"
          danger
          withRightArrow
          shape="round"
          disabled={isSubmitting}
          onClick={() => {
            setIsSubmitting(true);
            setTriggerSubmitFormAddress(triggerSubmitFormAddress + 1);
          }}
        >
          登録
        </Button>
      </div>
    </div>
  );
};

export default CreateUserAddressScreen;
