import styles from "./NotFound.module.scss";

const NotFound = () => {
  return (
    <div className={styles.container}>
      <div className={styles.textHeading}>Not Found</div>
      <div className={styles.textSecond}>こちらのURLは存在しません。</div>
      <span className={styles.text}>
        {
          "お探しのページは一時的にアクセスできない状況にあるか、\n移動もしくは削除された可能性があります。"
        }
      </span>
    </div>
  );
};

export default NotFound;
