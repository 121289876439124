import { PAGE_INFO } from "@app/constants/constants";
import { RouteItemDef } from "@app/types/route.types";

import CreateUserAddressScreen from "../screens/CreateUserAddressScreen/CreateUserAddressScreen";
import ListUserAddressScreen from "../screens/ListUserAddressScreen/ListUserAddressScreen";
import { UserAddressesPathsEnum } from "../user-addresses";

const LIST_USER_ADDRESSES_SCREEN: RouteItemDef = {
  id: "UA-0006",
  path: UserAddressesPathsEnum.LIST_USER_ADDRESSES,
  component: ListUserAddressScreen,
  isPrivateRoute: true,
  pageTitle: PAGE_INFO["UA-0006"].title,
};

const CREATE_USER_ADDRESSES_SCREEN: RouteItemDef = {
  id: "UA-0007",
  path: UserAddressesPathsEnum.CREATE_USER_ADDRESSES,
  component: CreateUserAddressScreen,
  isPrivateRoute: true,
  pageTitle: PAGE_INFO["UA-0007"].title,
};

export const USER_ADDRESSES_ROUTES = [
  LIST_USER_ADDRESSES_SCREEN,
  CREATE_USER_ADDRESSES_SCREEN,
];
