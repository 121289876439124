import { Rule } from "antd/lib/form";

import { PASSWORD_REGEX } from "@app/constants/constants";
import { ERROR_MESSAGES } from "@app/constants/message.constants";

export const formatMessageError = (
  message: string,
  list: Record<string, string>
) => {
  let newMessage = message;
  const keys = Object.keys(list);
  keys.forEach(key => {
    newMessage = newMessage.replace(`%${key}%`, list[key]);
  });
  return newMessage;
};

export const REGEX_NUMBER = /^[0-9]+$/;
export const REGEX_HIRAGANA = /^[ぁ-ん]+$/;
export const REGEX_EMAIL = /^[\w-+\\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export const emailRules: Rule[] = [
  {
    required: true,
    message: "メールアドレスは、必ず指定してください。",
  },
  {
    min: 8,
    max: 255,
    message: formatMessageError(ERROR_MESSAGES.UA_24, {
      attribute: "メールアドレ",
    }),
  },
  {
    pattern: REGEX_EMAIL,
    transform: value => value.trim(),
    message: "メールアドレスは、有効なメールアドレス形式で指定してください。",
  },
];

export const passwordRules: Rule[] = [
  {
    required: true,
    whitespace: true,
    message: "パスワードは、必ず指定してください。",
  },
  {
    min: 8,
    max: 50,
    message: formatMessageError(ERROR_MESSAGES.UA_24, {
      attribute: "パスワード",
    }),
  },
  {
    pattern: PASSWORD_REGEX,
    message: "パスワードの形式は半角英数字記号でお願いします。",
  },
];

export const phoneNumberRules: Rule[] = [
  {
    required: true,
    whitespace: true,
    message: "電話番号は、必ず指定してください。",
  },
  {
    pattern: REGEX_NUMBER,
    message: "お電話番号の形式が無効です。",
  },
  {
    min: 11,
    message: "お電話番号は、11文字から12文字にしてください。",
  },
  {
    max: 12,
    message: "お電話番号は、11文字から12文字にしてください。",
  },
];
