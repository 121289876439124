import { memo, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Checkbox, ModalProps } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { useHistory } from "react-router-dom";

import PointChip from "@app/components/atoms/PointChip/PointChip";
import ConfirmDialog from "@app/components/molecules/ConfirmDialog/ConfirmDialog";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { getCurrentUser, updateUser } from "@app/features/auth/auth";
import { postGachaHistories } from "@app/features/gacha-histories/gacha-histories";
import useGetGachaHistories from "@app/features/gacha-histories/hooks/useGetGachaHistories/useGetGachaHistories";
import { handleDirectPointPage } from "@app/features/point-exchanges/point-exchanges";
import { convertMoney } from "@app/helpers/util.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import styles from "./ModalConfirmGacha.module.scss";

interface ModalConfirmGachaProps extends ModalProps {
  open: boolean;
  numberCardRemain?: number;
  gachaCount: number | string;
  numberPointRoll: number;
  pack_id: number;
  shop_id: number;
  handleCancel?: () => void;
}

const ModalConfirmGacha = ({
  open,
  gachaCount,
  numberPointRoll,
  pack_id,
  numberCardRemain,
  handleCancel,
  ...rest
}: ModalConfirmGachaProps) => {
  const { md } = useBreakpoint();
  const { currentUser } = useAppSelector((state: RootState) => state.auth);
  const [isNoShowAlert, setIsNoShowAlert] = useState(false);
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();

  const [getGachaHistories] = useGetGachaHistories(setIsSubmitting);

  if (!currentUser) return <></>;

  const isNotEnoughMoney = currentUser?.total_point < numberPointRoll;

  const isGachaAll = String(gachaCount) === "all";

  const handleSubmit = () => {
    if (isNotEnoughMoney) return handleDirectPointPage(history);
    setIsSubmitting(true);
    if (isNoShowAlert) {
      dispatch(
        updateUser({
          no_show_alert_gacha: true,
        })
      )
        .then(unwrapResult)
        .then(() => dispatch(getCurrentUser()));
    }
    dispatch(
      postGachaHistories({
        pack_id,
        type: String(gachaCount),
        number_card: isGachaAll ? numberCardRemain : undefined,
      })
    )
      .then(unwrapResult)
      .then(res => {
        getGachaHistories({ isGacha: true, id: res.id });
      })
      .catch(err => {
        openNotification({ type: "warning", message: err.errors });
        setIsSubmitting(false);
      });
    return undefined;
  };

  return (
    <ConfirmDialog
      open={open}
      handleCancel={() => {
        setIsNoShowAlert(false);
        handleCancel?.();
      }}
      handleSubmit={handleSubmit}
      title={isNotEnoughMoney ? "Ptが不足しています" : "ガチャを回します"}
      width={md ? 504 : 333}
      okText={isNotEnoughMoney ? "購入ページへ" : "OK"}
      isDisableBtnSubmit={isSubmitting}
      isLoading={isSubmitting}
      className={cx(styles.root)}
      {...rest}
    >
      <div>
        <div
          className={`${
            md ? "font-20 mb-10 mt-5" : "font-13 mt-1 mb-4"
          } white-space-pre text-center font-weight-bold`}
        >
          {isNotEnoughMoney
            ? `ポイントが不足しています\n ポイントを購入しますか？`
            : `ポイントを${convertMoney(
                numberPointRoll
              )}使用して\n${gachaCount}回ガチャを回します\nよろしいですか？`}
        </div>
        <PointChip
          className={cx(styles.point, "flex-align-center flex-space-between")}
          label={currentUser.total_point}
        />
        <div className="text-center my-4">▼</div>
        <div>
          <PointChip
            className={cx(styles.point, "flex-align-center flex-space-between")}
            label={currentUser.total_point - numberPointRoll}
          />
        </div>
        {!isNotEnoughMoney && !isGachaAll && (
          <div className={`${md ? "mt-8" : "mt-6"} text-center`}>
            <Checkbox
              onChange={e => setIsNoShowAlert(e.target.checked)}
              checked={isNoShowAlert}
            >
              本日は表示しない
            </Checkbox>
          </div>
        )}
      </div>
    </ConfirmDialog>
  );
};

export default memo(ModalConfirmGacha);
