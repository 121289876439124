import { memo, useMemo, useCallback } from "react";

import { Progress } from "antd";
import cx from "classnames";
import { useHistory } from "react-router-dom";

import NewLabel from "@app/assets/images/new_label.png";
import Image from "@app/components/atoms/Image/Image";
import Countdown from "@app/components/molecules/CountDown/CountDown";
import { NUMBER_ROLL_GACHA } from "@app/constants/constants";
import {
  setPacksVisitedWithDevice,
  convertMoney,
  isReadyReleaseCard,
} from "@app/helpers/util.helper";
import { useAppSelector, useAppDispatch } from "@app/redux/store";

import {
  COLOR_STATUS_PACK,
  PACKS_LIST_STATUS,
  STATUS_SOLD_OUT,
  ShopsPathsEnum,
  postUserVisitPack,
} from "../../shops";
import { Pack } from "../../types/shops.types";
import styles from "./Card.module.scss";

interface CardProps {
  pack: Pack;
  isPackDetail?: boolean;
}

const Card = ({ pack, isPackDetail }: CardProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const isLogin = useAppSelector(state => state.auth.accessToken);
  const packAvailablePercent = useMemo(
    () => (pack.number_card_remain / pack.total_card) * 100,
    [pack]
  );

  const isPackVisited = useMemo(() => {
    if (isLogin) {
      return pack.new && pack.status !== PACKS_LIST_STATUS[STATUS_SOLD_OUT];
    }
    return (
      !localStorage.getItem("packsVisited")?.includes(String(pack.id)) &&
      pack.status !== PACKS_LIST_STATUS[STATUS_SOLD_OUT]
    );
  }, [pack.id, pack.status, pack.new, isLogin]);

  const strokeColor = useMemo(() => {
    const packSoldPercent = 100 - packAvailablePercent;
    if (packSoldPercent >= 70) {
      return COLOR_STATUS_PACK.RED;
    }
    if (packSoldPercent >= 40 && packSoldPercent < 70) {
      return COLOR_STATUS_PACK.YELLOW;
    }
    if (packSoldPercent < 40) {
      return COLOR_STATUS_PACK.GREEN;
    }
    return "";
  }, [packAvailablePercent]);

  const checkUserVisitPack = useCallback(() => {
    if (isLogin) {
      if (pack.new) {
        dispatch(postUserVisitPack(String(pack.id)));
      }
    } else {
      setPacksVisitedWithDevice(String(pack.id));
    }

    history.push(
      ShopsPathsEnum.PACK_DETAILS.replace(
        ":shopId",
        pack.shop_id.toString()
      ).replace(":packId", String(pack.id))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, pack.id, pack.new, isLogin, dispatch]);

  return (
    <div
      className={cx(styles.oripaContainer, {
        [styles.soldOut]: pack.status === PACKS_LIST_STATUS[STATUS_SOLD_OUT],
      })}
      aria-hidden
      onClick={checkUserVisitPack}
    >
      <div className={styles.thumbnail}>
        {pack.image_url ? (
          <Image
            className={cx(isPackDetail && styles.imageOnDetail, styles.image)}
            src={pack.image_url}
          />
        ) : (
          <div className={cx(styles.noImage)} />
        )}
      </div>

      <div
        className={cx(isPackDetail ? styles.contentOnDetail : styles.content)}
      >
        <p
          className={cx(
            styles.title,
            isPackDetail ? "font-14 mb-2" : "font-16 mb-4",
            "font-weight-bold pl-2 "
          )}
        >
          {pack.title}
        </p>
        <div>
          <div className="flex-space-between flex-align-end">
            <div
              className={cx(
                isPackDetail && styles.packPercentOnDetail,
                styles.packPercent
              )}
            >
              <div
                className={cx(
                  "flex-space-between-center font-weight-medium",
                  isPackDetail ? styles.fontDetail12 : "font-14"
                )}
              >
                <span>残り</span>
                <span className="text-end">
                  {convertMoney(pack.number_card_remain)}/
                  {convertMoney(pack.total_card)}
                </span>
              </div>
              <Progress
                percent={packAvailablePercent}
                strokeColor={strokeColor}
                showInfo={false}
              />
            </div>
            <div className="flex-align-center ml-4">
              <span
                className={cx(
                  "icon-point-icon",
                  isPackDetail ? "font-20" : "font-24"
                )}
              >
                <span className="path1" />
                <span className="path2" />
              </span>
              <span
                className={cx(
                  "ml-1 font-weight-bold",
                  isPackDetail ? "font-13" : "font-24"
                )}
              >
                {convertMoney(
                  pack.number_point_one_roll ??
                    pack.number_point_ten_roll / NUMBER_ROLL_GACHA.TEN
                )}
              </span>
            </div>
          </div>

          {isPackVisited && (
            <Image className={styles.labelNew} src={NewLabel} />
          )}
          {isReadyReleaseCard(pack.start_sale_at) && pack.start_sale_at && (
            <div className="flex-space-between-center mt-5">
              <span className="font-12 font-weight-medium">
                販売開始まであと
              </span>
              <Countdown startTime={pack.start_sale_at} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Card);
