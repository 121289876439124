/* eslint-disable no-irregular-whitespace */
import { memo, useState, useCallback, useEffect } from "react";

import { Input } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { Link, useLocation, useHistory } from "react-router-dom";

import { cancelToken } from "@app/api/api";
import XSns from "@app/assets/images/XSns.svg";
import arrowRight from "@app/assets/images/arrow_right.svg";
import LineSns from "@app/assets/images/lineSns.svg";
import searchIcon from "@app/assets/images/search_icon.svg";
import Button from "@app/components/atoms/Button/Button";
import Image from "@app/components/atoms/Image/Image";
import {
  Label,
  PACKS_LIST_STATUS,
  PACK_STATUS_NAME_ENUM,
  SNS_LINK,
  STATUS_SOLD_OUT,
  STATUS_ON_SALE,
  STATUS_WAITING_SALE,
  CATEGORY_TYPE,
  ParamsListPacks,
  STATUS_ALL,
  ShopsPathsEnum,
  ShopsEndpointsEnum,
  PACK_START_SALE,
} from "@app/features/shops/shops";
import { StaticPagePathsEnum } from "@app/features/static-page/static-page";
import { useAppSelector } from "@app/redux/store";

import styles from "./TabFilter.module.scss";

interface TabFilterProps {
  isFirstLoadingTop?: boolean;
  setIsHiddenMenu?: React.Dispatch<React.SetStateAction<boolean>>;
  isPackDetail?: boolean;
  labelList?: Label[];
  initParams: ParamsListPacks;
  setInitParams: (newQueries: ParamsListPacks) => void;
}

const listLink = [
  {
    title: "TOP",
    href: "/",
  },
  {
    title: "獲得カード所持一覧",
    href: ShopsEndpointsEnum.SHOP_LIST_USER,
  },
  {
    title: "利用規約",
    href: StaticPagePathsEnum.TERMS_OF_SERVICE,
  },
  // {
  //   title: "利用ガイド",
  //   href: StaticPagePathsEnum.USAGE_GUIDE,
  // },
  {
    title: "プライバシーポリシー",
    href: StaticPagePathsEnum.PRIVACY_POLICY,
  },
  {
    title: "特定取引法に基づく表記",
    href: StaticPagePathsEnum.COMMERCIAL_LAW,
  },
  {
    title: "お問い合わせ",
    href: ShopsPathsEnum.CONTACT,
  },
];

const TabFilter = ({
  labelList,
  initParams,
  isFirstLoadingTop,
  setIsHiddenMenu,
  setInitParams,
  isPackDetail,
}: TabFilterProps) => {
  const history = useHistory();
  const location = useLocation();

  const { lg } = useBreakpoint();

  const { isLoading } = useAppSelector(state => state.shops);
  const isHomePage = location.pathname === ShopsPathsEnum.SHOP_LIST;

  const [min, setMin] = useState<string | null>(
    (initParams.number_point_one_roll_min as string) ?? ""
  );
  const [max, setMax] = useState<string | null>(
    (initParams.number_point_one_roll_max as string) ?? ""
  );
  const [key, setKey] = useState<string | undefined>(initParams?.keyword ?? "");

  const statusList = [
    {
      name: PACK_STATUS_NAME_ENUM.ALL,
      value: PACKS_LIST_STATUS[STATUS_ALL],
    },
    {
      name: PACK_STATUS_NAME_ENUM.ON_SALE,
      value: PACKS_LIST_STATUS[STATUS_ON_SALE],
    },
    {
      name: PACK_STATUS_NAME_ENUM.WAITING_SALE,
      value: PACKS_LIST_STATUS[STATUS_WAITING_SALE],
    },
    {
      name: PACK_STATUS_NAME_ENUM.SOLD_OUT,
      value: PACKS_LIST_STATUS[STATUS_SOLD_OUT],
    },
  ];

  useEffect(() => {
    if (!initParams.keyword) {
      setKey("");
    }
    if (
      !initParams.number_point_one_roll_max &&
      !initParams.number_point_one_roll_min
    ) {
      setMin("");
      setMax("");
    }
  }, [
    initParams.keyword,
    initParams.number_point_one_roll_max,
    initParams.number_point_one_roll_min,
  ]);

  const handleEffectFilter = useCallback(() => {
    if (cancelToken.source) {
      cancelToken.source.cancel();
    }
    if (setIsHiddenMenu) {
      setIsHiddenMenu(false);
    }
    if (!isHomePage) {
      history.push(ShopsPathsEnum.SHOP_LIST);
    }
  }, [history, setIsHiddenMenu, isHomePage]);

  const getQueryStartedSale = useCallback((status?: string) => {
    if (status === PACKS_LIST_STATUS[STATUS_ON_SALE]) {
      return PACK_START_SALE.PACK_SOLD;
    }
    if (status === PACKS_LIST_STATUS[STATUS_WAITING_SALE]) {
      return PACK_START_SALE.PACK_NOT_YET_SOLD;
    }
    return undefined;
  }, []);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleEffectFilter();
      setInitParams({
        keyword: e.target.value.trim(),
      });
    },
    [setInitParams, handleEffectFilter]
  );

  const handleKeypress = useCallback(() => {
    handleEffectFilter();
    setInitParams({
      keyword: key?.trim(),
    });
  }, [setInitParams, key, handleEffectFilter]);

  const handleFilterPoint = useCallback(() => {
    handleEffectFilter();
    setInitParams({
      number_point_one_roll_min: min?.replace(/\$\s?|(,*)/g, ""),
      number_point_one_roll_max: max?.replace(/\$\s?|(,*)/g, ""),
    });
  }, [min, max, setInitParams, handleEffectFilter]);

  const handleSelectLabel = useCallback(
    (id: string) => {
      if (isFirstLoadingTop) {
        return;
      }
      handleEffectFilter();
      setInitParams({
        label_ids: Number(id) ? [String(id)] : undefined,
      });
    },
    [setInitParams, handleEffectFilter, isFirstLoadingTop]
  );

  const handleSelectStatuses = useCallback(
    (value?: string) => {
      if (isFirstLoadingTop) {
        return;
      }
      handleEffectFilter();
      setInitParams({
        status:
          value === PACKS_LIST_STATUS[STATUS_WAITING_SALE]
            ? undefined
            : value ?? undefined,
        started_sale: getQueryStartedSale(value),
      });
    },
    [setInitParams, getQueryStartedSale, isFirstLoadingTop, handleEffectFilter]
  );

  const isActiveMenuItem = (pathName: string) => {
    if (pathName !== "/") {
      return !!pathName.length && location.pathname.startsWith(pathName);
    }
    return pathName === location.pathname;
  };

  return (
    <div className={styles.tabFilter}>
      <div
        className={cx(styles.content, lg && styles.noBorder, styles.snsGroup)}
      >
        <div className="mb-5">
          <div className={cx(styles.buttonSns, styles.btnLine)}>
            <Image className="ml-2" src={LineSns} />
            <span>友だち追加</span>
          </div>
        </div>
        <div className="mb-5">
          <a href={SNS_LINK.X} className={cx(styles.buttonSns, styles.btnX)}>
            <Image className="ml-4" src={XSns} />
            <span>フォローする</span>
          </a>
        </div>
      </div>
      {!isPackDetail && (
        <div className={cx(styles.searchInput, styles.content)}>
          <Input
            disabled={isFirstLoadingTop}
            prefix={lg ? <Image className="pl-2" src={searchIcon} /> : ""}
            placeholder="キーワードを検索"
            value={key}
            onChange={e => setKey(e.target.value)}
            onBlur={e => handleSearch(e)}
            onKeyPress={e => {
              if (e.key === "Enter") {
                handleKeypress();
              }
            }}
          />
        </div>
      )}

      {labelList?.length && (
        <div className={cx(styles.labels, styles.content)}>
          <div>
            <p className="font-20 font-weight-bold">カードの種類から探す</p>
            <ul className={cx(styles.list, "pl-5")}>
              {[
                { id: CATEGORY_TYPE.ALL, name: "すべて", image_url: "" },
                ...labelList,
              ]?.map(item => {
                return (
                  <li className="font-14 font-weight-medium mb-2" key={item.id}>
                    <p
                      aria-hidden
                      className={cx(
                        styles.link,
                        (initParams.label_ids?.includes(String(item.id)) ||
                          (item.id === CATEGORY_TYPE.ALL &&
                            !initParams.label_ids)) &&
                          styles.active,
                        isFirstLoadingTop ? styles.notAllow : "cursor-pointer"
                      )}
                      onClick={() => handleSelectLabel(item.id)}
                    >
                      {item.name}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}

      <div className={cx(styles.searchPoint, styles.content)}>
        <p className="font-20 font-weight-bold">Pointから探す</p>
        <div className="flex-space-between-center">
          <Input
            disabled={isFirstLoadingTop}
            placeholder="300"
            value={min ?? ""}
            onChange={e => {
              const minValue = e.target.value
                .replace(/\D/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              setMin(minValue);
            }}
          />
          <span className="mx-2">～</span>

          <Input
            disabled={isFirstLoadingTop}
            placeholder="9,999,999"
            value={max ?? ""}
            onChange={e => {
              const maxValue = e.target.value
                .replace(/\D/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              setMax(maxValue);
            }}
          />
        </div>
        <div className="text-right mt-2">
          <Button
            type="primary"
            danger
            disabled={isLoading}
            className={styles.btnSearchPoint}
            onClick={handleFilterPoint}
          >
            検　索
          </Button>
        </div>
      </div>

      <div className={cx(styles.status, styles.content)}>
        <p className="font-20 font-weight-bold">開催状況から探す</p>
        <ul className={cx(styles.list, "pl-5")}>
          {statusList.map(item => (
            <li className="font-14 font-weight-medium mb-2" key={item.value}>
              <p
                aria-hidden
                className={cx(
                  styles.link,
                  ((initParams.status === item.value &&
                    initParams.started_sale !==
                      PACK_START_SALE.PACK_NOT_YET_SOLD) ||
                    (initParams.started_sale &&
                      !initParams.status &&
                      item.value === PACKS_LIST_STATUS[STATUS_WAITING_SALE])) &&
                    styles.active,
                  isFirstLoadingTop ? styles.notAllow : "cursor-pointer"
                )}
                onClick={() => handleSelectStatuses(item.value)}
              >
                {item.name}
              </p>
            </li>
          ))}
        </ul>
      </div>

      {isPackDetail && (
        <div className="mt-9">
          <Button
            onClick={() => {
              history.push(ShopsPathsEnum.CONTACT);
            }}
            width="100%"
            borderRadius="30px"
            danger
            type="primary"
          >
            問い合わせ
          </Button>
        </div>
      )}

      <div className={styles.listMove}>
        {listLink.map(item => {
          return (
            <Link
              className={cx(
                isActiveMenuItem(item.href) ? styles.active : "text-black",
                "py-3 flex-space-between-center  font-14 font-weight-bold",
                styles.content
              )}
              to={item.href}
              key={item.title}
            >
              <span>{item.title}</span>
              <Image
                className={
                  (styles.arrow,
                  isActiveMenuItem(item.href)
                    ? styles.filterColor
                    : "text-black")
                }
                src={arrowRight}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default memo(TabFilter);
