import { memo } from "react";

import { Button as AntdButton } from "antd";
import { ButtonProps as AntdButtonProps } from "antd/es/button";
import cx from "classnames";
import { Link, LinkProps } from "react-router-dom";

import { isURL } from "@app/helpers/util.helper";

import styles from "./Button.module.scss";

interface ButtonProps extends Omit<AntdButtonProps, "href"> {
  /**
   * Turn button into link, accepts internal and external links (optional)
   */
  to?: LinkProps["to"];
  /**
   * Remove horizontal padding (optional)
   */
  noPadding?: boolean;
  width?: string;
  height?: string;
  borderRadius?: string;
  withRightArrow?: boolean;
  withLeftArrow?: boolean;
}

const Button = ({
  to,
  className,
  noPadding,
  width,
  height,
  borderRadius,
  children,
  withRightArrow,
  withLeftArrow,
  ...rest
}: ButtonProps) => {
  const isExternalLink = typeof to === "string" && isURL(to);

  const buttonContent = (
    <AntdButton
      className={cx(styles.button, className, {
        [styles.noPadding]: noPadding,
      })}
      style={{ width, height, borderRadius }}
      {...(isExternalLink && {
        href: to as string,
        target: "_blank",
        rel: "noopener noreferrer",
      })}
      {...rest}
    >
      {withLeftArrow && (
        <div className={cx(styles.arrowLeft, "arrowLeft")}>◀</div>
      )}
      <div
        className={
          (withLeftArrow ? "ml-1" : "") || (withRightArrow ? "mr-1" : "")
        }
      >
        {children}
      </div>
      {withRightArrow && <div className={cx(styles.arrowRight)}>▶</div>}
    </AntdButton>
  );

  // Only wrap in react router link, if internal link
  if (!isExternalLink && to) {
    return <Link to={to}>{buttonContent}</Link>;
  }

  return buttonContent;
};
export default memo(Button);
