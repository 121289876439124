import { memo, useEffect, useState } from "react";

import { Col, Input, Row, Select } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import jpZipCode from "japan-postal-code";
import { useParams } from "react-router-dom";

import AlertError from "@app/components/atoms/AlertError/AlertError";
import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import StatusFormField from "@app/components/atoms/StatusFormField/StatusFormField";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { MODE_ACTIONS } from "@app/constants/constants";
import { getListPrefectures } from "@app/features/prefectures/prefectures";
import {
  createUserAddress,
  updateUserAddress,
} from "@app/features/user-addresses/user-addresses";
import {
  phoneNumberRules,
  REGEX_HIRAGANA,
  REGEX_NUMBER,
} from "@app/helpers/validations.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import { UserAddress } from "../../auth";
import styles from "./FormAddress.module.scss";

interface FormAddressProps {
  userAddress?: UserAddress;
  triggerSubmitFormAddress: number;
  onSubmitFinish: () => void;
  isScreen?: boolean;
  setIsSubmitting?: (isSubmit: boolean) => void;
}

const FormAddress = ({
  userAddress,
  triggerSubmitFormAddress,
  onSubmitFinish,
  isScreen,
  setIsSubmitting,
}: FormAddressProps) => {
  const { md } = useBreakpoint();
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const { mode, id } = useParams<{ mode: string; id: string }>();

  const [isDisableButtonZipcode, setIsDisableButtonZipcode] = useState(true);
  const [isShowAlert, setIsShowAlert] = useState(false);

  const { prefecturesList } = useAppSelector(
    (state: RootState) => state.prefectures
  );

  useEffect(() => {
    dispatch(getListPrefectures());
  }, [dispatch]);

  useEffect(() => {
    if (userAddress) {
      form.setFieldsValue({
        ...userAddress,
        prefecture_id: userAddress.prefecture?.id,
      });
    }
  }, [form, userAddress]);

  useEffect(() => {
    if (triggerSubmitFormAddress) {
      form
        .validateFields()
        .then(() => {
          setIsShowAlert(false);
          const onSubmit =
            mode === MODE_ACTIONS.CREATE
              ? dispatch(createUserAddress({ data: form.getFieldsValue() }))
              : dispatch(
                  updateUserAddress({
                    userAddressId: id ?? String(userAddress?.id),
                    data: form.getFieldsValue(),
                  })
                );
          onSubmit
            .then(() => onSubmitFinish())
            .catch(err => {
              openNotification({ type: "warning", message: err.errors });
            });
        })
        .catch(() => {
          setIsSubmitting?.(false);
          if (isScreen) {
            setIsShowAlert(true);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSubmitFormAddress]);

  const handleFillFromZipCode = () => {
    form.setFieldsValue({
      prefecture_id: null,
      city: null,
    });
    jpZipCode.get(form.getFieldValue("zipcode"), address => {
      const addressFind = prefecturesList?.find(
        item => item.name === address.prefecture
      );
      if (!addressFind) return;
      form.setFieldsValue({
        prefecture_id: addressFind.id,
        city: address.city,
      });
    });
  };

  return (
    <>
      {isShowAlert && (
        <AlertError
          className={`${md ? "mb-8" : ""} mt-3`}
          message="入力項目に誤りがあります。"
          maxWidth="849px"
        />
      )}
      <Form
        form={form}
        className={cx(styles.root, isScreen && !isShowAlert ? "mt-15" : "")}
        onFieldsChange={() =>
          setIsDisableButtonZipcode(
            !!form.getFieldError("zipcode")?.length ||
              !form.getFieldValue("zipcode")
          )
        }
      >
        <Row className="mt-3">
          <Col xs={24} md={5} className="mb-2 mr-10">
            <StatusFormField className="mt-2" title="お名前" isRequire />
          </Col>
          <Col xs={9} md={4}>
            <Item
              name="firstname"
              rules={[
                {
                  required: true,
                  message: "入力してください",
                },
              ]}
            >
              <Input maxLength={10} placeholder="山田" />
            </Item>
          </Col>
          <Col xs={9} md={4} className="ml-2">
            <Item
              name="lastname"
              rules={[
                {
                  required: true,
                  message: "入力してください",
                },
              ]}
            >
              <Input maxLength={10} placeholder="太郎" />
            </Item>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={24} md={5} className="mb-2 mr-10">
            <StatusFormField className="mt-2" title="ふりがな" isRequire />
          </Col>
          <Col xs={9} md={4}>
            <Item
              name="firstname_furigana"
              rules={[
                {
                  required: true,
                  message: "入力してください",
                },
                {
                  pattern: REGEX_HIRAGANA,
                  message: "全角で入力してください。",
                },
              ]}
            >
              <Input maxLength={10} placeholder="やまだ" />
            </Item>
          </Col>
          <Col xs={9} md={4} className="ml-2">
            <Item
              name="lastname_furigana"
              rules={[
                {
                  required: true,
                  message: "入力してください",
                },
                {
                  pattern: REGEX_HIRAGANA,
                  message: "全角で入力してください。",
                },
              ]}
            >
              <Input maxLength={10} placeholder="たろう" />
            </Item>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={24} md={5} className="mb-2 mr-10">
            <StatusFormField
              className="mt-2"
              title="郵便番号(ハイフンなし)"
              isRequire
            />
          </Col>
          <Col xs={12} md={6}>
            <Item
              normalize={value => value.trim()}
              name="zipcode"
              rules={[
                {
                  required: true,
                  message: "入力してください",
                },
                {
                  pattern: REGEX_NUMBER,
                  message: "郵便番号には、数字を指定してください。",
                },
                {
                  min: 7,
                  message: "郵便番号には、7以上の数字を指定してください。",
                },
              ]}
            >
              <Input maxLength={7} placeholder="1234567" />
            </Item>
          </Col>
          <Col xs={9} md={3} className="ml-2 mt-1">
            <Button
              danger
              noPadding
              width={`${md ? "78px" : "73px"}`}
              height={`${md ? "29px" : "28px"}`}
              onClick={handleFillFromZipCode}
              disabled={isDisableButtonZipcode}
            >
              <div className="font-12">住所検索</div>
            </Button>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={24} md={5} className="mb-2 mr-10">
            <StatusFormField className="mt-2" title="都道府県" isRequire />
          </Col>
          <Col xs={12} md={6}>
            <Item
              name="prefecture_id"
              rules={[
                {
                  required: true,
                  message: "選択してください",
                },
              ]}
            >
              <Select
                placeholder="選択してください"
                suffixIcon={
                  <div style={{ color: "black", fontSize: 9 }}>▼</div>
                }
                loading={!prefecturesList}
                options={
                  prefecturesList?.map(prefecture => ({
                    value: prefecture.id,
                    label: prefecture.name,
                  })) ?? []
                }
              />
            </Item>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={24} md={5} className="mb-2 mr-10">
            <StatusFormField className="mt-2" title="市町村" isRequire />
          </Col>
          <Col xs={24} md={14}>
            <Item
              name="city"
              rules={[
                {
                  required: true,
                  message: "入力してください",
                },
              ]}
            >
              <Input maxLength={255} placeholder="○○市○○区○○横丁" />
            </Item>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={24} md={5} className="mb-2 mr-10">
            <StatusFormField className="mt-2" title="番地以降" isRequire />
          </Col>
          <Col xs={24} md={14}>
            <Item
              name="address"
              rules={[
                {
                  required: true,
                  message: "入力してください",
                },
              ]}
            >
              <Input maxLength={255} placeholder="○丁目00-0" />
            </Item>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={24} md={5} className="mb-2 mr-10">
            <StatusFormField className="mt-2" title="アパート名、号室" />
          </Col>
          <Col xs={24} md={14}>
            <Item name="apartment_name_room_number">
              <Input maxLength={255} placeholder="○○荘000号室" />
            </Item>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={24} md={5} className="mb-2 mr-10">
            <StatusFormField className="mt-2" title="電話番号" isRequire />
          </Col>
          <Col xs={12} md={6}>
            <Item
              normalize={value => value.trim()}
              name="tel"
              rules={phoneNumberRules}
            >
              <Input maxLength={12} placeholder="0000000000" />
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default memo(FormAddress);
