import { combineReducers } from "@reduxjs/toolkit";

import { authReducer, AUTH_FEATURE_KEY } from "@app/features/auth/auth";
import {
  GACHA_HISTORIES_KEY,
  gachaHistoriesReducer,
} from "@app/features/gacha-histories/gacha-histories";
import {
  pointExchangesReducer,
  POINT_EXCHANGES_FEATURE_KEY,
} from "@app/features/point-exchanges/point-exchanges";
import {
  prefecturesReducer,
  PREFECTURES_FEATURE_KEY,
} from "@app/features/prefectures/prefectures";
import { shopsReducer, SHOPS_FEATURE_KEY } from "@app/features/shops/shops";
import {
  USER_ADDRESSES_FEATURE_KEY,
  userAddressesReducer,
} from "@app/features/user-addresses/user-addresses";

const rootReducer = combineReducers({
  [AUTH_FEATURE_KEY]: authReducer,
  [SHOPS_FEATURE_KEY]: shopsReducer,
  [GACHA_HISTORIES_KEY]: gachaHistoriesReducer,
  [POINT_EXCHANGES_FEATURE_KEY]: pointExchangesReducer,
  [PREFECTURES_FEATURE_KEY]: prefecturesReducer,
  [USER_ADDRESSES_FEATURE_KEY]: userAddressesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
