import { useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Input } from "antd";
import Link from "antd/lib/typography/Link";
import cx from "classnames";
import { get } from "lodash";
import { Link as LinkRouter, useHistory } from "react-router-dom";

import Logo from "@app/assets/images/logo.png";
import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import Image from "@app/components/atoms/Image/Image";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { ERROR_MESSAGES } from "@app/constants/message.constants";
import { getQueryParams } from "@app/helpers/queryParams/queryParams";
import { emailRules, passwordRules } from "@app/helpers/validations.helper";
import { useAppDispatch } from "@app/redux/store";

import {
  GRANT_TYPE,
  LoginDataDef,
  postLogin,
  AuthPathsEnum,
  getCurrentUser,
} from "../../auth";
import styles from "./Login.module.scss";

const Login = () => {
  const dispatch = useAppDispatch();
  const { redirect } = getQueryParams(window.location.search);

  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [form] = useForm();

  const handleSubmit = (dataForm: LoginDataDef) => {
    setIsSubmitting(true);
    dispatch(
      postLogin({ data: { ...dataForm, grant_type: GRANT_TYPE.password } })
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(getCurrentUser()).then(() =>
          history.push((redirect as string) ?? "/")
        );
      })
      .catch(err => {
        const errMsg =
          get(ERROR_MESSAGES, err.errors) ??
          get(err, "errors.email.0") ??
          err.errors;
        if (errMsg) {
          openNotification({ type: "warning", message: errMsg ?? err.errors });
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  const handleChange = () => {
    setIsValid(form.getFieldsError().some(({ errors }) => errors.length));
  };

  return (
    <>
      <div className={cx(styles.container)}>
        <div className="text-center">
          <LinkRouter to="/">
            <Image className={cx(styles.logo)} src={Logo} />
          </LinkRouter>
        </div>
        <Form onFinish={handleSubmit} onFieldsChange={handleChange} form={form}>
          <div className="font-12 font-weight-medium mt-9">メールアドレス</div>
          <Item name="email" rules={emailRules}>
            <Input maxLength={44} placeholder="メールアドレスを入力" />
          </Item>
          <div className="font-12 font-weight-medium">パスワード</div>
          <Item
            name="password"
            rules={passwordRules}
            className={cx(styles.password)}
          >
            <Input.Password maxLength={50} placeholder="パスワードを入力" />
          </Item>
          <Link
            className={cx(styles.link)}
            href={AuthPathsEnum.FORGOT_PASSWORD}
          >
            パスワードを忘れた方はこちら
          </Link>
          <div className={cx(styles.btnBox)}>
            <Button
              disabled={isSubmitting || isValid}
              type="primary"
              danger
              width="235px"
              htmlType="submit"
            >
              <div className="font-12">ログイン</div>
            </Button>
            <div className="mt-3">
              <Button
                danger
                width="235px"
                height="67px"
                className="btn-signUp"
                to={AuthPathsEnum.TEMPORAPY_REGISTER}
              >
                <div className="font-10 font-weight-medium">
                  アカウントをお持ちでない方はこちら
                </div>
                <div className="font-14">新規会員登録</div>
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Login;
