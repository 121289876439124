export const GRANT_TYPE: Record<string, string> = {
  password: "password",
  refresh_token: "refresh_token",
};

export const TOKEN_STATUS: Record<string, string> = {
  VALID: "valid",
  INVALID: "invalid",
  EXPIRED: "expired",
};

export const MESSAGE_ERROR = "認証に失敗しました。";

export const LOCATION_STATE_SUCCESS = "success";

export const MAINTENANCE_FLAG = "gacha_maintain_flag";
