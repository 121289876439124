import { memo, useEffect, useState, useCallback, useMemo } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Tabs } from "antd";
import cx from "classnames";

import { cancelToken } from "@app/api/api";
import {
  getQueryStartedSale,
  objectKeyByValue,
} from "@app/helpers/util.helper";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import {
  PACKS_LIST_STATUS,
  PACK_START_SALE,
  SHOP_PACK_LIMIT,
  STATUS_ALL,
  STATUS_ON_SALE,
  STATUS_SOLD_OUT,
  STATUS_WAITING_SALE,
  getShopPacks,
  updateGettingPacks,
  updateListPacks,
} from "../../shops";
import { ParamsListPacks, ShopPacksResponseDef } from "../../types/shops.types";
import ListOripaCard from "../ListOripaCard/ListOripaCard";
import styles from "./ShopPacks.module.scss";

interface ShopPackProps {
  isFirstLoadingTop: boolean;
  setQueryShopPacks: (newQueries: ParamsListPacks) => void;
  queryShopPacks: ParamsListPacks;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

const ShopPack = ({
  isFirstLoadingTop,
  queryShopPacks,
  setQueryShopPacks,
  pageNumber,
  setPageNumber,
}: ShopPackProps) => {
  const dispatch = useAppDispatch();

  const isLogin = useAppSelector(state => state.auth.accessToken);
  const userId = useAppSelector(state => state.auth.currentUser?.id);

  const [isFirstLoading, setIsFirstLoading] = useState(false);

  const fetchDataListPack = useCallback(
    (currentPage: number) => {
      dispatch(updateGettingPacks(true));
      if ((isLogin && userId) || !isLogin) {
        dispatch(
          getShopPacks({
            ...queryShopPacks,
            user_id: userId ? String(userId) : undefined,
            use_page: true,
            page: currentPage,
            limit: SHOP_PACK_LIMIT,
          })
        )
          .then(unwrapResult)
          .then((res: ShopPacksResponseDef) => {
            dispatch(updateListPacks(res));
            setIsFirstLoading(false);
          })
          .finally(() => {
            dispatch(updateGettingPacks(false));
          });
      }
    },
    [dispatch, queryShopPacks, userId, isLogin]
  );

  useEffect(() => {
    setIsFirstLoading(true);
    fetchDataListPack(1);
    const controller = new AbortController();
    return () => {
      dispatch(updateListPacks(null));
      controller.abort();
    };
  }, [dispatch, fetchDataListPack]);

  const fetchMoreData = async () => {
    setPageNumber(pageNumber + 1);
    fetchDataListPack(pageNumber + 1);
  };

  const activeTab: string = useMemo(() => {
    if (queryShopPacks.started_sale === PACK_START_SALE.PACK_NOT_YET_SOLD) {
      return String(STATUS_WAITING_SALE);
    }
    return queryShopPacks.status
      ? objectKeyByValue(PACKS_LIST_STATUS, queryShopPacks.status)
      : String(STATUS_ALL);
  }, [queryShopPacks.started_sale, queryShopPacks.status]);

  return (
    <div className={cx(styles.tabs)}>
      <Tabs
        onChange={activeKey => {
          setIsFirstLoading(true);
          if (cancelToken.source) {
            cancelToken.source.cancel();
          }
          setQueryShopPacks({
            status:
              Number(activeKey) !== STATUS_WAITING_SALE
                ? PACKS_LIST_STATUS[Number(activeKey)] ?? undefined
                : undefined,
            started_sale: getQueryStartedSale(Number(activeKey)),
          });
          setPageNumber(1);
        }}
        activeKey={activeTab}
        defaultActiveKey={String(STATUS_ALL)}
      >
        <Tabs.TabPane
          disabled={isFirstLoadingTop}
          tab="すべて"
          key={STATUS_ALL}
        >
          {queryShopPacks.status === PACKS_LIST_STATUS[STATUS_ALL] && (
            <ListOripaCard
              fetchMoreData={fetchMoreData}
              isLoading={isFirstLoading}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane
          disabled={isFirstLoadingTop}
          tab="開催中"
          key={STATUS_ON_SALE}
        >
          {queryShopPacks.status === PACKS_LIST_STATUS[STATUS_ON_SALE] && (
            <ListOripaCard
              fetchMoreData={fetchMoreData}
              isLoading={isFirstLoading}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane
          disabled={isFirstLoadingTop}
          tab="開催前"
          key={STATUS_WAITING_SALE}
        >
          {queryShopPacks.started_sale ===
            PACK_START_SALE.PACK_NOT_YET_SOLD && (
            <ListOripaCard
              fetchMoreData={fetchMoreData}
              isLoading={isFirstLoading}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane
          disabled={isFirstLoadingTop}
          tab="終了"
          key={STATUS_SOLD_OUT}
        >
          {queryShopPacks.status === PACKS_LIST_STATUS[STATUS_SOLD_OUT] && (
            <ListOripaCard
              fetchMoreData={fetchMoreData}
              isLoading={isFirstLoading}
            />
          )}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default memo(ShopPack);
