export enum PointExchangesPathsEnum {
  POINT_EXCHANGES = "/point-exchanges",
  POINT_EXCHANGES_SUCCESS = "/point-exchanges/success/:paymentId",
  EPSILON_POINT_EXCHANGES_SUCCESS = "/point-exchanges/success",
  SELECT_POINT_TRANSFER_METHOD_SCREEN = "/select-transfer-method",
  BANK_TRANSFER_DETAILS_SCREEN = "/bank-transfer-details/:paymentId",
  BANK_TRANSFER_NOTIFY_SCREEN = "/bank-transfer-notify",
  BANK_TRANSFER_SUCCESS_SCREEN = "/bank-transfer-success",
  BANK_TRANSFER_EXPIRATION = "/bank-transfer-expiration",
  PERSONAL_INFORMATION = "/personal-information",
}
