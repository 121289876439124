import { useContext, useEffect, useMemo, useState, useCallback } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Col, Row, Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import qs from "qs";
import { useHistory, useParams, useLocation } from "react-router-dom";

import ButtonBackPage from "@app/components/atoms/ButtonBackPage/ButtonBackPage";
import Image from "@app/components/atoms/Image/Image";
import Label from "@app/components/atoms/Label/Label";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import Helmet from "@app/components/molecules/Helmet/Helmet";
import { openNotification } from "@app/components/molecules/Notification/notification";
import ProgressPack from "@app/components/molecules/ProgressPack/ProgressPack";
import TabFilter from "@app/components/molecules/TabFilter/TabFilter";
import { NUMBER_ROLL_GACHA, PAGE_INFO } from "@app/constants/constants";
import { FilterContext } from "@app/contexts/FilterContext";
import { AuthPathsEnum, getListSettings } from "@app/features/auth/auth";
import BtnGacha from "@app/features/gacha-histories/components/BtnGacha/BtnGacha";
import {
  getPackNumberRollUser,
  postGachaHistories,
  postHoldingBuyCard,
} from "@app/features/gacha-histories/gacha-histories";
import useGetGachaHistories from "@app/features/gacha-histories/hooks/useGetGachaHistories/useGetGachaHistories";
import { convertMoney, isReadyReleaseCard } from "@app/helpers/util.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import Card from "../../components/Card/Card";
import ListTypeCard from "../../components/ListTypeCard/ListTypeCard";
import ModalConfirmGacha from "../../components/ModalConfirmGacha/ModalConfirmGacha";
import {
  PACKS_LIST_STATUS,
  STATUS_ON_SALE,
  STATUS_SOLD_OUT,
  PACK_START_SALE,
} from "../../constants/shops.constants";
import {
  getPackDetail,
  getShopDetail,
  getShopPacks,
  Pack,
  resetShopDetail,
  ShopsPathsEnum,
  TIME_INTERVAL,
} from "../../shops";
import styles from "./PackDetailsScreen.module.scss";

const PackDetailScreen = () => {
  const location = useLocation();
  const history = useHistory();

  const { lg } = useBreakpoint();

  const { shopId, packId } = useParams<{ shopId: string; packId: string }>();
  const dispatch = useAppDispatch();

  const {
    auth: { currentUser },
    gacha_histories: { numberRollPack },
  } = useAppSelector((state: RootState) => state);
  const labels = useAppSelector(state => state.shops.labelList?.labels);
  const userId = useAppSelector(state => state.auth.currentUser?.id);

  const { setQueryFilter, queryFilter } = useContext(FilterContext);

  const [gachaCountSelect, setGachaCountSelect] = useState<
    number | string | null
  >(null);
  const [packNewest, setPackNewest] = useState<Pack[]>([]);
  const [packDetail, setPackDetail] = useState<Pack | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [getGachaHistories] = useGetGachaHistories(setIsSubmitting);
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const { shopDetail } = useAppSelector((state: RootState) => state.shops);

  const controlNavbar = useCallback(() => {
    if (window.scrollY > lastScrollY) {
      // if scroll down hide the navbar
      setShow(false);
    } else {
      // if scroll up show the navbar
      setShow(true);
    }

    // remember current page location to use in the next move
    setLastScrollY(window.scrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);

    // cleanup function
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY, controlNavbar]);

  const numberPointRollOfModal = useMemo(() => {
    if (!packDetail) {
      return 0;
    }

    if (gachaCountSelect === NUMBER_ROLL_GACHA.ONE) {
      return (
        packDetail.number_point_one_roll ??
        packDetail.number_point_ten_roll / NUMBER_ROLL_GACHA.TEN
      );
    }
    if (gachaCountSelect === NUMBER_ROLL_GACHA.TEN) {
      return (
        packDetail.number_point_ten_roll ??
        packDetail.number_point_one_roll * NUMBER_ROLL_GACHA.TEN
      );
    }

    return packDetail.number_point_one_roll
      ? packDetail.number_point_one_roll * packDetail.number_card_remain
      : (packDetail.number_point_ten_roll / NUMBER_ROLL_GACHA.TEN) *
          packDetail.number_card_remain;
  }, [packDetail, gachaCountSelect]);

  useEffect(() => {
    dispatch(
      getShopPacks({
        user_id: userId ? String(userId) : undefined,
        status: PACKS_LIST_STATUS[STATUS_ON_SALE],
        started_sale: PACK_START_SALE.PACK_SOLD,
        use_page: true,
        limit: 3,
      })
    )
      .then(unwrapResult)
      .then(data => {
        setPackNewest(data.packs);
      });
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(getListSettings());
    dispatch(getPackDetail({ shopId, packId }))
      .then(unwrapResult)
      .then(data => {
        setPackDetail(data);
      })
      .finally(() => {
        setIsFirstLoading(false);
      });
    dispatch(getPackNumberRollUser({ pack_id: packId }));

    const fetchData = setInterval(() => {
      if (!document.hidden) {
        dispatch(getListSettings());
        dispatch(getPackDetail({ shopId, packId }))
          .then(unwrapResult)
          .then(data => {
            setPackDetail(data);
          });
      }
    }, TIME_INTERVAL);
    return () => {
      clearInterval(fetchData);
    };
  }, [dispatch, shopId, packId]);

  useEffect(() => {
    if (shopId) {
      dispatch(getShopDetail(shopId));
    }
  }, [dispatch, shopId]);

  useEffect(() => {
    return () => {
      dispatch(resetShopDetail());
    };
  }, [dispatch]);

  const checkCardIsHolding = useCallback(
    async (packDetailId: string, isGachaAll: string) => {
      if (isGachaAll !== NUMBER_ROLL_GACHA.All) {
        return true;
      }
      let isCardAvailable = null;
      setIsSubmitting(true);
      await dispatch(postHoldingBuyCard(packDetailId))
        .then(unwrapResult)
        .then(() => {
          isCardAvailable = true;
        })
        .catch(err => {
          isCardAvailable = false;
          openNotification({ type: "warning", message: err.errors });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
      return isCardAvailable;
    },
    [dispatch]
  );

  const handleSubmit = async (
    gachaCount: number | string,
    pointCount: number
  ) => {
    if (!packDetail) return;
    if (!currentUser) {
      history.push({
        pathname: AuthPathsEnum.LOGIN,
        search: qs.stringify({
          redirect: location.pathname + location.search,
        }),
      });
      return;
    }

    const isEnoughMoney = (currentUser?.total_point ?? 0) >= pointCount;

    if (await checkCardIsHolding(String(packDetail.id), String(gachaCount))) {
      if (currentUser?.no_show_alert_gacha_at && isEnoughMoney) {
        setIsSubmitting(true);
        dispatch(
          postGachaHistories({
            pack_id: packDetail.id,
            type: String(gachaCount),
            number_card:
              String(gachaCount) === NUMBER_ROLL_GACHA.All
                ? packDetail.number_card_remain
                : undefined,
          })
        )
          .then(unwrapResult)
          .then(data => {
            getGachaHistories({ isGacha: true, id: String(data.id) });
          })
          .catch(err => {
            openNotification({ type: "warning", message: err.errors });
            setIsSubmitting(false);
          });
      } else {
        setGachaCountSelect(gachaCount);
      }
    }
  };

  if (!packDetail || isFirstLoading) return <LoadingSpinner />;

  return (
    <div className={cx(styles.root)}>
      <Helmet
        title={
          packDetail?.title && shopDetail?.name
            ? `${shopDetail.name}｜${packDetail.title}｜トレカボールガチャ`
            : ""
        }
        description={
          shopDetail?.name
            ? `${shopDetail.name}${PAGE_INFO["UA-0003"].description}`
            : ""
        }
      />
      <div className={cx(styles.wrapper, lg && "px-12")}>
        <Row gutter={{ xs: 0, sm: 0, md: 0 }} className={styles.row}>
          <Col span={lg ? 6 : 0} className="mt-6">
            <div className={styles.tabFilter}>
              <TabFilter
                isPackDetail
                labelList={labels}
                initParams={queryFilter}
                setInitParams={setQueryFilter}
              />
            </div>
          </Col>
          <Col span={lg ? 12 : 24}>
            <div className={styles.main}>
              {!lg && (
                <div className={styles.ButtonBackPage}>
                  <ButtonBackPage
                    path={ShopsPathsEnum.SHOP_LIST}
                    className="my-2"
                  />
                </div>
              )}
              <div className={styles.imgBgBox}>
                {packDetail.image_background_url ? (
                  <Image
                    key={packDetail.id}
                    src={packDetail.image_background_url}
                    className={styles.imgBg}
                  />
                ) : (
                  <div className={styles.noImage} />
                )}
              </div>
              <div className={styles.container}>
                <div className={styles.packInfo}>
                  <div className={styles.packName}>{packDetail.title}</div>
                  <div className={cx(styles.pointStatus, "flex-1")}>
                    <ProgressPack pack={packDetail} />
                    <div className="flex-align-center ml-4">
                      {lg && (
                        <span className="icon-point-icon font-24">
                          <span className="path1" />
                          <span className="path2" />
                        </span>
                      )}

                      <span className="ml-1 font-weight-bold font-24">
                        {convertMoney(
                          packDetail.number_point_one_roll ??
                            packDetail.number_point_ten_roll /
                              NUMBER_ROLL_GACHA.TEN
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <ListTypeCard shopId={shopId} packId={packId} />
                <p className="font-10 pre-wrap  font-weight-bold mt-9">
                  {
                    "注意事項\nオリパでは傷ありが出ることがあります。商品画像はイメージであり、カードの状態や排出状況を表すものではありません。掲載しているカード以外も排出されます。鑑定済みカードにつきましては鑑定機関の定めたグレードを元に販売しており、ケース内部に確認できる傷や異物などに関してのクレームや返品交換は対応いたしかねます。また、未開封品においても、外装に軽い傷や凹み、シュリンクの一部に小さな破れ、シール痕などがある場合がございます。仕様上、商品の交換・返金はできません。ご了承の上お買い求めください。"
                  }
                </p>
              </div>
              <div className={cx(styles.gachaBox, show && styles.boxShawdow)}>
                {packDetail.max_roll_one_user && (
                  <div className="flex-justify-center mx-auto mb-2">
                    <div
                      className={cx(styles.rollNumber, "flex-space-between")}
                    >
                      <Typography className="font-10">残り購入回数</Typography>
                      <Typography className="font-10">
                        {packDetail.max_roll_one_user - (numberRollPack ?? 0)}/
                        {packDetail.max_roll_one_user} 回
                      </Typography>
                    </div>
                  </div>
                )}
                <div className="flex-justify-center">
                  <BtnGacha
                    numberGachaRoll={NUMBER_ROLL_GACHA.ONE}
                    numberPointRoll={
                      packDetail.number_point_one_roll ??
                      packDetail.number_point_ten_roll / NUMBER_ROLL_GACHA.TEN
                    }
                    className={cx(styles.btnGacha1, "flex-center")}
                    handleClick={handleSubmit}
                    isDisable={
                      (packDetail.start_sale_at &&
                        isReadyReleaseCard(packDetail.start_sale_at)) ||
                      packDetail.status ===
                        PACKS_LIST_STATUS[STATUS_SOLD_OUT] ||
                      isSubmitting ||
                      !packDetail.number_point_one_roll ||
                      (!!numberRollPack &&
                        packDetail.max_roll_one_user === numberRollPack)
                    }
                  />
                  <BtnGacha
                    numberGachaRoll={NUMBER_ROLL_GACHA.TEN}
                    numberPointRoll={
                      packDetail.number_point_ten_roll ??
                      packDetail.number_point_one_roll * NUMBER_ROLL_GACHA.TEN
                    }
                    className={cx(styles.btnGacha10, "flex-center")}
                    isDisable={
                      (packDetail.start_sale_at &&
                        isReadyReleaseCard(packDetail.start_sale_at)) ||
                      packDetail.status ===
                        PACKS_LIST_STATUS[STATUS_SOLD_OUT] ||
                      isSubmitting ||
                      !packDetail.number_point_ten_roll ||
                      (!!numberRollPack &&
                        packDetail.max_roll_one_user === numberRollPack)
                    }
                    handleClick={handleSubmit}
                  />
                </div>
                <div className="mt-2 flex-justify-center">
                  <BtnGacha
                    numberGachaRoll={NUMBER_ROLL_GACHA.All}
                    numberPointRoll={
                      packDetail.number_point_one_roll
                        ? packDetail.number_point_one_roll *
                          packDetail.number_card_remain
                        : (packDetail.number_point_ten_roll /
                            NUMBER_ROLL_GACHA.TEN) *
                          packDetail.number_card_remain
                    }
                    isDisable={
                      (packDetail.start_sale_at &&
                        isReadyReleaseCard(packDetail.start_sale_at)) ||
                      packDetail.status ===
                        PACKS_LIST_STATUS[STATUS_SOLD_OUT] ||
                      isSubmitting ||
                      !packDetail.number_card_remain ||
                      (!!numberRollPack &&
                        packDetail.max_roll_one_user === numberRollPack) ||
                      !!packDetail.max_roll_one_user
                    }
                    handleClick={handleSubmit}
                  />
                </div>

                <p className="font-10 font-weight-bold text-center">
                  ※ラスト１口まで全て購入となります。
                </p>
              </div>
            </div>
          </Col>
          <Col span={lg ? 6 : 0} className="mt-6">
            <div className={styles.tabNewest}>
              <div className="mb-4">
                <Label content="最新オリパ" bgColor="red" />
              </div>
              {packNewest?.map(item => {
                return (
                  <div className="mb-5" key={item.id}>
                    <Card isPackDetail pack={item} />
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </div>

      {packDetail && (
        <ModalConfirmGacha
          numberCardRemain={packDetail.number_card_remain}
          open={!!gachaCountSelect}
          pack_id={packDetail.id}
          shop_id={Number(shopId)}
          handleCancel={() => setGachaCountSelect(null)}
          gachaCount={gachaCountSelect ?? 0}
          numberPointRoll={numberPointRollOfModal}
        />
      )}
    </div>
  );
};

export default PackDetailScreen;
