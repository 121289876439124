import { memo, useState } from "react";

import { ModalProps, Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { get } from "lodash";

import noImage from "@app/assets/images/no_image_photo.svg";
import Image from "@app/components/atoms/Image/Image";
import ConfirmDialog from "@app/components/molecules/ConfirmDialog/ConfirmDialog";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { getUserInfo, updateUser } from "@app/features/auth/auth";
import { UserInfo } from "@app/features/auth/types/auth.types";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import styles from "./ModalRegisterPhoto.module.scss";

interface ModalConfirmGachaProps extends ModalProps {
  userInfo: UserInfo | null;
  isDisableBtnSubmit: boolean;
  open: boolean;
  handleCancel?: () => void;
  isPreview?: boolean;
}

const ModalRegisterPhoto = ({
  userInfo,
  isDisableBtnSubmit,
  open,
  handleCancel,
  isPreview = false,
  ...rest
}: ModalConfirmGachaProps) => {
  const { md } = useBreakpoint();
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state: RootState) => state.auth);
  const [imageUpload, setImageUpload] = useState<File | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!currentUser) return <></>;

  const handleUpdatePhoto = () => {
    setIsSubmitting(true);
    if (!imageUpload) {
      setIsSubmitting(false);
      handleCancel?.();
      return;
    }

    dispatch(updateUser({ image: imageUpload }))
      .then(res => {
        if (res.payload?.errors) {
          openNotification({
            type: "warning",
            message: res.payload.errors,
          });
        } else {
          handleCancel?.();
          dispatch(getUserInfo());
        }
      })
      .catch(err => {
        openNotification({
          type: "warning",
          message: err.errors,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      {isPreview ? (
        <ConfirmDialog
          width={md ? 504 : 333}
          open={open}
          onCancel={handleCancel}
          title="身分証詳細"
          footer={null}
          className={cx(styles.root)}
        >
          <div className="flex-center">
            <Image width={237} src={userInfo?.image_url} />
          </div>
        </ConfirmDialog>
      ) : (
        <ConfirmDialog
          open={open}
          handleCancel={() => {
            handleCancel?.();
            setImageUpload(undefined);
          }}
          handleSubmit={handleUpdatePhoto}
          title="身分証アップロード"
          width={md ? 504 : 333}
          isDisableBtnSubmit={isDisableBtnSubmit}
          cancelText="キャンセル"
          isLoading={isSubmitting}
          okText="送　信"
          className={cx(styles.root)}
          {...rest}
        >
          <Typography
            className={cx(
              "text-center font-weight-medium",
              md ? "font-16" : "font-13"
            )}
          >
            証明書の写真をアップロードしてください。
            <br />
            運転免許書・健康保健書
          </Typography>
          <div className="flex-center my-5">
            <div className={cx(md ? "" : "text-center")}>
              <img
                className={styles.imageUpload}
                width={136}
                defaultValue={userInfo?.image_url}
                src={
                  imageUpload
                    ? URL.createObjectURL(imageUpload)
                    : userInfo?.image_url ?? noImage
                }
                alt=""
              />
            </div>
            <input
              id="upload"
              hidden
              onChange={e => {
                setImageUpload(get(e, "target.files.0"));
              }}
              accept=".jpg, .png, .jpeg, .gif, .webp"
              type="file"
            />
          </div>
          <div className="d-flex">
            <div
              aria-hidden
              onClick={() => document.getElementById("upload")?.click()}
              className="btnUpload flex-center"
            >
              ファイルを選択
            </div>
            <Typography
              className={cx(
                md ? "ml-4 font-16" : "ml-2 font-11",
                "font-weight-medium anywhere"
              )}
            >
              {imageUpload
                ? imageUpload.name
                : userInfo?.image_path ?? "選択されていません"}
            </Typography>
          </div>
        </ConfirmDialog>
      )}
    </>
  );
};

export default memo(ModalRegisterPhoto);
