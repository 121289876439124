import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory, useParams } from "react-router-dom";

import { openNotification } from "@app/components/molecules/Notification/notification";
import { ERROR_MESSAGES } from "@app/constants/message.constants";
import { getUrlVideo } from "@app/helpers/util.helper";
import { useAppDispatch } from "@app/redux/store";

import {
  CARD_STATUS,
  GachaHistoriesPathsEnum,
  GACHA_STATUS,
  GACHA_TIMEOUT,
  getGachaHistoriesDetail,
  updateGachaCardsFirstRequest,
  FIRST_LIMIT_CARD_REQUEST,
} from "../../gacha-histories";

const useGetGachaHistories = (
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { shopId, packId, gachaId } = useParams<{
    gachaId: string;
    packId: string;
    shopId: string;
  }>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const getGachaHistories = ({
    isGacha,
    id,
  }: {
    isGacha: boolean;
    id?: string;
  }) => {
    const data = {
      id: id ?? gachaId,
      params: {
        card_statuses: [CARD_STATUS.SOLD],
        page: "1",
        limit: FIRST_LIMIT_CARD_REQUEST,
        use_page: "true",
      },
    };

    const redirectToGachaHistory = (url: string) => {
      history.push({
        pathname: GachaHistoriesPathsEnum.RESULTS_PAGE.replace(
          ":shopId",
          shopId
        )
          .replace(":packId", packId)
          .replace(":gachaId", data.id),
        state: {
          urlAnimation: url,
        },
      });
    };

    const fetchWithTimeout = (
      videoUrl: string,
      timeout: number
    ): Promise<string> => {
      return new Promise((resolve, reject) => {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => {
          controller.abort();
          reject(new Error("Timeout"));
        }, timeout);

        fetch(videoUrl, { signal: controller.signal })
          .then(response => {
            clearTimeout(timeoutId);
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.blob();
          })
          .then(url => URL.createObjectURL(url))
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    };

    dispatch(getGachaHistoriesDetail(data))
      .then(unwrapResult)
      .then(async result => {
        if (result.status === GACHA_STATUS.FAIL) {
          setIsSubmitting(false);
          openNotification({
            type: "error",
            message: ERROR_MESSAGES.UA_148,
          });
        }
        if (result.status === GACHA_STATUS.IN_PROGRESS) {
          setTimeout(() => {
            getGachaHistories({
              isGacha,
              id,
            });
          }, GACHA_TIMEOUT);
        }
        if (result.status === GACHA_STATUS.COMPLETED) {
          const videoUrl = getUrlVideo(result);
          if (!isGacha || !videoUrl) {
            dispatch(updateGachaCardsFirstRequest(result));
            return;
          }

          const isIosPlatform =
            typeof navigator !== "undefined" &&
            (/iPad|iPhone|iPod/.test(navigator.userAgent || "") ||
              (navigator.platform === "MacIntel" &&
                navigator.maxTouchPoints > 1));

          if (isIosPlatform) {
            redirectToGachaHistory(videoUrl);
            return;
          }

          fetchWithTimeout(videoUrl, 2000)
            .then(url => {
              dispatch(updateGachaCardsFirstRequest(result));
              redirectToGachaHistory(url);
            })
            .catch(() => {
              dispatch(updateGachaCardsFirstRequest(result));
              redirectToGachaHistory(videoUrl);
            });
        }
      });
  };
  return [getGachaHistories];
};

export default useGetGachaHistories;
