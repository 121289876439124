export enum AuthPathsEnum {
  LOGIN = "/login",
  TEMPLATE = "/template",
  CREATE_USER = "/verify-user-temporary-email/:token",
  TOKEN_EXPIRATION = "/token-expiration",
  FORGOT_PASSWORD = "/forgot-password",
  FORGOT_PASSWORD_SUCCESS = "/forgot-password/success",
  TEMPORAPY_REGISTER = "/register",
  TEMPORAPY_REGISTER_SUCCESS = "/register/success",
  RESET_PASSWORD = "/reset-password/:token",
  MY_PAGE = "/myPage",
  NOT_FOUND = "/404",
  VERIFY_EMAIL = "/verify-user-success-email/:token",
  MAINTENANCE = "/maintenance",
}
