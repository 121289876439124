import { useState } from "react";

import cx from "classnames";
import dayjs from "dayjs";

import styles from "./CountDown.module.scss";

interface TimerProps {
  startTime: string;
}

const Countdown = ({ startTime }: TimerProps) => {
  const [HH, setHH] = useState<string | null>(null);
  const [MM, setMM] = useState<string | null>(null);
  const [SS, setSS] = useState<string | null>(null);

  setTimeout(() => {
    const now = dayjs().local();
    const start = dayjs.tz(startTime);

    const h = start.diff(now, "hours");
    const m = start.diff(now, "minutes") - 60 * h;
    const s = start.diff(now, "seconds") - 60 * 60 * h - 60 * m;

    const hh = h > 9 ? String(h) : `0${String(h)}`.slice(-2);
    const mm = `0${String(m)}`.slice(-2);
    const ss = `0${String(s)}`.slice(-2);
    setHH(hh);
    setMM(mm);
    setSS(ss);
  }, 1000);

  return (
    <div className={cx(styles.timer, "notranslate")}>
      {HH && MM && SS && (
        <>
          <span className={styles.inputTime}>{HH}</span>
          <span className="mx-1 font-weight-bold">:</span>
          <span className={styles.inputTime}>{MM}</span>
          <span className="mx-1 font-weight-bold">:</span>
          <span className={styles.inputTime}>{SS}</span>
        </>
      )}
    </div>
  );
};

export default Countdown;
