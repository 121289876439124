import { memo, useEffect } from "react";

import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Typography from "antd/lib/typography/Typography";
import cx from "classnames";
import { useHistory } from "react-router-dom";

import Logo from "@app/assets/images/logo.png";
import Image from "@app/components/atoms/Image/Image";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { useAppSelector } from "@app/redux/store";

import styles from "./MaintenanceScreen.module.scss";

const MaintenanceScreen = () => {
  const { md } = useBreakpoint();
  const { listSettings } = useAppSelector(state => state.auth);

  const history = useHistory();

  useEffect(() => {
    if (listSettings?.settings?.length) {
      history.push("/");
    }
  }, [history, listSettings?.settings?.length]);

  if (!listSettings) return <LoadingSpinner />;

  return (
    <div className="text-center">
      <Image className={cx(styles.logo)} src={Logo} />
      <section>
        <Typography
          className={cx("font-weight-bold mt-11", md ? "font-20" : "")}
        >
          メンテナンス中
        </Typography>
        <Typography className={cx(md ? "font-13 mt-3" : "font-12 mt-1")}>
          システムアップロード中です。
          <br />
          ご迷惑をお掛けして申し訳ございません。
          <br />
          完了次第公式Twitterでご報告させていただきます。
          <br />
          何卒ご了承ください。
        </Typography>
      </section>
    </div>
  );
};

export default memo(MaintenanceScreen);
