import { memo } from "react";

import cx from "classnames";

import styles from "./StatusFormField.module.scss";

const StatusFormField = ({
  isRequire,
  title,
  className,
}: {
  isRequire?: boolean;
  title: string;
  className?: string;
}) => {
  return (
    <div className={cx(className, "flex-align-center")}>
      <div className={cx(styles.title)}>{title}</div>
      <div
        className={cx(
          styles.btn,
          {
            [styles.isRequire]: isRequire,
          },
          "flex-center"
        )}
      >
        <div className={cx(styles.btnText)}>{isRequire ? "必須" : "任意"}</div>
      </div>
    </div>
  );
};

export default memo(StatusFormField);
