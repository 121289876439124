/* eslint-disable no-irregular-whitespace */
import { useEffect, useState } from "react";

import { Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import NoImage from "@app/assets/images/no_image.png";
import Image from "@app/components/atoms/Image/Image";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import {
  CARD_STATUS,
  cleanGachaHistoriesChecked,
} from "@app/features/gacha-histories/gacha-histories";
import {
  getQueryParams,
  setQueryParams,
} from "@app/helpers/queryParams/queryParams";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import { ParamsDef } from "@app/types/route.types";

import { getShopListUser } from "../../redux/shops.slice";
import { ShopsPathsEnum } from "../../shops";
import { SearchData } from "../../types/shops.types";
import styles from "./ShopListUserScreen.module.scss";

const ShopListUserScreen = () => {
  const { md } = useBreakpoint();
  const [form] = useForm();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { shopListUser } = useSelector((state: RootState) => state.shops);
  const { search } = getQueryParams(location.search);
  const history = useHistory();

  const [queryShopListPage, setQueryShopListPage] = useState<ParamsDef>({
    keyword: search,
    use_page: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getShopListUser(queryShopListPage)).finally(() =>
      setIsLoading(false)
    );
  }, [dispatch, queryShopListPage]);

  const handleSearch = (valueData: SearchData) => {
    const value = valueData.search.trim();
    const searchValue = value.length === 0 ? undefined : value;
    const newParams = {
      keyword: searchValue,
    };
    setQueryShopListPage(newParams);
    window.history.pushState(
      "",
      "",
      `${location.pathname}${searchValue ? "?" : ""}${setQueryParams({
        search: searchValue,
      })}`
    );
  };

  return (
    <>
      <div className={cx(styles.root)}>
        <div className={cx(styles.containerBox)}>
          <div className={cx(styles.container)}>
            <div className={`font-weight-bold font-${md ? 18 : 13}`}>
              獲得カード所持履歴
            </div>
            <div className={cx(styles.listShopBox)}>
              <Form
                form={form}
                initialValues={{ search }}
                onFinish={handleSearch}
              >
                <Form.Item required name="search">
                  <Input
                    placeholder="キーワードを検索"
                    maxLength={100}
                    prefix={
                      <span
                        aria-hidden
                        className="icon-search-icon ml-2"
                        onClick={() => handleSearch(form.getFieldsValue())}
                      />
                    }
                    className={styles.searchInput}
                  />
                </Form.Item>
              </Form>
              <div className={`font-weight-bold pb-6 font-${md ? 15 : 11}`}>
                ※獲得カードの保有期間は2週間となります。2週間以内にPOINT変換又は発送連絡をお願いいたします。
                <br />
                　２週間を超えたカードは自動的にPOINTへ変換されますのであらかじめご了承ください。
              </div>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  {!shopListUser?.shops.length ? (
                    <div>
                      <p
                        className={`text-center text-primary font-${
                          md ? 16 : 14
                        } mt-15`}
                      >
                        検索結果がありません。
                      </p>
                    </div>
                  ) : (
                    <>
                      {shopListUser?.shops.map(shop => (
                        <div key={shop.id} className={cx(styles.shopBox)}>
                          <Image
                            src={shop.image_url ?? NoImage}
                            className={cx(styles.shopImg)}
                          />
                          <div className="flex-1">
                            <div
                              aria-hidden
                              onClick={() => {
                                dispatch(cleanGachaHistoriesChecked());
                                history.push({
                                  pathname:
                                    ShopsPathsEnum.SHOP_LIST_USER_DETAILS.replace(
                                      ":id",
                                      String(shop.id)
                                    ),
                                  search: setQueryParams({
                                    stt: CARD_STATUS.SOLD,
                                  }),
                                });
                              }}
                              className={cx(styles.shopNameBox, {
                                [styles.error]: shop.has_card_exchange,
                              })}
                            >
                              <div className={cx(styles.shopName)}>
                                <span className="icon-map-icon" />
                                <div
                                  className={`font-weight-bold font-${
                                    md ? 15 : 13
                                  }`}
                                >
                                  {shop.name}
                                </div>
                              </div>
                              <div className={md ? "font-11" : "font-7"}>▶</div>
                            </div>
                            {shop.has_card_exchange && (
                              <div
                                className={cx(
                                  md ? "font-13" : "font-11",
                                  "text-primary mt-1"
                                )}
                              >
                                ※期限を迎えるカードが存在します。3日以内にPOINT変換されますのでご確認をお願いします。
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopListUserScreen;
