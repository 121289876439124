/* eslint-disable no-useless-escape */
export const DURATION_NOTIFICATION = 5;

export const PAGINATION_LIMIT_PC = 8;

export const SHOP_LIMIT_SP = 6;

export const SHOP_PACKS_LIMIT_SP = 5;

export const PASSWORD_REGEX =
  /^[A-Za-z\d@$!%*#?&\/\\\"{}|;<>[\]`+~=_,^()-.\:\']*$/g;

export const PHONE_REGEX = /^(?=.*[\d])[\d]{12}$/g;

export const ONLY_NUMBER = /^\d+$/;

export const MODE_ACTIONS = {
  CREATE: "create",
  EDIT: "edit",
};

export const LOCAL_STORAGE_BACK_PAGE_KEY = "backPage";

export const NUMBER_ROLL_GACHA = {
  ONE: 1,
  TEN: 10,
  All: "all",
};

export const PAGE_INFO = {
  "UA-0001": {
    title:
      "オリパオンラインガチャ｜ポケカ通販おすすめオリパ｜トレカボールガチャ",
    description: "",
  },
  "UA-0003": {
    title: "",
    description:
      "のオリジナルカードパックでポケカゲット！不要カードはポイント変換してリトライ可能。準備中のワンピース他、人気カードを随時追加致します。",
  },
  "UA-0004": {
    title: "",
    description:
      "のオリジナルカードパックでポケカゲット！不要カードはポイント変換してリトライ可能。準備中のワンピース他、人気カードを随時追加致します。",
  },
  "UA-0005": {
    title: "結果｜トレカボールガチャ",
    description:
      "のオリジナルカードパックでポケカゲット！不要カードはポイント変換してリトライ可能。準備中のワンピース他、人気カードを随時追加致します。",
  },
  "UA-0006": {
    title: "発送先一覧｜トレカボールガチャ",
    description: "",
  },
  "UA-0007": {
    title: "発送先入力｜トレカボールガチャ",
    description: "",
  },
  "UA-0008": {
    title: "ポイント購入｜トレカボールガチャ",
    description:
      "ポイントを購入するにはログインが必要です。トレカボールガチャで使用するポイントを購入し、好きなガチャを引こう！",
  },
  "UA-0011": {
    title: "獲得カード所持履歴｜トレカボールガチャ",
    description: "",
  },
  "UA-0012": {
    title: "獲得カード所持詳細｜トレカボールガチャ",
    description: "",
  },
  "UA-0013": {
    title: "マイページ｜トレカボールガチャ",
    description: "",
  },
  "UA-0014": {
    title: "ログイン｜トレカボールガチャ",
    description: "",
  },
  "UA-0015": {
    title: "パスワード再設定｜トレカボールガチャ",
    description: "",
  },
  "UA-0016": {
    title: "パスワード再設定メール送信完了｜トレカボールガチャ",
    description: "",
  },
  "UA-0017": {
    title: "仮登録画面｜トレカボールガチャ",
    description: "",
  },
  "UA-0018": {
    title: "仮登録完了｜トレカボールガチャ",
    description: "",
  },
  "UA-0019": {
    title: "新規会員本登録｜トレカボールガチャ",
    description: "",
  },
  "UA-0020": {
    title: "新規会員本登録_確認｜トレカボールガチャ",
    description: "",
  },
  "UA-0021": {
    title: "新規会員本登録_完了｜トレカボールガチャ",
    description: "",
  },
  "UA-0023": {
    title: "仮登録期限切れ画面｜トレカボールガチャ",
    description: "",
  },
  "UA-0024": {
    title: "パスワード再設定入力｜トレカボールガチャ",
    description: "",
  },
  "UA-0025": {
    title: "パスワード再設定完了｜トレカボールガチャ",
    description: "",
  },
  "UA-0026": {
    title: "決済方法選択｜トレカボールガチャ",
    description:
      "ポイントを購入するにはログインが必要です。トレカボールガチャで使用するポイントを購入し、好きなガチャを引こう！",
  },
  "UA-0027": {
    title: "決済方法選択完了｜トレカボールガチャ",
    description:
      "振込先銀行の情報を送付しました。ご登録のメールアドレスより詳細をご確認ください。",
  },
  "UA-0028": {
    title: "振込確認｜トレカボールガチャ",
    description:
      "お振込先銀行の情報ページです。内容をご確認の上、銀行振込を行ってください。",
  },
  "UA-0029": {
    title: "振込完了｜トレカボールガチャ",
    description: "",
  },
  "UA-0031": {
    title: "お問い合わせ｜トレカボールガチャ",
    description:
      "トレカボールガチャのお問い合わせページです。お問い合わせや不明点等ございましたら内容をご入力の上、お問い合わせください。",
  },
  "UA-0050": {
    title: "初めての方へ｜トレカボールガチャ",
    description:
      "本利用規約は、トレカボール（以下「当社」といいます。）が提供するインターネットサービス「トレカボールガチャ」において 当社が提供する各種サービスの利用に際し、 ユーザーが遵守する必要のある事項及び当社とユーザーとの間の権利義務を規定するものです。",
  },
  "UA-0051": {
    title: "初めての方へ｜トレカボールガチャ",
    description:
      "本プライバシーポリシーは、トレカボール（以下「当社」といいます。）が取得する個人情報の取り扱いについて定めています。当社は、本プライバシーポリシー、 個人情報の保護に関する法律、関係法令及び ガイドライン等を遵守し、個人情報を適切に取り扱います。",
  },
  "UA-0052": {
    title: "初めての方へ｜トレカボールガチャ",
    description: "トレカボールガチャの特定商取引法に基づく表記です。",
  },
  "UA-0053": {
    title: "初めての方へ｜トレカボールガチャ",
    description: "",
  },
  PERSONAL_INFORMATION: {
    title: "お支払い情報入力｜トレカボールガチャ",
    description:
      "ポイントを購入するにはログインが必要です。トレカボールガチャで使用するポイントを購入し、好きなガチャを引こう！",
  },
};
