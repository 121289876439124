import cx from "classnames";

import SpinnerImage from "@app/assets/images/spinner.png";

import styles from "./LoadingSpinner.module.scss";

interface LoadingSpinnerProps {
  isFullScreen?: boolean;
}

const LoadingSpinner = ({ isFullScreen }: LoadingSpinnerProps) => {
  return (
    <div
      className={cx(styles.container, {
        [styles.fullScreen]: isFullScreen,
      })}
    >
      <img src={SpinnerImage} className={cx(styles.icon)} alt="" />
    </div>
  );
};

export default LoadingSpinner;
