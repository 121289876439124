import { AxiosResponse } from "axios";

import { api } from "@app/api/api";
import { UserAddress } from "@app/features/auth/auth";

import { UserAddressesEndpointsEnum } from "../constants/user-addresses.endpoints";

const userAddressListApi = (): Promise<AxiosResponse> => {
  const url = UserAddressesEndpointsEnum.USER_ADDRESS_LIST;
  return api.get(url);
};

const updateUserAddressApi = ({
  userAddressId,
  data,
}: {
  userAddressId: string;
  data: UserAddress;
}): Promise<AxiosResponse> => {
  const url = UserAddressesEndpointsEnum.UPDATE_USER_ADDRESS.replace(
    ":user_address_id",
    userAddressId
  );
  return api.patch(url, data);
};

const deleteUserAddressApi = (
  userAddressId: string
): Promise<AxiosResponse> => {
  const url = UserAddressesEndpointsEnum.DELETE_USER_ADDRESS.replace(
    ":user_address_id",
    userAddressId
  );
  return api.delete(url);
};

const createUserAddressApi = ({
  data,
}: {
  data: UserAddress;
}): Promise<AxiosResponse> => {
  const url = UserAddressesEndpointsEnum.CREATE_USER_ADDRESS;
  return api.post(url, data);
};

const userAddressDetailApi = (id: string): Promise<AxiosResponse> => {
  const url = UserAddressesEndpointsEnum.USER_ADDRESS_DETAIL.replace(
    ":user_address_id",
    id
  );
  return api.get(url);
};

const userAddressesApi = {
  userAddressListApi,
  updateUserAddressApi,
  deleteUserAddressApi,
  createUserAddressApi,
  userAddressDetailApi,
};

export default userAddressesApi;
