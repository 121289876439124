import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { get } from "lodash";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import api from "../api/auth.api";
import { AUTH_FEATURE_KEY, MAINTENANCE_FLAG } from "../auth";
import {
  AuthUserDataDef,
  CurrentUser,
  LoginDataDef,
  RefreshTokenDataDef,
  CreateUserDataDef,
  UpdateUserDataDef,
  TokenValidDataDef,
  UserInfo,
  ResetPasswordDataDef,
  ListSettings,
} from "../types/auth.types";

export type AuthState = {
  accessToken: string | null;
  refreshToken: string | null;
  currentUser: CurrentUser | null;
  userInfo: UserInfo | null;
  listSettings: ListSettings | null;
  isMaintenanceGacha: boolean;
};

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  currentUser: null,
  userInfo: null,
  listSettings: null,
  isMaintenanceGacha: false,
};

export const postLogin = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/postLogin`,
  async (
    {
      data,
    }: {
      data: LoginDataDef;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.loginApi(data);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const postRefreshToken = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/postRefreshToken`,
  async (
    {
      data,
    }: {
      data: RefreshTokenDataDef;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.refreshTokenApi(data);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const deleteLogout = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/deleteLogout`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.logoutApi();
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getCurrentUser = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/getCurrentUser`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getCurrentUserApi();
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const postOfficialMember = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/postOfficialMember`,
  async ({ data }: { data: CreateUserDataDef }, { rejectWithValue }) => {
    try {
      const response = await api.createUserApi(data);
      return response.data;
    } catch (error) {
      const err = get(error, "response.data");
      if (!err) {
        throw err;
      } else {
        return rejectWithValue(err);
      }
    }
  }
);

export const updateUser = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/updateUser`,
  async (data: UpdateUserDataDef, { rejectWithValue }) => {
    try {
      const response = await api.updateUserApi(data);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const checkTokenValid = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/checkTokenValid`,
  async (data: TokenValidDataDef, { rejectWithValue }) => {
    try {
      const response = await api.checkTokenValidApi(data);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const forgotPassword = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/forgotPassword`,
  async (data: AuthUserDataDef, { rejectWithValue }) => {
    try {
      const response = await api.forgotPasswordApi(data);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      const statusCode = get(err, "response.status");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue({ error, statusCode });
      }
    }
  }
);

export const temporaryRegister = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/temporaryRegister`,
  async (data: AuthUserDataDef, { rejectWithValue }) => {
    try {
      const response = await api.temporaryRegisterApi(data);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      const statusCode = get(err, "response.status");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue({ error, statusCode });
      }
    }
  }
);

export const getUserInfo = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/getUserInfo`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getUserInfoApi();
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/resetPassword`,
  async (data: ResetPasswordDataDef, { rejectWithValue }) => {
    try {
      const response = await api.resetPasswordApi(data);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      const statusCode = get(err, "response.status");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue({ error, statusCode });
      }
    }
  }
);

export const checkTokenResetPassword = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/checkTokenResetPassword`,
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await api.checkTokenResetPasswordApi(token);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const verifyEmail = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/verifyEmail`,
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await api.verifyEmailApi(token);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const resendConfirmation = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/resendConfirmation`,
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await api.resendConfirmationApi(token);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getListSettings = createAsyncThunk<ListSettings>(
  `${AUTH_FEATURE_KEY}/getListSettings`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getListSettingsApi();
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const authSlice = createSlice({
  name: AUTH_FEATURE_KEY,
  initialState,
  reducers: {
    updateAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    updateRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    clearToken: state => {
      state.accessToken = null;
      state.refreshToken = null;
      state.currentUser = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(postLogin.fulfilled, (state, action) => {
      const { access_token, refresh_token } = action.payload;
      state.accessToken = access_token;
      state.refreshToken = refresh_token;
    });
    builder.addCase(postLogin.rejected, () => {
      return initialState;
    });
    builder.addCase(postRefreshToken.fulfilled, (state, action) => {
      const { access_token, refresh_token } = action.payload;
      state.accessToken = access_token;
      state.refreshToken = refresh_token;
    });
    builder.addCase(postRefreshToken.rejected, () => {
      return initialState;
    });
    builder.addCase(deleteLogout.fulfilled, state => {
      state.accessToken = null;
      state.refreshToken = null;
      state.currentUser = null;
    });
    builder.addCase(deleteLogout.rejected, () => {
      return initialState;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      const response = action.payload;
      const { no_show_alert_gacha_at, no_show_alert_point_exchange_at } =
        response;
      response.no_show_alert_gacha_at =
        !!no_show_alert_gacha_at &&
        !dayjs().tz().isAfter(dayjs(no_show_alert_gacha_at).tz(), "day");
      response.no_show_alert_point_exchange_at =
        !!no_show_alert_point_exchange_at &&
        !dayjs()
          .tz()
          .isAfter(dayjs(no_show_alert_point_exchange_at).tz(), "day");
      state.currentUser = response;
    });
    builder.addCase(getCurrentUser.rejected, state => {
      state.currentUser = null;
    });

    builder.addCase(getUserInfo.pending, state => {
      state.userInfo = null;
    });
    builder.addCase(getUserInfo.rejected, state => {
      state.userInfo = null;
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.userInfo = action.payload;
    });

    builder.addCase(getListSettings.pending, state => {
      state.listSettings = null;
    });
    builder.addCase(getListSettings.rejected, state => {
      state.isMaintenanceGacha = false;
      state.listSettings = {
        settings: [],
        page_info: null,
      };
    });
    builder.addCase(getListSettings.fulfilled, (state, action) => {
      const { settings } = action.payload;
      const valueGachaMaintainFlag = settings?.find(
        item => item.name === MAINTENANCE_FLAG
      )?.value;
      state.isMaintenanceGacha = Boolean(Number(valueGachaMaintainFlag));
      state.listSettings = action.payload;
    });
  },
});

export const { updateAccessToken, updateRefreshToken, clearToken } =
  authSlice.actions;

const authConfig = {
  key: AUTH_FEATURE_KEY,
  storage,
  whitelist: ["accessToken", "refreshToken"],
};

export const LOCAL_STORAGE_AUTH_KEY = "persist:auth";

export const authReducer = persistReducer<AuthState>(
  authConfig,
  authSlice.reducer
);
