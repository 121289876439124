import { PAGE_INFO } from "@app/constants/constants";
import { RouteItemDef } from "@app/types/route.types";

import CommercialLawScreen from "../screens/CommercialLawScreen/CommercialLawScreen";
import PrivacyPolicyScreen from "../screens/PrivacyPolicyScreen/PrivacyPolicyScreen";
import StartupGuideScreen from "../screens/StartupGuideScreen/StartupGuideScreen";
import TermsOfServiceScreen from "../screens/TermsOfServiceScreen/TermsOfServiceScreen";
import { StaticPagePathsEnum } from "../static-page";

const TERMS_OF_SERVICE_SCREEN: RouteItemDef = {
  id: "UA-0050",
  path: StaticPagePathsEnum.TERMS_OF_SERVICE,
  component: TermsOfServiceScreen,
  pageTitle: PAGE_INFO["UA-0050"].title,
};

const PRIVACY_POLICY_SCREEN: RouteItemDef = {
  id: "UA-0051",
  path: StaticPagePathsEnum.PRIVACY_POLICY,
  component: PrivacyPolicyScreen,
  pageTitle: PAGE_INFO["UA-0051"].title,
};

const COMMERCIAL_LAW_SCREEN: RouteItemDef = {
  id: "UA-0052",
  path: StaticPagePathsEnum.COMMERCIAL_LAW,
  component: CommercialLawScreen,
  pageTitle: PAGE_INFO["UA-0052"].title,
};

const STARTUP_GUIDE_SCREEN: RouteItemDef = {
  id: "UA-0053",
  path: StaticPagePathsEnum.USAGE_GUIDE,
  component: StartupGuideScreen,
  pageTitle: PAGE_INFO["UA-0053"].title,
};

export const STATIC_PAGE_ROUTES = [
  TERMS_OF_SERVICE_SCREEN,
  PRIVACY_POLICY_SCREEN,
  COMMERCIAL_LAW_SCREEN,
  STARTUP_GUIDE_SCREEN,
];
