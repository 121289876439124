import { memo } from "react";

import cx from "classnames";

import Button from "@app/components/atoms/Button/Button";

import styles from "./ButtonArrow.module.scss";

interface ButtonProps {
  direction: "right" | "left";
  onClick: () => void;
}

const ButtonArrow = ({ direction, onClick }: ButtonProps) => {
  return (
    <Button className="btn-arrow" onClick={onClick}>
      {direction === "left" && (
        <div className={cx(styles.iconLeft, styles.icon)}>◀</div>
      )}
      {direction === "right" && (
        <div className={cx(styles.iconRight, styles.icon)}>▶</div>
      )}
    </Button>
  );
};
export default memo(ButtonArrow);
