/* eslint-disable no-irregular-whitespace */
import { memo } from "react";

import { Typography, Input, Col } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import TextArea from "antd/lib/input/TextArea";
import cx from "classnames";
import { useHistory, useLocation } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import StatusFormField from "@app/components/atoms/StatusFormField/StatusFormField";
import Helmet from "@app/components/molecules/Helmet/Helmet";
import { PAGE_INFO } from "@app/constants/constants";
import { emailRules, phoneNumberRules } from "@app/helpers/validations.helper";

import { ShopsPathsEnum } from "../../shops";
import styles from "./ContactScreen.module.scss";

const ContactScreen = () => {
  const md = useBreakpoint();
  const history = useHistory();
  const [form] = useForm();

  const {
    state,
  }: {
    state: {
      username?: string;
      email?: string;
      tel?: string;
      type?: string;
      message?: string;
    };
  } = useLocation();

  // const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = () => {
    form.validateFields().then(() => {
      history.push({
        pathname: ShopsPathsEnum.CONTACT_CONFIRM,
        state: form.getFieldsValue(),
      });
    });
  };

  return (
    <div className={cx(styles.root)}>
      <Helmet
        title={PAGE_INFO["UA-0031"].title}
        description={PAGE_INFO["UA-0031"].description}
      />
      <div className={cx(styles.container)}>
        <div className="container">
          <Typography className={cx(`${styles.title} pt-9`)}>
            どんなことでもお気軽にお問い合わせください。
          </Typography>
          <Form form={form} className={cx(styles.root)}>
            <div className="mt-3">
              <StatusFormField
                className="my-2 mr-10"
                title="お名前"
                isRequire
              />
              <Item
                name="username"
                initialValue={state?.username ?? ""}
                rules={[
                  {
                    required: true,
                    message: "入力してください",
                  },
                ]}
              >
                <Input maxLength={255} placeholder="お名前を入力してください" />
              </Item>
            </div>

            <div className="mt-3">
              <StatusFormField
                className="my-2 mr-10"
                title="メールアドレス"
                isRequire
              />
              <Item
                name="email"
                initialValue={state?.email}
                rules={emailRules ?? ""}
              >
                <Input
                  maxLength={255}
                  placeholder="メールアドレスを入力してください"
                />
              </Item>
            </div>

            <div className="mt-3">
              <StatusFormField
                className="my-2 mr-10"
                title="電話番号"
                isRequire
              />
              <Item
                initialValue={state?.tel ?? ""}
                normalize={value => value.trim()}
                name="tel"
                rules={phoneNumberRules}
              >
                <Input
                  maxLength={255}
                  placeholder="お電話番号を入力してください"
                />
              </Item>
            </div>

            <div className="mt-3">
              <StatusFormField
                className="my-2 mr-10"
                title="お問い合わせの種類"
                isRequire
              />
              <Col xs={24} md={10} className="mb-2 mr-10">
                <Item
                  name="type"
                  initialValue={state?.type ?? ""}
                  rules={[
                    {
                      required: true,
                      message: "選択してください",
                    },
                  ]}
                >
                  <Input maxLength={255} placeholder="入力してください" />
                </Item>
              </Col>
            </div>

            <div className="mt-3">
              <StatusFormField
                className="my-2 mr-10"
                title="お問い合わせ内容"
                isRequire
              />
              <Item
                name="message"
                initialValue={state?.message ?? ""}
                rules={[
                  {
                    required: true,
                    message: "入力してください",
                  },
                ]}
              >
                <TextArea
                  rows={4}
                  placeholder="お問い合わせ内容をご記入ください。"
                />
              </Item>
            </div>
          </Form>
        </div>
        <div className="d-flex  flex-align-center flex-justify-center ">
          <Button
            height={md ? "50px" : "38px"}
            className={styles.btn}
            type="primary"
            danger
            withRightArrow
            shape="round"
            onClick={handleSubmit}
          >
            確認画面へすすむ
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(ContactScreen);
