/* eslint-disable no-irregular-whitespace */
import { memo, useEffect, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { List, Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import dayjs from "dayjs";
import { get } from "lodash";
import { useHistory, useParams } from "react-router-dom";

import noImage from "@app/assets/images/no_image_upload.png";
import Button from "@app/components/atoms/Button/Button";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import Helmet from "@app/components/molecules/Helmet/Helmet";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { PAGE_INFO } from "@app/constants/constants";
import { ERROR_MESSAGES } from "@app/constants/message.constants";
import { useAppDispatch } from "@app/redux/store";

import {
  PointExchangesPathsEnum,
  TRANSFER_BANK_STATUS,
  paymentHistoriesDetail,
  updatePaymentHistories,
} from "../../point-exchanges";
import { PaymentDetail } from "../../types/point-exchanges.types";
import styles from "./BankTransferDetailsScreen.module.scss";

const BankTransferDetailsScreen = () => {
  const { md } = useBreakpoint();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { paymentId } = useParams<{ paymentId: string }>();
  const [paymentDetail, setPaymentDetail] = useState<PaymentDetail | null>(
    null
  );

  const [imageUpload, setImageUpload] = useState<File | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setPaymentDetail(null);
    dispatch(paymentHistoriesDetail(paymentId))
      .then(unwrapResult)
      .then(data => {
        if (
          data.status === TRANSFER_BANK_STATUS.FAIL ||
          (data.status === TRANSFER_BANK_STATUS.OPEN &&
            dayjs.tz(data.created_at).add(1, "day").local().isAfter(dayjs()))
        ) {
          setPaymentDetail(data);
        } else {
          history.push(PointExchangesPathsEnum.BANK_TRANSFER_EXPIRATION);
        }
      })
      .catch(() => setPaymentDetail(null));
  }, [dispatch, history, paymentId]);

  const handleSubmit = () => {
    if (!imageUpload) return;
    setIsSubmitting(true);
    dispatch(
      updatePaymentHistories({
        id: paymentId,
        data: { image: imageUpload },
      })
    )
      .then(unwrapResult)
      .then(() => {
        history.push(PointExchangesPathsEnum.BANK_TRANSFER_SUCCESS_SCREEN);
      })
      .catch(err => {
        openNotification({
          type: "warning",
          message: err.errors || ERROR_MESSAGES.UA_00,
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  if (!paymentDetail) return <LoadingSpinner />;

  return (
    <div className={cx(styles.root)}>
      <Helmet
        title={PAGE_INFO["UA-0028"].title}
        description={PAGE_INFO["UA-0028"].description}
      />
      <div className={cx(styles.container)}>
        <div className="container">
          <section>
            <Typography
              className={cx(
                md
                  ? "font-15 font-weight-bold pt-8"
                  : "font-11 font-weight-medium pt-5"
              )}
            >
              この度はご利用いただきありがとうございました。
              <br />
              ご注文内容をご確認後、お支払期限までに手続きを行ってください。
            </Typography>
            <Typography className={cx(styles.title, "mt-4")}>
              振込金額
            </Typography>
            <Typography
              className={cx(
                "text-end py-5 font-weight-bold",
                md ? "font-36" : "font-24"
              )}
            >
              {paymentDetail.point.toLocaleString("en-US")}円
            </Typography>
            <Typography className={cx(styles.title)}>お支払期限</Typography>
            <Typography
              className={cx(
                "font-weight-medium py-3 ml-3",
                md ? "font-15" : "font-12"
              )}
            >
              {dayjs
                .tz(paymentDetail.created_at)
                .add(1, "day")
                .local()
                .format("YYYY/MM/DD　HH:mm　まで")}
            </Typography>
            <Typography className={cx(styles.title, "mt-6")}>
              【振込先】
            </Typography>
            <div
              className={cx("flex-justify-center", md ? "mt-6  ml-3" : "mt-3")}
            >
              <List
                bordered
                className={cx(styles.list, styles.listHead)}
                dataSource={[
                  "金融機関名",
                  "支店名",
                  "口座種類",
                  "口座番号",
                  "口座名義",
                ]}
                renderItem={item => <List.Item>{item}</List.Item>}
              />
              <List
                bordered
                className={cx(styles.list, styles.listBody)}
                dataSource={[
                  "GMOあおぞらネット銀行 0310",
                  "法人営業部　101",
                  "普通預金",
                  "2056159",
                  "カ) フクロウ",
                ]}
                renderItem={item => <List.Item>{item}</List.Item>}
              />
            </div>
            <Typography className={cx(styles.title, "mt-8")}>
              振込明細アップロード
            </Typography>
            <Typography
              className={cx(
                "font-weight-medium mt-3",
                md ? "font-15" : "font-11"
              )}
            >
              お振込の手続き完了後、振込明細の写真やスクリーンショットをアップロードしてください。
            </Typography>
            <div className={cx("gap-26 mt-3", md ? "d-flex" : "")}>
              <div className={cx(md ? "" : "text-center")}>
                <img
                  className={styles.imageUpload}
                  width={md ? "142px" : "100px"}
                  height={md ? "142px" : "100px"}
                  src={imageUpload ? URL.createObjectURL(imageUpload) : noImage}
                  alt=""
                />
              </div>
              <input
                id="upload"
                hidden
                onChange={e => {
                  setImageUpload(get(e, "target.files.0"));
                }}
                accept=".jpg, .png, .jpeg, .gif, .webp"
                type="file"
              />
              <div className={cx(md ? "" : "mt-4", "flex-align-center")}>
                <div
                  aria-hidden
                  onClick={() => document.getElementById("upload")?.click()}
                  className="flex-center btnUpload"
                >
                  ファイルを選択
                </div>
                <Typography
                  className={cx(
                    md ? "ml-4 font-16" : "ml-2 font-11",
                    "font-weight-medium",
                    styles.imageName
                  )}
                >
                  {imageUpload ? imageUpload.name : "選択されていません"}
                </Typography>
              </div>
            </div>
            <div className="text-center">
              <Button
                className={cx(styles.btn)}
                disabled={isSubmitting || !imageUpload}
                type="primary"
                danger
                shape="round"
                onClick={handleSubmit}
              >
                振込完了
              </Button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default memo(BankTransferDetailsScreen);
