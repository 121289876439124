import { memo } from "react";

import cx from "classnames";

import Image from "@app/components/atoms/Image/Image";
import { textOverflow } from "@app/helpers/util.helper";

import { ShopDetail } from "../../shops";
import styles from "./ShopInfo.module.scss";

interface ShopInfoProps {
  shopDetail: ShopDetail | null;
}
const ShopInfo = ({ shopDetail }: ShopInfoProps) => {
  return (
    <div className={cx(styles.content)}>
      <div className={cx(styles.avatar)}>
        <Image className={cx(styles.image)} src={shopDetail?.image_url} />
      </div>
      <div className="flex-1">
        <div className="flex-align-center">
          <span className="icon-map-icon font-14" />
          <span className="font-weight-bold font-13 ml-2 item">
            {shopDetail && textOverflow(shopDetail?.name, 12)}
          </span>
        </div>
        <div className="d-flex mt-1">
          <span className="icon-location-icon font-14" />
          <span className="pre-wrap font-weight-medium font-10 ml-2 item">
            {shopDetail?.address}
          </span>
        </div>
        <div className="flex-align-center mt-1">
          <span className="icon-phone-icon font-14" />
          <span className="font-weight-medium font-10 ml-2 item">
            {shopDetail?.tel}
          </span>
        </div>
        <div className="font-10 font-weight-medium mt-3">
          {shopDetail?.description}
        </div>
      </div>
    </div>
  );
};

export default memo(ShopInfo);
