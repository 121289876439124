import { memo } from "react";

import cx from "classnames";

import styles from "./AlertError.module.scss";

const AlertError = ({
  message,
  maxWidth,
  className,
}: {
  message: string;
  maxWidth?: string;
  className?: string;
}) => {
  return (
    <div style={{ maxWidth }} className={cx(styles.alert, className)}>
      <span className="icon-alert-error" />
      <div className={cx(styles.message)}>{message}</div>
    </div>
  );
};

export default memo(AlertError);
