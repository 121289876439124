import { PAGE_INFO } from "@app/constants/constants";
import { RouteItemDef } from "@app/types/route.types";

import { PointExchangesPathsEnum } from "../point-exchanges";
import BankTransferDetailsScreen from "../screens/BankTransferDetailsScreen/BankTransferDetailsScreen";
import BankTransferExpirationScreen from "../screens/BankTransferExpirationScreen/BankTransferExpirationScreen";
import BankTransferNotifyScreen from "../screens/BankTransferNotifyScreen/BankTransferNotifyScreen";
import BankTransferSuccessScreen from "../screens/BankTransferSuccessScreen/BankTransferSuccessScreen";
import PersonalInformationScreen from "../screens/PersonalInformationScreen/PersonalInformationScreen";
import PointExchangeScreen from "../screens/PointExchangeScreen/PointExchangeScreen";
import EpsilonPointExchangeScreenSuccess from "../screens/PointExchangeScreenSuccess/EpsilonPointExchangeScreenSuccess";
import PointExchangeScreenSuccess from "../screens/PointExchangeScreenSuccess/PointExchangeScreenSuccess";
import SelectPointTransferMethodScreen from "../screens/SelectPointTransferMethodScreen/SelectPointTransferMethodScreen";

const POINT_EXCHANGES_SCREEN: RouteItemDef = {
  id: "UA-0008",
  path: PointExchangesPathsEnum.POINT_EXCHANGES,
  withPadding: true,
  component: PointExchangeScreen,
  isPrivateRoute: true,
  pageTitle: PAGE_INFO["UA-0008"].title,
};

const PERSONAL_INFORMATION_SCREEN: RouteItemDef = {
  id: "PERSONAL_INFORMATION",
  path: PointExchangesPathsEnum.PERSONAL_INFORMATION,
  component: PersonalInformationScreen,
  isPrivateRoute: true,
  pageTitle: PAGE_INFO.PERSONAL_INFORMATION.title,
};

const PAYPAL_POINT_EXCHANGES_SCREEN_SUCCESS: RouteItemDef = {
  id: "paypal_paymentSuccess",
  path: PointExchangesPathsEnum.POINT_EXCHANGES_SUCCESS,
  component: PointExchangeScreenSuccess,
};

const EPSILON_POINT_EXCHANGES_SCREEN_SUCCESS: RouteItemDef = {
  id: "epsilon_paymentSuccess",
  path: PointExchangesPathsEnum.EPSILON_POINT_EXCHANGES_SUCCESS,
  component: EpsilonPointExchangeScreenSuccess,
};

const SELECT_POINT_TRANSFER_METHOD_SCREEN: RouteItemDef = {
  id: "UA-0026",
  path: PointExchangesPathsEnum.SELECT_POINT_TRANSFER_METHOD_SCREEN,
  component: SelectPointTransferMethodScreen,
  isPrivateRoute: true,
  pageTitle: PAGE_INFO["UA-0026"].title,
};

const BANK_TRANSFER_DETAILS_SCREEN: RouteItemDef = {
  id: "UA-0028",
  path: PointExchangesPathsEnum.BANK_TRANSFER_DETAILS_SCREEN,
  component: BankTransferDetailsScreen,
  isPrivateRoute: true,
  pageTitle: PAGE_INFO["UA-0028"].title,
};

const BANK_TRANSFER_NOTIFY_SCREEN: RouteItemDef = {
  id: "UA-0027",
  path: PointExchangesPathsEnum.BANK_TRANSFER_NOTIFY_SCREEN,
  component: BankTransferNotifyScreen,
  isPrivateRoute: true,
  pageTitle: PAGE_INFO["UA-0027"].title,
};

const BANK_TRANSFER_SUCCESS_SCREEN: RouteItemDef = {
  id: "UA-0029",
  path: PointExchangesPathsEnum.BANK_TRANSFER_SUCCESS_SCREEN,
  component: BankTransferSuccessScreen,
  isPrivateRoute: true,
  pageTitle: PAGE_INFO["UA-0029"].title,
};

const BANK_TRANSFER_EXPIRATION_SCREEN: RouteItemDef = {
  id: "UA-0030",
  path: PointExchangesPathsEnum.BANK_TRANSFER_EXPIRATION,
  component: BankTransferExpirationScreen,
  isPrivateRoute: true,
};

export const POINT_EXCHANGES_ROUTES = [
  POINT_EXCHANGES_SCREEN,
  SELECT_POINT_TRANSFER_METHOD_SCREEN,
  BANK_TRANSFER_DETAILS_SCREEN,
  BANK_TRANSFER_NOTIFY_SCREEN,
  BANK_TRANSFER_SUCCESS_SCREEN,
  BANK_TRANSFER_EXPIRATION_SCREEN,
  PERSONAL_INFORMATION_SCREEN,
  PAYPAL_POINT_EXCHANGES_SCREEN_SUCCESS,
  EPSILON_POINT_EXCHANGES_SCREEN_SUCCESS,
];
