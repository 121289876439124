/* eslint-disable no-irregular-whitespace */
import { memo, useState, useEffect } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Typography, Input } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { useHistory, useLocation } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import ButtonBackPage from "@app/components/atoms/ButtonBackPage/ButtonBackPage";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import StatusFormField from "@app/components/atoms/StatusFormField/StatusFormField";
import Helmet from "@app/components/molecules/Helmet/Helmet";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { PAGE_INFO } from "@app/constants/constants";
import { emailRules } from "@app/helpers/validations.helper";
import { useAppDispatch } from "@app/redux/store";

import {
  PAYMENT_TYPE_ID,
  PointExchangesPathsEnum,
  createPaymentLink,
} from "../../point-exchanges";
import styles from "./PersonalInformationScreen.module.scss";

const PersonalInformationScreen = () => {
  const md = useBreakpoint();
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const {
    state,
  }: {
    state?: string;
  } = useLocation();

  useEffect(() => {
    if (!state) {
      history.push(PointExchangesPathsEnum.POINT_EXCHANGES);
    }
  }, [history, state]);

  const handleSubmit = () => {
    form.validateFields().then(() => {
      if (!state) return null;
      setIsSubmitting(true);
      return dispatch(
        createPaymentLink({
          id: state,
          data: {
            payment_type_id: PAYMENT_TYPE_ID.CREDIT_GMO,
            ...form.getFieldsValue(),
          },
        })
      )
        .then(unwrapResult)
        .then(data => {
          window.location.href = data.url;
        })
        .catch(err => {
          openNotification({ type: "warning", message: err.errors });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    });
  };

  return (
    <div className={cx(styles.root)}>
      <Helmet
        title={PAGE_INFO.PERSONAL_INFORMATION.title}
        description={PAGE_INFO.PERSONAL_INFORMATION.description}
      />
      <div className={cx(styles.container)}>
        <div className="container">
          <ButtonBackPage className={cx(md ? "mt-9" : "mt-4")} />
          <Typography className={cx(`${styles.title} pt-9`)}>
            お支払い情報入力
          </Typography>
          <Form form={form} className={cx(styles.root)}>
            <div className="mt-3">
              <StatusFormField
                className="my-2 mr-10"
                title="ユーザ名"
                isRequire
              />
              <Item
                name="user_name"
                rules={[
                  {
                    required: true,
                    message: "ユーザーは、必ず指定してください。",
                  },
                ]}
              >
                <Input maxLength={255} placeholder="お名前を入力してください" />
              </Item>
            </div>
            <div className="mt-3">
              <StatusFormField
                className="my-2 mr-10"
                title="メールアドレス"
                isRequire
              />
              <Item name="user_mail_add" rules={emailRules}>
                <Input
                  maxLength={255}
                  placeholder="メールアドレスを入力してください"
                />
              </Item>
            </div>
          </Form>
        </div>
        <div className="d-flex  flex-align-center flex-justify-center ">
          <Button
            height={md ? "50px" : "38px"}
            className={styles.btn}
            type="primary"
            danger
            disabled={isSubmitting}
            withRightArrow
            shape="round"
            onClick={handleSubmit}
          >
            お支払い画面へ
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(PersonalInformationScreen);
