import { memo } from "react";

import { Typography } from "antd";
import cx from "classnames";

import styles from "./PointChip.module.scss";

interface ChipProps {
  label?: number;
  className?: string;
  handleClick?: () => void;
}

const PointChip = ({ label, className, handleClick }: ChipProps) => {
  const { Text } = Typography;
  return (
    <div
      aria-hidden
      className={cx(className, styles.chip)}
      onClick={handleClick}
    >
      <span className="icon-point-icon">
        <span className="path1" />
        <span className="path2" />
      </span>
      <Text
        strong
        className={cx(styles.label, {
          [styles.labelError]: (label ?? 0) < 0,
        })}
      >
        {label ? label.toLocaleString("en-US") : 0}
      </Text>
    </div>
  );
};

export default memo(PointChip);
