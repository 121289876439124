import { Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";

import Button from "@app/components/atoms/Button/Button";

import { PointExchanges } from "../../types/point-exchanges.types";
import styles from "./Point.module.scss";

type PointProps = {
  pointExchanges: PointExchanges;
  isDisabledBtn?: boolean;
  handleClick: (id: number) => void;
};

const Point = ({ pointExchanges, isDisabledBtn, handleClick }: PointProps) => {
  const { md } = useBreakpoint();
  const { Text } = Typography;

  return (
    <>
      <div className={cx(styles.point)}>
        <div className="flex-space-between">
          <div className={cx(styles.pointBox, "flex-align-center")}>
            <span className={`icon-point-icon font-${md ? 27 : 23}`}>
              <span className="path1" />
              <span className="path2" />
            </span>
            <div className="d-flex flex-align-center gap-8 font-weight-bold">
              <Text className={cx(styles.numberPoint, "font-20")}>
                {pointExchanges.point.toLocaleString("en-US")}
              </Text>
              <Text className="font-10 pt-1">points</Text>
            </div>
          </div>
          <div>
            <Button
              danger
              shape="round"
              type="primary"
              disabled={isDisabledBtn}
              className={cx(styles.btnBuy)}
              onClick={() => handleClick(pointExchanges.id)}
            >
              <span className={`font-${md ? 14 : 12} px-2`}>
                ￥{pointExchanges.money.toLocaleString("en-US")}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Point;
