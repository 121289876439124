import { useEffect } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory, useParams } from "react-router-dom";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";

import { useAppDispatch } from "../../../../redux/store";
import {
  PAYMENT_STATUS,
  PointExchangesPathsEnum,
  paymentHistoriesDetailPaypal,
} from "../../point-exchanges";

const PointExchangeScreenSuccess = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { paymentId } = useParams<{ paymentId: string }>();

  useEffect(() => {
    dispatch(paymentHistoriesDetailPaypal(paymentId))
      .then(unwrapResult)
      .then(data => {
        if (data.status === PAYMENT_STATUS.SUCCESS) {
          history.push(PointExchangesPathsEnum.POINT_EXCHANGES, data.point);
        } else {
          history.push(PointExchangesPathsEnum.POINT_EXCHANGES);
        }
      })
      .catch(() => history.push(PointExchangesPathsEnum.POINT_EXCHANGES));
  }, [dispatch, history, paymentId]);

  return <LoadingSpinner />;
};

export default PointExchangeScreenSuccess;
