/* eslint-disable no-irregular-whitespace */
import { memo, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { useHistory, useLocation } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import StatusFormField from "@app/components/atoms/StatusFormField/StatusFormField";
import Helmet from "@app/components/molecules/Helmet/Helmet";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { PAGE_INFO } from "@app/constants/constants";
import { useAppDispatch } from "@app/redux/store";

import { postFeedBack } from "../../redux/shops.slice";
import { ShopsPathsEnum } from "../../shops";
import styles from "./ContactConfirmScreen.module.scss";

const ContactConfirmScreen = () => {
  const md = useBreakpoint();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useAppDispatch();
  const {
    state,
  }: {
    state: {
      username: string;
      email: string;
      tel: string;
      type: string;
      message: string;
    };
  } = useLocation();

  const handleSubmit = () => {
    setIsSubmitting(true);
    dispatch(postFeedBack(state))
      .then(unwrapResult)
      .then(() => {
        history.push(ShopsPathsEnum.CONTACT_SUCCESS, state);
      })
      .catch(err => {
        openNotification({
          type: "error",
          message: err.errors,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  if (!state) {
    history.push(ShopsPathsEnum.CONTACT);
    return null;
  }

  if (isSubmitting) return <LoadingSpinner />;

  return (
    <div className={cx(styles.root)}>
      <Helmet
        title={PAGE_INFO["UA-0031"].title}
        description={PAGE_INFO["UA-0031"].description}
      />
      <div className={cx(styles.container)}>
        <div className="container">
          <Typography className={cx(`${styles.title} pt-9`)}>
            内容を確認の上、送信ボタンをクリックしてください。
          </Typography>
          <div className={cx(styles.root)}>
            <div className="mt-3">
              <StatusFormField
                className="my-2 mr-10"
                title="お名前"
                isRequire
              />
              <Typography className="ml-3">{state.username}</Typography>
            </div>

            <div className="mt-3">
              <StatusFormField
                className="my-2 mr-10"
                title="メールアドレス"
                isRequire
              />
              <Typography className="ml-3">{state.email}</Typography>
            </div>

            <div className="mt-3">
              <StatusFormField
                className="my-2 mr-10"
                title="電話番号"
                isRequire
              />
              <Typography className="ml-3">{state.tel}</Typography>
            </div>

            <div className="mt-3">
              <StatusFormField
                className="my-2 mr-10"
                title="お問い合わせの種類"
                isRequire
              />
              <Typography className="ml-3">{state.type}</Typography>
            </div>

            <div className="mt-3">
              <StatusFormField
                className="my-2 mr-10"
                title="お問い合わせ内容"
                isRequire
              />
              <Typography className="ml-3 white-space-pre">
                {state.message}
              </Typography>
            </div>
          </div>
        </div>
        <div className="d-flex flex-align-center flex-justify-center gap-20">
          <Button
            height={md ? "50px" : "38px"}
            className={cx(styles.btn, styles.bgLight)}
            type="primary"
            danger
            withLeftArrow
            shape="round"
            onClick={() =>
              history.push({
                pathname: ShopsPathsEnum.CONTACT,
                state,
              })
            }
          >
            入力画面に戻る
          </Button>
          <Button
            height={md ? "50px" : "38px"}
            className={styles.btn}
            type="primary"
            danger
            withRightArrow
            shape="round"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            上記の内容で送信
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(ContactConfirmScreen);
