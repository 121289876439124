import { memo } from "react";

import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";

import styles from "./Label.module.scss";

interface LabelProps {
  content: string;
  bgColor: string;
  textDark?: boolean;
}

const Label = ({ content, bgColor, textDark = false }: LabelProps) => {
  const { md } = useBreakpoint();
  return (
    <div className={cx(styles.labelCard, bgColor && styles[bgColor])}>
      <div
        className={cx(styles.labelTag, textDark ? styles.black : styles.white)}
      />
      <div
        className={cx(
          textDark ? styles.textBlack : styles.textWhite,
          md ? "font-14" : "font-12",
          "font-weight-bold"
        )}
      >
        {content}
      </div>
    </div>
  );
};
export default memo(Label);
