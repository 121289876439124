/* eslint-disable filenames/match-regex */
import qs from "qs";

interface ParsedQsParams {
  [key: string]: undefined | string | string[] | null;
  listId: string[];
}

export const getQueryParams = (path: string) => {
  return qs.parse(path, { ignoreQueryPrefix: true }) as ParsedQsParams;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setQueryParams = (params: any) => {
  return qs.stringify(params, { arrayFormat: "brackets" });
};
