/* eslint-disable no-irregular-whitespace */
import { Typography } from "antd";
import cx from "classnames";
import { useLocation } from "react-router-dom";

import Helmet from "@app/components/molecules/Helmet/Helmet";
import { PAGE_INFO } from "@app/constants/constants";

import { StaticPagePathsEnum } from "../../static-page";
import styles from "../StaticPage.module.scss";

const TermsOfServiceScreen = () => {
  const { pathname } = useLocation();
  return (
    <>
      {pathname === StaticPagePathsEnum.TERMS_OF_SERVICE && (
        <Helmet
          title={PAGE_INFO["UA-0050"].title}
          description={PAGE_INFO["UA-0050"].description}
        />
      )}
      <div className={cx(styles.root, "container pt-5 pb-10")}>
        <Typography.Title className="text-center" level={2}>
          利　用　規　約
        </Typography.Title>
        <Typography className="mt-2">
          本利用規約（以下「本利用規約」といいます。）は、株式会社Aja
          State（以下 「当社」とい
          います。）が提供するインターネットサービス「トレカボールガチャ」において
          当社が提供す
          る各種サービス（以下「本サービス」といいます。）の利用に際し、
          ユーザーが遵守する必要
          のある事項及び当社とユーザーとの間の権利義務を規定するものです。
        </Typography>

        <Typography.Title level={3}>
          第１条（本サービスの内容及び当社の役割）
        </Typography.Title>
        <ol>
          <li>
            本サービスはトレーディングカード等の委託販売、買取及び販売サービスであ
            り、本サービスの内容は本利用規約及び当社のウェブサイトに掲載する利用条
            件等に規定するとおりとします。
          </li>
          <li>
            当社が本サービスにおいて自らトレーディングカード等の買取又は販売を行う
            場合、出品者又は購入者としての当社には本利用規約の委託販売サービスに関
            する規定は適用されないものとします。
          </li>
        </ol>

        <Typography.Title level={3}>第２条（定義）</Typography.Title>
        <Typography>
          本利用規約において、以下の用語は、別途定義されている場合及び文脈上別異
          に解すべきことが明確である場合を除き、以下の意味を有するものとします。
          本条の定義は本規約においても適用されるものとします。
        </Typography>
        <ol>
          <li>
            「本規約」とは、本利用規約、別途当社の定める利用規約及び当社のウェブ
            サイトに掲載する利用条件等の一切をいい、これらはすべて本規約を構成する
            ものとします。
          </li>
          <li>
            「プライバシーポリシー」とは、当社が定めるプライバシーポリシー（名称
            の如何を問わないものとします。）をいいます。
          </li>
          <li>
            「ガイド」とは、当社が定める本サービスに関するガイドをいいます。
          </li>
          <li>
            「ユーザー」とは、本規約の内容に同意して、日本国内において本サービス
            を利用する個人又は法人をいいます。
          </li>
          <li>
            「個人情報」とは、個人情報の保護に関する法律第２条第１項に定義される
            個人情報に該当するものをいいます。
          </li>
          <li>「商品」とは、トレーディングカードその他の商品をいいます。</li>
          <li>
            「コンテンツ」とは、当社又はユーザーが本サービスに掲載・発信した情報
            をいいます。
          </li>
          <li>
            「出品」とは、ユーザーが所有する商品を本サービスにおいて他のユーザー
            が閲覧可能かつユーザーが商品を取引できる状態にすることをいいます。
          </li>
          <li>
            「出品者」とは、本サービスを通じて商品を出品するユーザーをいいます。
          </li>
          <li>
            「出品物」とは、本サービスで出品されている、出品が予定されている、
            出品が予定されていた又は出品されていた商品をいいます。
          </li>
          <li>
            「購入者」とは、本サービスにおいて出品された商品を購入するユーザー
            をいいます。
          </li>
          <li>
            「チャット」とは、本サービス上でユーザー同士がメッセージを送受信し
            合うことができる機能をいいます。
          </li>
          <li>
            「利用契約」とは、ユーザーと当社との間に成立し本規約が適用される本
            サービスの利用にかかる契約をいいます。
          </li>
          <li>
            「販売サービス」とは、当社がユーザーに商品を販売するサービスをいい
            ます。
          </li>
          <li>
            「買取サービス」とは、当社がユーザーから商品を買取するサービスをい
            います。
          </li>
          <li>
            「売上金」とは、委託販売サービスでの販売および買取サービス等によっ
            て生じたユーザーが当社に対して有する金銭債権をいいます。
          </li>
        </ol>

        <Typography.Title level={3}>第３条（ユーザー登録等）</Typography.Title>
        <ol>
          <li>
            本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規
            約を遵守することに同意し、かつ、当社の定める一定の情報（以下「登録情
            報」といいます。）を当社の定める方法で当社に提供することにより、当社に
            対し本サービスの利用の登録を申請することができます。
          </li>
          <li>
            登録申請は必ず本サービスを利用する個人又は法人の代表者若しくは従業員自
            身が行わなければならず、当社が事前に書面（電磁的記録を含みます。以下同
            じ。）により同意をした場合を除き、代理人による登録申請は認められません。
            また、登録希望者は、登録の申請に際し、真実、正確、かつ、最新の情報を当
            社に提供しなければなりません。
          </li>
          <li>
            登録希望者が未成年者である場合は、事前に親権者などの法定代理人の包括的
            な同意を得たうえで本サービスを利用するものとします。登録希望者が未成年
            者である場合、親権者の同意の有無に関して、当社から親権者などの法定代理
            人に対し確認の連絡をする場合があります。かかる連絡を受けた登録希望者は、
            親権者の同意に関して、当社が求めた事項を速やかに回答するものとします。
          </li>
          <li>
            ユーザー及び登録希望者は、複数のユーザー登録を行うことができないものと
            します。
          </li>
          <li>
            当社は、第１項に基づき登録を申請した者が以下の各号のいずれかの事由に該
            当すると当社が判断した場合は、登録を拒否することがあります。
            <ol className="text-roman">
              <li>
                本規約に違反するおそれがあると当社が判断した場合又は登録希望者が本規
                約に違反したことがある場合
              </li>
              <li>
                過去に本サービス又は当社が提供する他のサービスの利用の登録を取り消さ
                れたことがある場合
              </li>
              <li>
                当社に提供された登録情報の全部又は一部につき、虚偽、誤記又は記載漏れ
                があった場合
              </li>
              <li>
                未成年者、成年被後見人、被保佐人又は被補助人のいずれかであって、法定代理人、後見人､
                保佐人又は補助人の同意等を得ていなかった場合
              </li>
              <li>
                反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これ
                に準ずる者を意味します。以下同じ。）である又は資金提供その他を通じて反
                社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢
                力等との何らかの交流若しくは関与を行っている場合
              </li>
              <li>その他、当社が登録を適当でないと判断した場合</li>
            </ol>
          </li>
          <li>
            当社は、前項に従って登録希望者の登録の可否を判断し、当社が登録を認める
            場合にはその旨を登録希望者に通知します。かかる通知により登録希望者の
            ユーザーとしての登録は完了し、利用契約がユーザーと当社の間に成立します。
          </li>
          <li>
            ユーザーは、登録情報に変更があった場合は、遅滞なく、当社の定める方法に
            より当該変更事項を当社に通知し、当社から要求された情報及び資料を提出す
            るものとします。
          </li>
        </ol>

        <Typography.Title level={3}>
          第４条（アカウント情報の管理）
        </Typography.Title>
        <ol>
          <li>
            ユーザーは、自己の責任において、本サービスにかかるユーザーID及びパス
            ワード（以下「アカウント情報」といいます。）を管理及び保管するものとし、
            これを第三者に利用させたり、貸与、譲渡、名義変更又は売買等の行為をした
            りしてはならないものとします。
          </li>
          <li>
            アカウント情報の管理不十分（第三者から推認されやすいパスワードを利用し
            た場合も含みます。）、使用上の過誤、第三者の使用等による損害の責任は
            ユーザーが負うものとし、当社は一切の責任を負いません。
          </li>
          <li>
            ユーザーは、アカウント情報が盗まれ又は第三者に使用されていることが判明
            した場合、直ちにその旨を当社に通知し、当社の指示に従うものとします。
          </li>
        </ol>

        <Typography.Title level={3}>
          第５条（ユーザー登録の取消等）
        </Typography.Title>
        <ol>
          <li>
            当社は、ユーザーが以下の各号のいずれかに該当したと当社が判断した場合、
            事前の通知なしに、ユーザー登録の取消、本サービスの全部若しくは一部へのアクセスの拒否・利用停止又はユーザーに関連するコンテンツや情報の全部若
            しくは一部の削除の措置等をとることができるものとします。なお、当社は、
            ユーザーが以下の各号のいずれにも該当しないことを確認するために当社が必
            要と判断する確認を行うことができ、当該確認が完了するまでの間本サービス
            の全部若しくは一部へのアクセスの拒否・利用停止等の措置することができま
            す。なお、本条に関連して当社から照会を受けたユーザーは、速やかに当社に
            対して当該照会を受けた事項に関して回答を行うものとします。
            <ol className="text-roman">
              <li>法令又は本規約に違反した場合</li>
              <li>不正行為があった場合</li>
              <li>登録した情報が虚偽の情報である場合</li>
              <li>登録した情報が既存の登録情報と重複している場合</li>
              <li>
                登録した携帯電話番号又はメールアドレスが不通になったことが判明した場合
              </li>
              <li>
                本規約上必要となる手続又は本規約上求められている当社への連絡を行わなかった場合
              </li>
              <li>
                ユーザーが債務超過、無資力、支払停止又は支払不能の状態に陥った場合
              </li>
              <li>
                他のユーザーや第三者に不当に迷惑をかける等、本サービスの運営に支障を生じさせた場合
              </li>
              <li>
                登録した金融機関の口座に関し、違法又は不適切その他の問題があることが当該金融機関による指摘その他により判明した場合
              </li>
              <li>第３条第５項各号のいずれかに該当する場合</li>
              <li>
                １２か月上本サービスの利用がなく、当社からの連絡に対して応答がない場合
              </li>
              <li>
                ユーザーが自ら又は第三者を通じて、暴力的な要求行為、法的な責任を超えた不当な要求行為、脅迫的な言動又は暴力を用いる行為、風評を流布し、偽計を用い又は威力を用いて、信用を毀損又は業務を妨害する行為をした場
                合
              </li>
              <li>本項に基づく当社からの照会に対して応答がない場合</li>
              <li>その他当社がユーザーに相応しくないと判断した場合</li>
            </ol>
          </li>

          <li>
            当社は、本条の措置を受けたユーザーに対し、将来にわたって当社が提供する
            サービスの利用及びアクセスを禁止することができるものとします。
          </li>
          <li>
            当社は、本条の措置の時点で当該ユーザーに支払われることとなっていた金銭
            等について、違法行為への関与が疑われる場合その他当社が必要と判断した場
            合、支払いを留保することができるものとします。
          </li>
          <li>
            当社は、本条の措置によりユーザーに生じる損害について、一切の責任を負わ
            ないものとします。
          </li>
          <li>
            当社は、本条の措置に関する理由をユーザーに説明する義務を負わないものと
            します。
          </li>
        </ol>

        <Typography.Title level={3}>第６条（ユーザーの退会）</Typography.Title>
        <ol>
          <li>
            ユーザーは、当社の定める方法で当社に退会を希望する旨を申請し、当社の判
            断により認められた場合には退会することができます。
          </li>
          <li>
            前項の規定にかかわらず、取引の決済や商品の郵送や商品代金の引出申請等取
            引の手続が未了のものがある場合は、ユーザーは退会することができず、当該
            ユーザーは一連の未了の取引を本規約に従って遅滞なく円滑に進め完了させた
            後、当社の定める方法で当社に再度申請を行わなければなりません。
          </li>
          <li>
            当社は、本条の措置によりユーザーに生じる損害について一切の責任を負わな
            いものとします。
          </li>
        </ol>

        <Typography.Title level={3}>
          第７条（個人情報等の取扱い）
        </Typography.Title>
        <ol>
          <li>
            当社は、本規約のほか、プライバシーポリシーに従って個人情報等を取り扱い
            ます。
          </li>
          <li>
            ユーザーは、本サービスの利用又は本サービスの閲覧の前に、本サービス上で
            プライバシーポリシーを必ず確認し、その内容に同意した上で本サービスを利
            用するものとします。
          </li>
          <li>
            当社は、本サービスを通じて得た個人情報等に関して、プライバシーポリシー
            の利用目的として記載された事項及び本サービス全般の提供のために利用する
            ことができるものとします。
          </li>
          <li>
            当社は、本サービスによる商品の売買に関するユーザー間のトラブルの解決の
            ため当社が必要と判断した場合、他のユーザーにユーザーの連絡先を提供することができます。かかる提供について、ユーザーは予め同意するものとします。
          </li>
          <li>
            当社は、クレジットカードの第三者による不正利用を防止することを目的とし
            て、第三者に対して個人情報を提供することができます。かかる提供について、
            ユーザーは予め同意するものとします。
          </li>
          <li>
            ユーザーは、本サービスを通じて得た個人情報等の取扱いにあたっては、個人
            情報の保護に関する法律その他の法令に従うものとします。
          </li>
        </ol>

        <Typography.Title level={3}>第８条（禁止事項）</Typography.Title>
        <Typography>
          当社は、本サービスの利用に関してユーザー及び第三者の以下の各号に該当す
          ると当社が判断する行為（以下「禁止事項」といいます。）を禁止し、ユー
          ザーは故意又は過失の有無にかかわらず禁止事項に該当する行為を行うことが
          本規約違反とみなされることを同意します。以下の禁止事項は、当社により適
          宜追加修正される場合があり、ユーザーは実際に本サービスを利用される際に
          は常に最新の内容を確認する義務を負うものとします
        </Typography>
        <ol>
          <li>
            取引・決済等について
            <ul>
              <li>
                通常の経済的価値と著しくかい離した販売価格により商品を出品すること
              </li>
              <li>お互いの出品商品を、値下げして購入し合う行為</li>
              <li>
                交換及び半交換並びにそれらを本サービス内外で持ちかける行為
              </li>
              <li>
                出品した商品の代金の全部若しくは一部を本サービス上の決済手段以外の
                手段で決済しようとする行為
              </li>
              <li>当社が認めない方法で決済をするよう持ちかける行為</li>
              <li>同じ商品が含まれる商品の複数出品</li>
              <li>古物商間の売買又は交換に該当する行為</li>
              <li>海外から商品を配送する行為</li>
              <li>
                他のユーザーに出品代行させる行為及び他のユーザーに代わり出品代行す
                る行為
              </li>
            </ul>
          </li>
          <li>
            他のユーザーとの関係等について
            <ul>
              <li>
                他のユーザーのユーザー資格を利用して当社のサービスを利用すること
              </li>
              <li>当社又は他のユーザーの利益を侵害すること</li>
              <li>
                当社若しくは他のユーザーの著作権を含む知的財産権その他の権利を侵害すること又はそのおそれのある行為
              </li>
              <li>
                他のユーザーが、理解することができなかったり、誤解や混乱をしたりす
                るおそれのある行為
              </li>
              <li>
                他のユーザーのプライバシーを侵害したり、名誉を毀損したり、精神的損
                害を与えること
              </li>
              <li>
                当社の所定の手続以外で、ユーザー本人又は他のユーザーの個人に関する
                情報（本名、住所、電子メールアドレス及び電話番号を含むあらゆる連絡
                先）を発信若しくは掲載すること
              </li>
              <li>当社又は他のユーザーに経済的損害を与えること</li>
              <li>他のユーザーに損害を与えること</li>
              <li>他のユーザーが迷惑や不快を感じるおそれのある行為</li>
              <li>他のユーザーと紛争が生じる可能性のある行為</li>
              <li>
                他のユーザーに対し、トレーディングカード等の売買目的以外の目的でチャットによりメッセージを送信すること
              </li>
              <li>異性交際に関する情報を送信する行為</li>
              <li>低俗、わいせつな書き込み、配信などを行うこと</li>
              <li>虚偽の情報の書き込み、配信などを行うこと</li>
              <li>民族差別・人種差別を意識させたり、それらにつながる行為</li>
            </ul>
          </li>
          <li>
            その他
            <ul>
              <li>法令等又は本規約に違反する行為</li>
              <li>本規約の趣旨に照らして不適切な行為</li>
              <li>当社のご利用上の注意に反する行為</li>
              <li>当社のサービス運営を妨げること</li>
              <li>自分の氏名又は自己のユーザーID以外の人物を名乗ること</li>
              <li>青少年の心身に悪影響を与える行為</li>
              <li>公序良俗に反する行為</li>
              <li>倫理的視点で認められないと当社が判断する行為</li>
              <li>
                当社の事前の書面による許可なく、当社のサービス外のところで、商業目
                的で、当社が提供するあらゆるサービス、コンテンツ、情報、システム、
                機能、プログラム等の全部又は一部を利用すること
              </li>
              <li>商業用の広告、宣伝を目的とした行為</li>
              <li>
                当社が運営する以外のウェブサイトやリソースへリンクを貼ること
              </li>
              <li>勧誘活動等を行うこと</li>
              <li>選挙運動に関するあらゆる行為</li>
              <li>
                コンピュータ・ウィルスの送信等、コンピュータの機器や通信回線、ソフ
                トウェア等の機能に悪影響を及ぼす行為
              </li>
              <li>
                当社が提供するサービスに繋がっているサーバーやネットワークに対して
                悪影響を及ぼすこと
              </li>
              <li>
                当社がサービスを提供する上で関係するあらゆるシステムに対して、不正
                にアクセスすること
              </li>
              <li>
                当社が提供するインターフェイスとは別の手法を用いてサービスにアクセ
                スすること
              </li>
              <li>
                当社のウェブサイトに関連するシステムやソフトウェアのセキュリティ
                ホールやエラー、バグ等を利用した行為
              </li>
              <li>
                当社のウェブサイトに関連するシステムやソフトウェア、プロトコル等を
                リバースエンジニアリングや逆アセンブル等の手法により解読する行為、
                これらを改ざん、修正等する行為、及びこれらを複製、二次利用する行為
              </li>
              <li>マネー・ロンダリングを目的とした行為</li>
              <li>その他、当社が不適切と考える行為</li>
            </ul>
          </li>
        </ol>

        <Typography.Title level={3}>
          第９条（ポイントの取り扱い）
        </Typography.Title>
        <ol>
          <li>
            当社は、ユーザーが本サービスにおいて当社が別途指定する基準を満たした場
            合、ポイントを付与することができます。ユーザーは、かかるポイントを第６
            項に定める有効期間中本サービスにおける商品の購入時に１ポイント=１円と
            して支払うことができ、使用されたポイントに相当する金銭を当社が出品者に
            支払うものとします。
          </li>
          <li>
            ユーザー（第５条に定める措置を受けたユーザーを除く。）は本サービスによ
            る取引完了時から９０日以内の引出申請前の売上金を１ポイント=１円として
            １円単位でポイントに交換（ユーザーが当社に対して有する売上金支払請求権
            を代物弁済として有償ポイントの購入代金に充当する。）することができます。
            ただし、前記ポイントは本サービスにおいてのみ使用できます。
          </li>
          <li>
            前項に基づきユーザーがポイント交換手続を行ったことを当社が確認した場合、
            当社は、ユーザーに購入金額相当分のポイントを付与することにより、交換が
            完了するものとします。ユーザーは、完了した交換手続を取り消すことはできません。
          </li>
          <li>
            ポイントを交換したユーザーは、交換完了後速やかに、残高に誤りがないかを
            確認し、誤りがあった場合直ちに当社に申し出るものとします。
          </li>
          <li>
            ユーザーによるポイントの取得率（第１項による取得の場合に限ります。）、
            取得条件、失効条件、利用できる対象商品等の各種条件は、本規約に定めると
            ころを除き、当社が別途指定するものとします。
          </li>
          <li>
            ユーザーは、当社が延長を認めた場合を除き、ポイントを獲得した日から９０
            日間（当社がユーザーがポイントを取得する前にこれよりも短い期間を定めた
            場合には、当該期間）に限定して、ポイントを利用することができます。当社
            は、ポイントの有効期間から一定期間前にユーザーに対して有効期間の終了を
            告知する義務はいかなる意味でも負担せず、当社が任意にユーザーに対して有
            効期限を告知したとしてもそれを継続して告知することはないことを確認する
            ものとします。
          </li>
          <li>
            ポイント有効期間の起算点は、当社がユーザーにポイントを付与した日とし、
            キャンセルその他の理由によりユーザーが使用したポイントがユーザーに返還
            された場合であっても、これと異なる取り扱いはいたしません。
          </li>
          <li>
            ユーザーが退会した場合又は登録が取り消された場合には、第６項の有効期間
            内であっても、当該ユーザーが保有するポイントは退会時点又は取消時点にお
            いて全て失効いたします。当社は、退会時又は取消時に失効するポイント数を
            ユーザーに連絡しないことをユーザーは同意するものとします。
          </li>
          <li>
            ユーザーは、当社が付与したポイントを本サービスにのみ使用するものとし、
            当該ポイントを第三者へ譲渡・売買することや、本サービスにおける取引以外
            の決済手段として利用することはできません。ユーザーは、第三者からこれら
            の禁止行為を行うよう勧誘を受けた場合には、当社に連絡するものとします。
          </li>
          <li>
            当社は、いかなる場合であっても、ポイントを現金に換算の上ポイント保有者に払い戻すことはありません。
          </li>
        </ol>
        <Typography.Title level={3}>
          第１０条（他のサービスへの移行）
        </Typography.Title>
        <ol>
          <li>
            ユーザーが本サービスを利用するにあたり、本サービスから当社の関連会社又
            は第三者サービスに移行する場合があります。ユーザーは、予めこれに同意す
            るものとし、本規約及び第三者サービスの利用規約等を遵守して本サービス及
            び第三者サービスを利用するものとします。
          </li>
          <li>
            第三者サービスへの移行後に本サービスの利用を継続したユーザーは、第三者
            サービスの利用規約等に同意したものとみなします。当社は、第三者サービス
            について何等の保証を行わないものとし、ユーザー及び第三者が第三者サービ
            スを利用することにより生じる損害について一切責任を負わないものとします。
          </li>
        </ol>

        <Typography.Title level={3}>
          第１１条（本サービスの停止等）
        </Typography.Title>
        <ol>
          <li>
            当社は、以下のいずれかに該当する場合には、ユーザーに事前に通知すること
            なく本サービスの利用の全部又は一部を停止又は中断することができるものと
            します。
            <ol className="text-roman">
              <li>
                本サービスにかかるコンピュータ・システムの点検又は保守作業を定期的又
                は緊急に行う場合
              </li>
              <li>コンピュータ、通信回線等が事故により停止した場合</li>
              <li>
                火災、停電、天災地変などの不可抗力により本サービスの運営ができなく
                なった場合
              </li>
              <li>
                他の事業者が提供している当社所定のサービスで本サービスの実施に利用さ
                れるサービス（以下「第三者サービス」といいます。）に、トラブル、サー
                ビス提供の中断又は停止、本サービスとの連携の停止、仕様変更等が生じた
                場合
              </li>
              <li>その他、当社が停止又は中断を必要と判断した場合</li>
            </ol>
          </li>
          <li>
            当社は、当社の都合により本サービスの提供を終了することができます。この場合、当社はユーザーに事前に通知するものとします。
          </li>
          <li>
            当社は、本条に基づき当社が行った措置に基づきユーザーに生じた損害につい
            て一切の責任を負いません。
          </li>
        </ol>

        <Typography.Title level={3}>第１２条（権利帰属）</Typography.Title>
        <ol>
          <li>
            本サービスに関する所有権及び知的財産権は全て当社又は当社にライセンスを
            許諾している者に帰属しており、本規約に定める登録に基づく本サービスの利
            用許諾は本規約において明示されているものを除き本サービスに関する当社又
            は当社にライセンスを許諾している者の知的財産権の譲渡又は使用許諾を意味
            するものではありません。ユーザーは、いかなる理由によっても当社又は当社
            にライセンスを許諾している者の知的財産権を侵害するおそれのある行為（逆
            アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに
            限定されません。）をしないものとします。
          </li>
          <li>
            当社は、出品物の写真、動画、情報その他のコンテンツを無償で自由に利用
            （複製、複写、改変、第三者への再許諾その他のあらゆる利用を含みます。）
            することができるものとします。
          </li>
          <li>
            ユーザー等がサービスに関連して発信又は掲載したコンテンツに関する一切の
            責任は当該ユーザー等が負うものとし、当社はその内容、品質、正確性、信憑
            性、適法性、最新性、有用性等について確認いたしません。また、当社は、そ
            れらに関して一切保証せず、一切責任を負わないものとします。
          </li>
          <li>
            ユーザー等は、他のユーザー等が発信又は掲載するコンテンツに対して、その
            内容、品質、正確性、信憑性、適法性、最新性、有用性等をユーザー等自身で
            判断する必要があります。当社は、ユーザー等及び第三者が当社のコンテンツ
            を利用することにより生じる損害について一切責任を負わないものとします。
          </li>
          <li>
            当社はコンテンツのバックアップを行う義務を負わないものとします。ユー
            ザーは、コンテンツのバックアップが必要な場合には自己の費用と責任でこれを行うものとします。
          </li>
          <li>
            当社は、ユーザーが本規約に違反又は本規約の精神に照らして不適切な行為を
            行ったと当社が判断した場合、当該ユーザーが掲載したあらゆるコンテンツを
            事前の通知なしに変更及び削除できるものとします。
          </li>
        </ol>

        <Typography.Title level={3}>第１３条（権利帰属）</Typography.Title>
        <ol>
          <li>
            本サービスの提供を受けるために必要なコンピュータ、ソフトウェアその他の
            機器、通信回線その他の通信環境等の準備及び維持は、ユーザーの費用と責任
            において行うものとします。
          </li>
          <li>
            ユーザーは、自己の本サービスの利用環境に応じてコンピュータ・ウィルスの
            感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの
            費用と責任において講じるものとします。
          </li>
          <li>
            当社は、ユーザーが送受信したメッセージその他の情報を運営上一定期間保存
            していた場合であっても、かかる情報を保存する義務を負うものではなく、当
            社はいつでもこれらの情報を削除できるものとします。なお、当社はかかる情
            報の削除に基づきユーザーに生じた損害について一切の責任を負いません。
          </li>
          <li>
            ユーザーは、本サービスの利用開始に際し又は本サービスの利用中に本サービ
            スからのダウンロードその他の方法によりソフトウェア等をユーザーのコン
            ピュータ等にインストールする場合には、ユーザーが保有する情報の消滅若し
            くは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとし、
            当社はユーザーに発生したかかる損害について一切責任を負わないものとしま
            す。
          </li>
        </ol>
        <Typography.Title level={3}>
          第１４条（保証の否認及び免責）
        </Typography.Title>
        <ol>
          <li>
            当社は、本サービスの内容・品質・水準、本サービスの安定的な提供及び本
            サービスの利用に伴う結果等につき、いかなる保証も行うものではありません。
            本サービスは現状有姿で提供されるものであり、当社は本サービスについて特
            定の目的への適合性、商業的有用性、完全性、継続性等を含め一切保証しませ
            ん。
          </li>
          <li>
            ユーザーが当社から直接又は間接に、本サービス、本サービスの他のユー
            ザーその他の事項に関する何らかの情報を得た場合であっても、当社はユー
            ザーに対し本規約において規定されている内容を超えていかなる保証も行うも
            のではありません。
          </li>
          <li>
            本サービスは第三者サービスと連携することがありますが、かかる連携を保証
            するものではなく、本サービスにおいて第三者サービスと連携できなかった場
            合でも当社は一切の責任を負いません。
          </li>
          <li>
            本サービスが第三者サービスと連携している場合において、ユーザーは当該第
            三者サービスの利用規約を自己の費用と責任で遵守するものとし、ユーザーと
            当該第三者サービスを運営する事業者との間で紛争等が生じた場合でも当社は
            当該紛争等について一切の責任を負いません。
          </li>
          <li>
            ユーザーは、本サービスを利用することがユーザーに適用のある法令等に違反
            するか否かを自己の責任と費用に基づいて調査するものとし、当社はユーザー
            による本サービスの利用がユーザーに適用のある法令等に適合することを何ら
            保証するものではありません。
          </li>
          <li>
            本サービスに関連してユーザーと他のユーザー、第三者サービスの事業者その
            他の第三者との間において生じた取引、連絡、紛争等については、ユーザーの
            責任において処理及び解決するものとし、当社はかかる事項について一切責任
            を負いません。
          </li>
          <li>
            当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、
            ユーザーのメッセージ又は情報の削除又は消失､ ユーザーの登録の取消、本
            サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本
            サービスに関連してユーザーが被った損害につき、賠償する責任を一切負わな
            いものとします。
          </li>
          <li>
            本サービスから他のウェブサイトへのリンク又は他のウェブサイトから本サー
            ビスへのリンクが提供されている場合でも、当社は本サービス以外のウェブサ
            イト及びそこから得られる情報に関して如何なる理由に基づいても一切の責任
            を負わないものとします。
          </li>
          <li>
            当社は、本サービスに関連してユーザーが被った損害について、当社に故意又
            は重過失がある場合を除き一切賠償の責任を負いません。消費者契約法の適用
            その他の理由により当社がユーザーに対して損害賠償責任を負う場合においても、当社の賠償責任は当社の債務不履行又は不法行為によりユーザーに生じた損害のうち現実に発生した直接かつ通常の損害に限り、かつ、損害の事由が生じた時点から遡って過去１か月間に当社が当該ユーザーから現実に受領した以
            下の金額を上限とします。
            <ol className="text-roman">
              <li>売買契約の出品者に対して：販売手数料</li>
              <li>売買契約の購入者に対して：商品の代金</li>
            </ol>
          </li>
          <li>
            当社は、ユーザーの青少年の健全な利用の推進、本サービスの犯罪利用の阻
            止、ユーザーの本規約遵守状況の確認及び本サービスの改善を目的として、
            ユーザーによる送信データなどのコンテンツの閲覧・記録を行うことができ
            るものとします。
          </li>
        </ol>

        <Typography.Title level={3}>
          第１５条（ユーザーの賠償等の責任）
        </Typography.Title>
        <ol>
          <li>
            ユーザーは、本規約に違反することにより当社に損害を与えた場合又は本サー
            ビスの利用に関連して当社に損害を与えた場合、当社に対しその損害を賠償し
            なければなりません。
          </li>
          <li>
            ユーザーは、本サービスに関連して他のユーザー、第三者サービスの事業者そ
            の他の第三者との間で紛争が生じた場合（それらの者からクレームを受けた場
            合を含みます。）、直ちにその内容を当社に通知するとともに、ユーザーの費
            用と責任において当該紛争又はクレームを処理し、当社からの要請に基づきそ
            の経過及び結果を当社に速やかに報告するものとします。
          </li>
          <li>
            ユーザーによる本サービスの利用に関連して、当社が他のユーザー、第三者
            サービスの事業者その他の第三者から権利侵害その他の理由により何らかの請
            求を受けた場合は、ユーザーは当該請求に基づき当社が当該第三者に支払を余
            儀なくされた金額又は当該請求に基づき当社に発生した費用を賠償しなければ
            なりません。
          </li>
        </ol>

        <Typography.Title level={3}>第１６条（本規約の変更）</Typography.Title>
        <ol>
          <li>
            当社は、ユーザーの同意なくして本サービスの内容を自由に変更できるものと
            します。
          </li>
          <li>
            当社は、本規約の変更を行う場合、変更後の本規約の効力発生日を定め、効力
            発生日の２週間前までに変更後の本規約の内容及び効力発生日を当社所定の方
            法によりユーザーに周知するものとします。
          </li>
        </ol>

        <Typography.Title level={3}>第１７条（連絡及び通知）</Typography.Title>
        <Typography>
          本サービスに関する問い合わせその他ユーザーから当社に対する連絡又は通知、
          及び本規約の変更に関する通知その他当社からユーザーに対する連絡、通知、
          又は周知は、当社の定める方法で行うものとします。
        </Typography>

        <Typography.Title level={3}>
          第１８条（本規約の譲渡等）
        </Typography.Title>
        <ol>
          <li>
            ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位又は本規約
            に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、そ
            の他の処分をすることはできません。
          </li>
          <li>
            当社は本サービスにかかる事業を第三者に譲渡（事業譲渡、会社分割その他態
            様の如何を問わないものとします。）した場合には、当該譲渡に伴い利用契約
            上の地位、本規約に基づく権利及び義務並びにユーザーの登録情報その他の顧
            客に関する情報を当該譲渡の譲受人に譲渡することができるものとし、ユー
            ザーはかかる譲渡につき予め同意したものとします。
          </li>
        </ol>

        <Typography.Title level={3}>第１９条（分離可能性）</Typography.Title>
        <ol>
          <li>
            本規約のいずれかの条項又はその一部が消費者契約法その他の法令等により無
            効又は執行不能と判断された場合であっても、無効又は執行不能と判断された
            規定以外の残りの規定は継続して完全に効力を有することにユーザーは予め同
            意します。
          </li>
          <li>
            当社及びユーザーは、当該無効又は執行不能と判断された条項を適法かつ執行
            力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項に
            関し法律的及び経済的に同等の効果を確保できるように努力するものとします。
          </li>
        </ol>

        <Typography.Title level={3}>第２０条（言語）</Typography.Title>
        <Typography>
          本規約は、日本語を正文とします。本規約について参考のために英語による翻
          訳文が作成された場合であっても、日本語の正文だけが契約としての効力を有
          するものとし、英訳は何ら効力を有しないものとします。
        </Typography>

        <Typography.Title level={3}>
          第２１条（準拠法及び管轄裁判所）
        </Typography.Title>
        <Typography>
          本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争につい
          ては東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </Typography>

        <Typography.Title level={3}>第２２条（協議解決）</Typography.Title>
        <Typography>
          当社及びユーザーは、本規約に定めのない事項又は本規約の解釈に疑義が生じ
          た場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るもの
          とします。
        </Typography>
        <Typography className="text-end mt-2">以　上</Typography>
      </div>
    </>
  );
};

export default TermsOfServiceScreen;
