/* eslint-disable no-irregular-whitespace */
import { useEffect, useMemo, useState } from "react";

import { Col, Divider, Row, Input, Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { useHistory } from "react-router-dom";

import camera from "@app/assets/images/camera.svg";
import Button from "@app/components/atoms/Button/Button";
import ButtonBackPage from "@app/components/atoms/ButtonBackPage/ButtonBackPage";
import Form, { Item } from "@app/components/atoms/Form/Form";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import ConfirmDialog from "@app/components/molecules/ConfirmDialog/ConfirmDialog";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { handleDirectPointPage } from "@app/features/point-exchanges/point-exchanges";
import ModalRegisterPhoto from "@app/features/shops/components/ModalRegisterPhoto/ModalRegisterPhoto";
import { ShopsPathsEnum } from "@app/features/shops/shops";
import { UserAddressesPathsEnum } from "@app/features/user-addresses/user-addresses";
import {
  convertMoney,
  convertPhoneNumber,
  getUserAddress,
} from "@app/helpers/util.helper";
import { emailRules } from "@app/helpers/validations.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import { getUserInfo, updateUser } from "../../auth";
import styles from "./MyPage.module.scss";

const MyPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { md } = useBreakpoint();
  const { Link } = Typography;

  const { currentUser, userInfo } = useAppSelector(
    (state: RootState) => state.auth
  );
  const [isEditUser, setIsEditUser] = useState({
    isEditName: false,
    isEditMail: false,
  });

  const [isOpenModalConfirmChangeEmail, setIsOpenModalConfirmChangeEmail] =
    useState(false);
  const [isOpenModalRegisterPhoto, setIsOpenModalRegisterPhoto] =
    useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  const userAddressDefault = useMemo(
    () => userInfo?.user_addresses?.find(item => item.is_default),
    [userInfo]
  );

  const handleUpdateEmail = () => {
    setIsSubmitting(true);
    dispatch(updateUser({ email }))
      .then(res => {
        if (res.payload?.errors) {
          openNotification({
            type: "warning",
            message: res.payload.errors,
          });
        }
        dispatch(getUserInfo()).then(() =>
          setIsEditUser({ ...isEditUser, isEditMail: false })
        );
      })
      .catch(err => {
        openNotification({
          type: "warning",
          message: err.errors,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsOpenModalConfirmChangeEmail(false);
      });
  };

  if (!userInfo) return <LoadingSpinner />;

  return (
    <div className={cx(styles.root)}>
      <ButtonBackPage className={cx(md ? "mt-5" : "mt-3")} />
      <div
        className={`${
          md ? "font-24 mt-8 mb-4" : "font-20 mt-4 mb-2"
        } font-weight-bold`}
      >
        マイページ
      </div>
      <div>
        <div className={cx(styles.pointBox)}>
          <div className="flex-align-center mb-1">
            <span className="font-16 mr-1 icon-point-icon">
              <span className="path1" />
              <span className="path2" />
            </span>
            <div className="font-14 font-weight-medium">所持ポイント</div>
          </div>
          <Row>
            <Col xs={24} sm={24} md={16}>
              <div className={cx(styles.point)}>
                {convertMoney(currentUser?.total_point ?? 0)} pt
              </div>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Button
                width="100%"
                withRightArrow
                danger
                type="primary"
                shape="round"
                className={`${md ? "" : "mt-2"}`}
                height={`${md ? "53px" : "38px"}`}
                onClick={() => handleDirectPointPage(history)}
              >
                ポイントを購入
              </Button>
            </Col>
          </Row>
        </div>
        <div className="mt-6">
          <div className="flex-align-center">
            <span
              className={`${md ? "font-20" : "font-15"} icon-person mr-2`}
            />
            <div className={`${md ? "font-20" : "font-14"} font-weight-medium`}>
              ユーザー名
            </div>
            <Button
              width="83px"
              height="22px"
              danger
              hidden={isEditUser.isEditName}
              onClick={() => setIsEditUser({ ...isEditUser, isEditName: true })}
              shape="round"
            >
              <div className="font-11">変更する</div>
            </Button>
          </div>

          <div className="mt-2">
            {isEditUser.isEditName ? (
              <Form
                onFinish={data => {
                  dispatch(updateUser(data)).then(() => {
                    dispatch(getUserInfo()).then(() =>
                      setIsEditUser({ ...isEditUser, isEditName: false })
                    );
                  });
                }}
              >
                <Row>
                  <Col xs={19} md={14}>
                    <Item
                      initialValue={userInfo?.username}
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "入力してください",
                        },
                      ]}
                    >
                      <Input maxLength={255} />
                    </Item>
                  </Col>
                  <Col xs={5} md={4}>
                    <Button
                      htmlType="submit"
                      noPadding
                      type="primary"
                      danger
                      width="60px"
                      height={`${md ? "38px" : "36px"}`}
                      className="ml-2"
                    >
                      確定
                    </Button>
                  </Col>
                </Row>
              </Form>
            ) : (
              <div
                className={`${
                  md ? "font-16 mt-2 pl-6" : "font-13 mt-1 pl-4"
                } white-space-pre`}
              >
                {userInfo?.username}
              </div>
            )}
          </div>
          <Divider />
        </div>
        <div className="mt-6">
          <div className="flex-align-center">
            <span
              className={`${
                md ? "font-20" : "font-15"
              } icon-location-icon mr-2`}
            />
            <div className={`${md ? "font-20" : "font-14"} font-weight-medium`}>
              送り先住所
            </div>
            <Button
              width="83px"
              height="22px"
              hidden={!userAddressDefault}
              onClick={() =>
                history.push(UserAddressesPathsEnum.LIST_USER_ADDRESSES)
              }
              danger
              shape="round"
            >
              <div className="font-11">変更する</div>
            </Button>
          </div>

          <div className={cx(styles.addressBox, `${md ? "pl-6" : "pl-4"}`)}>
            {userAddressDefault ? (
              <>
                <div className="font-16 font-weight-bold mt-4">
                  {`${userAddressDefault?.firstname ?? "-"}  ${
                    userAddressDefault?.lastname ?? ""
                  }`}
                </div>
                <div className={cx(styles.title)}>ご住所</div>
                <div>
                  {userAddressDefault?.zipcode &&
                    `〒${convertPhoneNumber(userAddressDefault.zipcode)}`}
                </div>
                <div>{getUserAddress(userAddressDefault)}</div>
                <div className={cx(styles.title, "mt-2")}>電話番号</div>
                <div>
                  {userAddressDefault?.tel &&
                    convertPhoneNumber(userAddressDefault.tel)}
                </div>
              </>
            ) : (
              <div className="mt-2 mb-7">
                <Link
                  href={UserAddressesPathsEnum.LIST_USER_ADDRESSES}
                  className="textLink font-11 font-weight-bold"
                >
                  発送先を登録してください。{" "}
                  <span className="icon-link font-11" />
                </Link>
              </div>
            )}
          </div>
          <Divider />
        </div>

        <div className="mt-6">
          <div className="flex-align-center">
            <span className={`${md ? "font-20" : "font-15"} icon-email mr-2`} />
            <div
              className={`${md ? "font-20" : "font-14"} font-weight-medium `}
            >
              メールアドレス
            </div>
            <Button
              width="83px"
              hidden={isEditUser.isEditMail}
              onClick={() => setIsEditUser({ ...isEditUser, isEditMail: true })}
              height="22px"
              danger
              shape="round"
            >
              <div className="font-11">変更する</div>
            </Button>
          </div>

          <div className="mt-2">
            {isEditUser.isEditMail ? (
              <Form
                onFinish={data => {
                  setEmail(data.email);
                  setIsOpenModalConfirmChangeEmail(true);
                }}
              >
                <Row>
                  <Col xs={19} md={14}>
                    <Item
                      name="email"
                      initialValue={userInfo?.email}
                      normalize={value => value.trim()}
                      rules={emailRules}
                    >
                      <Input maxLength={255} />
                    </Item>
                  </Col>
                  <Col xs={5} md={4}>
                    <Button
                      noPadding
                      htmlType="submit"
                      type="primary"
                      danger
                      width="60px"
                      height={`${md ? "38px" : "36px"}`}
                      className="ml-2"
                    >
                      確定
                    </Button>
                  </Col>
                </Row>
              </Form>
            ) : (
              <div
                className={`${md ? "font-16 mt-2 pl-6" : "font-13 mt-1 pl-4"}`}
              >
                {userInfo?.email}
              </div>
            )}
          </div>
          <Divider />
        </div>

        <div className="mt-6">
          <div className="flex-align-center">
            <img
              className={cx(styles.camera, "mr-2")}
              src={camera}
              alt="camera-icon"
            />
            <div
              className={cx(
                styles.contentWidth,
                "font-weight-medium",
                md ? "font-20" : "font-14"
              )}
            >
              証明写真
            </div>
            <Button
              width="83px"
              height="22px"
              onClick={() => setIsOpenModalRegisterPhoto(true)}
              danger
              shape="round"
            >
              <div className="font-11">
                {userInfo.image_url ? "変更する" : "登録する"}
              </div>
            </Button>
            {userInfo.image_url && (
              <Button
                width="83px"
                height="22px"
                onClick={() => {
                  setIsPreview(true);
                  setIsOpenModalRegisterPhoto(true);
                }}
                danger
                type="primary"
                shape="round"
                className="ml-3"
              >
                <div className="font-11">確　認</div>
              </Button>
            )}
          </div>

          <div className={cx(styles.addressBox, `${md ? "pl-6" : "pl-4"}`)}>
            {userInfo.image_url ? (
              <p className={cx(md ? "font-16" : "font-13")}>登録済み</p>
            ) : (
              <div className="mt-2 mb-7">
                <div
                  aria-hidden="true"
                  className={cx(
                    styles.btnLink,
                    "textLink font-11 font-weight-bold text-primary cursor-pointer"
                  )}
                  onClick={() => {
                    setIsOpenModalRegisterPhoto(true);
                  }}
                >
                  証明写真を登録してください。
                  <span className="icon-link font-11" />
                </div>
              </div>
            )}
            <div className="text-center">
              <Button
                shape="round"
                className={cx(styles.btnSubmit, "yellow")}
                width="100%"
                onClick={() => history.push(ShopsPathsEnum.SHOP_LIST_USER)}
              >
                獲得カード所持一覧へ
              </Button>
            </div>
          </div>
          <Divider />
        </div>
        <ConfirmDialog
          width={md ? 450 : 333}
          okText="閉じる"
          isHideButtonCancel
          isDisableBtnSubmit={isSubmitting}
          open={isOpenModalConfirmChangeEmail}
          handleCancel={() => setIsOpenModalConfirmChangeEmail(false)}
          handleSubmit={handleUpdateEmail}
        >
          <div
            className={cx(
              md ? "font-14" : "font-10",
              "text-center font-weight-bold mt-10"
            )}
          >
            メールアドレスを変更しました。
            <br />
            以前登録いただいていたメールアドレス宛に
            <br />
            案内メールを送信しました。
            <br />
            案内メールに記載されているリンクから
            <br />
            本登録を完了してください。
          </div>
        </ConfirmDialog>
        <ModalRegisterPhoto
          userInfo={userInfo}
          isDisableBtnSubmit={isSubmitting}
          open={isOpenModalRegisterPhoto}
          isPreview={isPreview}
          handleCancel={() => {
            setIsOpenModalRegisterPhoto(false);
            setIsPreview(false);
          }}
        />
      </div>
    </div>
  );
};

export default MyPage;
