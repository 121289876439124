import { memo } from "react";

import Helmet from "@app/components/molecules/Helmet/Helmet";
import { PAGE_INFO } from "@app/constants/constants";

import BankTransfer from "../../Components/BankTransfer/BankTransfer";

const BankTransferNotifyScreen = () => {
  return (
    <>
      <Helmet
        title={PAGE_INFO["UA-0027"].title}
        description={PAGE_INFO["UA-0027"].description}
      />
      <BankTransfer
        title="案内を送信しました。"
        content={`この度はご利用いただきありがとうございました。\nご登録のメールアドレスに詳細をお送りいたしました。\nメールに記載のURLよりお手続きをお願いいたします。`}
      />
    </>
  );
};

export default memo(BankTransferNotifyScreen);
