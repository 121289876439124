import { memo, useMemo } from "react";

import { Progress } from "antd";

import { COLOR_STATUS_PACK } from "@app/features/shops/constants/shops.constants";
import { convertMoney } from "@app/helpers/util.helper";

import { Pack } from "../../../features/shops/shops";
import styles from "./ProgressPack.module.scss";

interface ProgressProps {
  pack: Pack;
}

const ProgressPack = ({ pack }: ProgressProps) => {
  const packAvailablePercent = useMemo(
    () => (pack.number_card_remain / pack.total_card) * 100,
    [pack]
  );

  const strokeColor = useMemo(() => {
    const packSoldPercent = 100 - packAvailablePercent;
    if (packSoldPercent >= 70) {
      return COLOR_STATUS_PACK.RED;
    }
    if (packSoldPercent >= 40 && packSoldPercent < 70) {
      return COLOR_STATUS_PACK.YELLOW;
    }
    if (packSoldPercent < 40) {
      return COLOR_STATUS_PACK.GREEN;
    }
    return "";
  }, [packAvailablePercent]);

  return (
    <div className={styles.packPercent}>
      <div className="flex-space-between-center font-14 font-weight-medium">
        <span>残り</span>
        <span className="text-end">
          {convertMoney(pack.number_card_remain)}/
          {convertMoney(pack.total_card)}
        </span>
      </div>
      <Progress
        percent={packAvailablePercent}
        strokeColor={strokeColor}
        showInfo={false}
      />
    </div>
  );
};

export default memo(ProgressPack);
