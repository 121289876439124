import { createContext, useMemo, ReactNode } from "react";

import { ParamsListPacks } from "@app/features/shops/shops";
import { setInitialValues } from "@app/helpers/util.helper";
import useQueryState from "@app/hook/useQueryState";

interface FilterContextInterface {
  queryFilter: ParamsListPacks;
  setQueryFilter: (newQueries: ParamsListPacks) => void;
  initialValues: ParamsListPacks;
}

export const FilterContext = createContext<FilterContextInterface>({
  queryFilter: {},
  initialValues: {},
  setQueryFilter: () => {
    return null;
  },
});

const FilterContextProvider = ({ children }: { children: ReactNode }) => {
  const [queryFilter, setQueryFilter] = useQueryState<ParamsListPacks>({});

  const initialValues = useMemo(() => {
    return setInitialValues({
      started_sale: queryFilter?.started_sale,
      status: queryFilter?.status,
      number_point_one_roll_min: queryFilter?.number_point_one_roll_min,
      number_point_one_roll_max: queryFilter?.number_point_one_roll_max,
      keyword: queryFilter?.keyword,
      label_ids: queryFilter?.label_ids,
    });
  }, [
    queryFilter?.started_sale,
    queryFilter?.status,
    queryFilter?.number_point_one_roll_min,
    queryFilter?.number_point_one_roll_max,
    queryFilter?.keyword,
    queryFilter?.label_ids,
  ]);

  return (
    <FilterContext.Provider
      value={{
        initialValues,
        queryFilter,
        setQueryFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterContextProvider;
