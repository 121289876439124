import { memo, useState, useMemo } from "react";

import cx from "classnames";

import ZoomDialog from "@app/features/shops/components/ZoomDialog/ZoomDialog";
import { Card } from "@app/features/shops/shops";
import {
  getNameCard,
  isCardAlmostExpired,
  isExistCardImage,
} from "@app/helpers/util.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppSelector } from "@app/redux/store";

import { CARD_STATUS } from "../../gacha-histories";
import GachaCardImage from "../GachaCardImage/GachaCardImage";
import styles from "./GachaCard.module.scss";

interface GachaCardProps {
  card: Card;
  handleChecked?: () => void;
  isNotShowIconCheck?: boolean;
  isGachaCardInOrderDialog?: boolean;
  classNameCard?: string;
  classNameCardIcon?: string;
}

const GachaCard = ({
  card,
  handleChecked,
  isNotShowIconCheck,
  isGachaCardInOrderDialog = false,
  classNameCard,
  classNameCardIcon,
}: GachaCardProps) => {
  const { gachaHistoriesChecked } = useAppSelector(
    (state: RootState) => state.gacha_histories
  );

  const [isZoom, setIsZoom] = useState<boolean>(false);

  const isChecked = isNotShowIconCheck
    ? false
    : gachaHistoriesChecked.some(item => item.id === card.id);

  const isCardSoldAlmostExpired = useMemo(() => {
    return (
      card.status === CARD_STATUS.SOLD && isCardAlmostExpired(card.gacha_at)
    );
  }, [card.gacha_at, card.status]);

  return (
    <>
      <div
        className={cx(styles.box, {
          [styles.isChecked]: isChecked && !isGachaCardInOrderDialog,
        })}
      >
        <div className={cx(styles.imgCard)}>
          <GachaCardImage
            imageCard={card.image_url}
            type={isCardSoldAlmostExpired ? "almost_expired" : card.type}
            className={cx(styles.card, classNameCard)}
          />
          {!isNotShowIconCheck && (
            <div
              aria-hidden
              className={classNameCardIcon ?? styles.checkbox}
              onClick={handleChecked}
            >
              <span className="icon-check-icon" />
            </div>
          )}
          {isExistCardImage(card.card_images, card.type) &&
            !isGachaCardInOrderDialog && (
              <div
                aria-hidden
                className={styles.zoomCard}
                onClick={() => setIsZoom(true)}
              >
                <span className="icon-search-icon" />
              </div>
            )}
        </div>
        {!isGachaCardInOrderDialog && (
          <div className={styles.cardInfo}>
            {isCardSoldAlmostExpired && (
              <p className={cx("font-10 font-weight-bold", styles.warning)}>
                期限を迎えます。3日以内にPOINT変換され
                ますのでご確認をお願いします。
              </p>
            )}
            <div className={styles.cardName}>{getNameCard(card)}</div>
            <p className={styles.cardType}>{card.rare?.name ?? ""}</p>
            <p
              className={styles.cardPoint}
            >{`${card.point.toLocaleString()}Pt`}</p>
          </div>
        )}
      </div>
      {isZoom && (
        <ZoomDialog
          isOpenDialog={isZoom}
          card={card}
          handleCancel={() => setIsZoom(false)}
        />
      )}
    </>
  );
};

export default memo(GachaCard);
