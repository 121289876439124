import { memo, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Input } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { useHistory } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import ButtonBackPage from "@app/components/atoms/ButtonBackPage/ButtonBackPage";
import Form, { Item } from "@app/components/atoms/Form/Form";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { ERROR_MESSAGES } from "@app/constants/message.constants";
import { emailRules } from "@app/helpers/validations.helper";
import { useAppDispatch } from "@app/redux/store";

import {
  AuthUserDataDef,
  temporaryRegister,
  MESSAGE_ERROR,
  AuthPathsEnum,
  LOCATION_STATE_SUCCESS,
} from "../../auth";
import TemplateAuth from "../../components/TemplateAuth/TemplateAuth";
import styles from "./TemporaryRegisterScreen.module.scss";

const TemporaryRegisterScreen = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { md } = useBreakpoint();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = (data: AuthUserDataDef) => {
    setIsSubmitting(true);
    dispatch(temporaryRegister(data))
      .then(unwrapResult)
      .then(() => {
        history.push(
          AuthPathsEnum.TEMPORAPY_REGISTER_SUCCESS,
          LOCATION_STATE_SUCCESS
        );
      })
      .catch(err => {
        const errMessage =
          err.statusCode === 500 ? ERROR_MESSAGES.UA_00 : MESSAGE_ERROR;
        openNotification({ type: "warning", message: errMessage });
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div>
      <ButtonBackPage className={cx(md ? "mt-9" : "mt-4")} />
      <TemplateAuth title="仮登録">
        <div className={styles.box}>
          <Form onFinish={handleSubmit}>
            <div className="font-10 mb-8 text-center">
              <div>仮登録を行います。</div>
              <div>
                お客様のメールアドレスを入力後、「送信」ボタンを押してください。
              </div>
              <div>お客様へ本登録URLを送信いたします。</div>
            </div>
            <div className="font-12 mb-1">メールアドレス</div>
            <Item name="email" rules={emailRules}>
              <Input placeholder="メールアドレスを入力" />
            </Item>
            <div className={styles.btnBox}>
              <Button
                danger
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
              >
                送信
              </Button>
            </div>
          </Form>
        </div>
      </TemplateAuth>
    </div>
  );
};

export default memo(TemporaryRegisterScreen);
