export enum ShopsEndpointsEnum {
  SHOP_LIST = "/shops",
  SHOP_DETAIL = "/shops/:id",
  SHOP_PACKS = "/packs",
  BANNER_LIST = "/banners",
  PACK_DETAIL = "/shops/:shopId/packs/:packId",
  CARD_LIST = "/shops/:id/packs/:packId/cards",
  SHOP_LIST_USER = "/users/shops",
  GET_CARD_LIST_USER = "/users/shops/:shopId/cards",
  CARD_TO_DELIVERY = "/delivery-histories",
  LABEL_LIST = "/labels",
  POST_FEEDBACK = "/feedback",
  USER_VISIT_PACK = "/user-visit-pack",
}
