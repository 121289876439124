import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { PrefecturesEndpointsEnum } from "../constants/prefectures.endpoints";

const prefecturesListApi = (): Promise<AxiosResponse> => {
  const url = PrefecturesEndpointsEnum.PREFECTURES_LIST;
  return api.get(url);
};

const shopsApi = {
  prefecturesListApi,
};

export default shopsApi;
