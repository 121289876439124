import { memo, useEffect, useState, useCallback, useContext } from "react";

import { Carousel, Tabs } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Link from "antd/lib/typography/Link";
import cx from "classnames";
import { debounce } from "lodash";

import { cancelToken } from "@app/api/api";
import Image from "@app/components/atoms/Image/Image";
import TabFilter from "@app/components/molecules/TabFilter/TabFilter";
import { FilterContext } from "@app/contexts/FilterContext";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { ParamsDef } from "@app/types/route.types";

import NavigatorMobile from "../../components/NavigatorMobile/NavigatorMobile";
import ShopPack from "../../components/ShopPacks/ShopPacks";
import { BANNERS_LIMIT } from "../../constants/shops.constants";
import { getLabelList } from "../../redux/shops.slice";
import { getBannerList, CATEGORY_TYPE } from "../../shops";
import styles from "./ShopListScreen.module.scss";

const ShopListScreen = () => {
  const dispatch = useAppDispatch();
  const { xs, md, xl, lg } = useBreakpoint();

  const [isFirstLoadingTop, setIsFirstLoadingTop] = useState(true);

  const [pageNumber, setPageNumber] = useState<number>(1);

  const [centerPadding, setCenterPadding] = useState(0);

  const banners = useAppSelector((state: RootState) => state.shops.bannerList);
  const labels = useAppSelector(state => state.shops.labelList?.labels);

  const { initialValues, setQueryFilter } = useContext(FilterContext);

  const handleWindowResize = useCallback(() => {
    const maxBannerPc = 1142;
    const actualInnerWidth = document.body.clientWidth;
    const widthBannerSp = actualInnerWidth * 0.91;

    setCenterPadding(
      xl
        ? (actualInnerWidth - maxBannerPc) / 2
        : (actualInnerWidth - widthBannerSp) / 2
    );
  }, [xl]);

  useEffect(() => {
    const bannerParams: ParamsDef = {
      limit: BANNERS_LIMIT,
      use_page: true,
    };
    Promise.all([
      dispatch(getBannerList(bannerParams)),
      dispatch(getLabelList({ use_page: false })),
    ]).then(() => setIsFirstLoadingTop(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    handleWindowResize();

    const handleSetOffsetSlides = debounce(() => {
      handleWindowResize();
    }, 100);

    window.addEventListener("resize", handleSetOffsetSlides);

    return () => {
      window.removeEventListener("resize", handleSetOffsetSlides);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    return () => {
      setQueryFilter({});
    };
  }, [dispatch, setQueryFilter]);

  return (
    <div className={styles.root}>
      {!!labels?.length && (
        <div className={cx(styles.tabs)}>
          <div className={cx(lg && (styles.container, "px-4"))}>
            <Tabs
              onChange={activeKey => {
                if (cancelToken.source && !isFirstLoadingTop) {
                  cancelToken.source.cancel();
                }
                setQueryFilter({
                  label_ids: Number(activeKey) ? [activeKey] : undefined,
                });
              }}
              activeKey={initialValues.label_ids?.[0] ?? CATEGORY_TYPE.ALL}
              moreIcon={null}
            >
              <Tabs.TabPane tab="すべて" key={CATEGORY_TYPE.ALL} />
              {labels?.map(item => (
                <Tabs.TabPane tab={item.name} key={item.id} />
              ))}
            </Tabs>
          </div>
        </div>
      )}

      {banners && banners.banners.length === 1 ? (
        <div className={styles.slider}>
          <Image
            key={Number(md)}
            src={
              md
                ? banners.banners[0]?.pc_image_url
                : banners.banners[0]?.sp_image_url
            }
            className={styles.banner}
          />
        </div>
      ) : (
        <Carousel
          key={banners?.banners.length}
          autoplay
          dots={{ className: "dotsBanner" }}
          draggable
          swipeToSlide
          centerMode
          centerPadding={`${centerPadding}px`}
          className={styles.slider}
        >
          {banners?.banners.map(item => (
            <Link href={item.href_url} key={item.id} target="_blank">
              <Image
                key={Number(md)}
                src={md ? item.pc_image_url : item.sp_image_url}
                className={styles.banner}
              />
            </Link>
          ))}
        </Carousel>
      )}

      <div className={styles.wrapper}>
        <div className={styles.container}>
          {lg && (
            <div className={styles.tabFilter}>
              <TabFilter
                isFirstLoadingTop={isFirstLoadingTop}
                labelList={labels}
                initParams={initialValues}
                setInitParams={setQueryFilter}
              />
            </div>
          )}
          <ShopPack
            isFirstLoadingTop={isFirstLoadingTop}
            setQueryShopPacks={setQueryFilter}
            queryShopPacks={initialValues}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
          {xs && <NavigatorMobile />}
        </div>
      </div>
    </div>
  );
};

export default memo(ShopListScreen);
