/* eslint-disable no-irregular-whitespace */
import { memo } from "react";

import { Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { useHistory, useLocation } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import Helmet from "@app/components/molecules/Helmet/Helmet";
import { PAGE_INFO } from "@app/constants/constants";

import { ShopsPathsEnum } from "../../shops";
import styles from "./ContactSuccessScreen.module.scss";

const ContactSuccessScreen = () => {
  const md = useBreakpoint();
  const history = useHistory();

  const { state } = useLocation();

  if (!state) {
    history.push(ShopsPathsEnum.CONTACT);
    return null;
  }

  return (
    <div className={cx(styles.root)}>
      <Helmet
        title={PAGE_INFO["UA-0031"].title}
        description={PAGE_INFO["UA-0031"].description}
      />
      <div className={cx(styles.container)}>
        <div className="container">
          <Typography className={cx(`${styles.title} pt-9 text-center`)}>
            お問い合わせありがとうございました。
          </Typography>
        </div>
        <div>
          <p className="pre-wrap text-center font-12 px-5 font-weight-medium mt-7">
            {
              "営業時間：11:00~18:00\n土日祝日以外の営業日でのご返信をさせていただきます。\n返信まで１〜3営業日いただく事がありますので予めご了承ください。"
            }
          </p>
        </div>
        <div className="d-flex flex-justify-center mt-8 text-black">
          <Button
            height={md ? "50px" : "38px"}
            className={cx(styles.btn, styles.bgLight)}
            type="primary"
            danger
            withLeftArrow
            shape="round"
            onClick={() =>
              history.push({
                pathname: ShopsPathsEnum.CONTACT,
              })
            }
          >
            お問い合わせへ戻る
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(ContactSuccessScreen);
