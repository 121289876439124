import { memo } from "react";

import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { useHistory } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";

import styles from "./ButtonBackPage.module.scss";

const ButtonBackPage = ({
  className,
  path,
}: {
  className?: string;
  path?: string;
}) => {
  const { md } = useBreakpoint();
  const history = useHistory();
  return (
    <div className={cx(styles.btnRoot)}>
      <Button
        shape="round"
        width={md ? "79px" : "64px"}
        className={cx("gray btn-small", className)}
        withLeftArrow
        onClick={() => {
          if (!path) {
            history.goBack();
          } else {
            history.push(path);
          }
        }}
      >
        <div className="ml-2">戻る</div>
      </Button>
    </div>
  );
};

export default memo(ButtonBackPage);
