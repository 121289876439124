import { memo, useEffect, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Input } from "antd";
import { useHistory, useParams } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item } from "@app/components/atoms/Form/Form";
import Helmet from "@app/components/molecules/Helmet/Helmet";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { PAGE_INFO } from "@app/constants/constants";
import { passwordRules } from "@app/helpers/validations.helper";
import { useAppDispatch } from "@app/redux/store";

import {
  ResetPasswordDataDef,
  resetPassword,
  checkTokenResetPassword,
  AuthPathsEnum,
  TOKEN_STATUS,
} from "../../auth";
import TemplateAuth from "../../components/TemplateAuth/TemplateAuth";
import styles from "./ResetPasswordScreen.module.scss";

const ResetPasswordScreen = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { token } = useParams<{ token: string }>();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);

  const handleSubmit = (data: ResetPasswordDataDef) => {
    setIsSubmitting(true);
    dispatch(resetPassword({ token, ...data }))
      .then(unwrapResult)
      .then(() => {
        setIsSuccess(true);
      })
      .catch(err => {
        if (err.statusCode === 500) {
          openNotification({ type: "warning", message: err.error.errors });
        } else {
          history.push(AuthPathsEnum.TOKEN_EXPIRATION);
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  useEffect(() => {
    dispatch(checkTokenResetPassword(token))
      .then(unwrapResult)
      .then(res => {
        if (res.auth === TOKEN_STATUS.VALID) {
          setIsTokenValid(true);
        } else {
          history.push(AuthPathsEnum.TOKEN_EXPIRATION);
        }
      })
      .catch(() => {
        history.push(AuthPathsEnum.TOKEN_EXPIRATION);
      });
  }, [token, history, dispatch]);

  return (
    <>
      {isTokenValid && (
        <TemplateAuth title="パスワード再設定" className={styles.container}>
          {isSuccess ? (
            <div className={styles.successBox}>
              <Helmet title={PAGE_INFO["UA-0025"].title} />
              <div>
                {"登録が完了しました。\nログインからやり直してください。"}
              </div>
              <Button
                type="primary"
                danger
                className={styles.btn}
                withRightArrow
                to={AuthPathsEnum.LOGIN}
              >
                ログイン
              </Button>
            </div>
          ) : (
            <div className={styles.box}>
              <div className="mb-14 font-12 text-center text-explain">
                ご入力頂いたパスワードを登録いたします。
              </div>
              <Form onFinish={handleSubmit}>
                <div className="font-12 mb-1">新しいパスワード</div>
                <Item name="password" rules={passwordRules}>
                  <Input.Password
                    maxLength={50}
                    placeholder="パスワードを入力"
                  />
                </Item>
                <div className="font-12 mb-1 mt-5">新しいパスワード 再入力</div>
                <Item
                  name="password_confirmation"
                  dependencies={["password"]}
                  rules={[
                    ...passwordRules,
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "最初に入力したパスワードと同じ値を入力してください。"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    maxLength={50}
                    placeholder="パスワードを入力"
                  />
                </Item>

                <div className={styles.btnBox}>
                  <Button
                    danger
                    type="primary"
                    htmlType="submit"
                    disabled={isSubmitting}
                  >
                    設定
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </TemplateAuth>
      )}
    </>
  );
};

export default memo(ResetPasswordScreen);
