import { Typography } from "antd";

import Logo from "@app/assets/images/logo_white.png";
import { AuthPathsEnum } from "@app/features/auth/auth";
import { ShopsPathsEnum } from "@app/features/shops/constants/shops.paths";
import { StaticPagePathsEnum } from "@app/features/static-page/static-page";
import { RootState } from "@app/redux/root-reducer";
import { useAppSelector } from "@app/redux/store";

import styles from "./Footer.module.scss";

const Footer = () => {
  const { currentUser } = useAppSelector((state: RootState) => state.auth);
  const { Link } = Typography;
  return (
    <div className={styles.root}>
      <div className="container">
        <div className={styles.footer}>
          {currentUser && <Link href={AuthPathsEnum.MY_PAGE}>マイページ</Link>}
          <Link href={StaticPagePathsEnum.TERMS_OF_SERVICE}>利用規約</Link>
          {/* <Link href={StaticPagePathsEnum.USAGE_GUIDE}>利用ガイド</Link> */}
          <Link href={StaticPagePathsEnum.PRIVACY_POLICY}>
            プライバシーポリシー
          </Link>
          <Link href={StaticPagePathsEnum.COMMERCIAL_LAW}>
            特定商取引法に基づく表記
          </Link>
          <Link href={ShopsPathsEnum.CONTACT}>お問い合わせ</Link>
          <Link target="_blank" href={ShopsPathsEnum.INFO_PAGE}>
            企業情報
          </Link>
        </div>
        <img src={Logo} width={150} className={styles.logoFooter} alt="logo" />
        <p className="text-white font-10 font-weight-bold">
          Copyright© Torecaball All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
