export const STATUS_ON_SALE = 1;
export const STATUS_SOLD_OUT = 2;
export const STATUS_ALL = 3;
export const STATUS_WAITING_SALE = 4;

export enum PACK_START_SALE {
  PACK_SOLD = "1",
  PACK_NOT_YET_SOLD = "0",
}

export enum PACK_STATUS {
  ON_SALE = 1,
  SOLD_OUT = 2,
  ALL = 3,
  WAITING_SALE = 4,
}

export enum PACK_STATUS_NAME_ENUM {
  ON_SALE = "開催中",
  SOLD_OUT = "終了",
  WAITING_SALE = "開催前",
  ALL = "すべて",
}

export enum CATEGORY_TYPE {
  ALL = "0",
  POKEMON = "1",
  ONE_PIECE = "2",
}

export const CATEGORIES_LIST_TYPE: Record<number, string | null> = {
  [CATEGORY_TYPE.ALL]: null,
  [CATEGORY_TYPE.POKEMON]: "pokemon",
  [CATEGORY_TYPE.ONE_PIECE]: "one_piece",
};

export const PACKS_LIST_STATUS: Record<number, string | undefined> = {
  [STATUS_ON_SALE]: "on_sale",
  [STATUS_SOLD_OUT]: "sold_out",
  [STATUS_ALL]: undefined,
  [STATUS_WAITING_SALE]: "waiting_sale",
};

export const CARD_TYPE = {
  LAST_ONE: "last_one",
  BIG_HIT: "big_hit",
  MEDIUM_HIT: "medium_hit",
  SMALL_HIT: "small_hit",
  FAIL: "fail",
};

export const DELAY_AUTO_PLAY = 5000;

export const TIME_INTERVAL = 10000;

export const BANNERS_LIMIT = 10;

export const SHOP_PACK_LIMIT = 10;

export const SHOP_LIST_USER_LIMIT = 20;

export const MAX_CARD_SMALL_FAIL = 20;

export const MAX_CARD_MEDIUM_HIT_PC = 24;

export const MAX_CARD_MEDIUM_HIT_SP = 16;

export const MAX_CARD_BIG_HIT = 24;

export const TIME_AUTO_EXCHANGE_CARD = 14;
export const TIME_SHOW_ALERT_EXCHANGE_CARD = 3;

export enum COLOR_STATUS_PACK {
  GREEN = "#2aaea1",
  YELLOW = "#ffc14a",
  RED = "#cf4842",
}

export const SNS_LINK = {
  LINE: "https://lin.ee/xiR5kZfi",
  X: "https://x.com/torecaball_web?s=21&t=NtQcLZuhMkBLrjQanORRMA",
};
