import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";

import api from "../api/prefectures.api";
import { PREFECTURES_FEATURE_KEY } from "../constants/prefectures.keys";
import { PrefecturesList } from "../prefectures";

export type PrefecturesState = {
  prefecturesList: PrefecturesList | null;
};

const initialState: PrefecturesState = {
  prefecturesList: null,
};

export const getListPrefectures = createAsyncThunk(
  `${PREFECTURES_FEATURE_KEY}/getListPrefectures`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.prefecturesListApi();
      return response.data.prefectures;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const prefecturesSlice = createSlice({
  name: PREFECTURES_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getListPrefectures.pending, state => {
      state.prefecturesList = null;
    });
    builder.addCase(getListPrefectures.fulfilled, (state, action) => {
      state.prefecturesList = action.payload;
    });
    builder.addCase(getListPrefectures.rejected, state => {
      state.prefecturesList = [];
    });
  },
});

export const prefecturesReducer = prefecturesSlice.reducer;
