import { memo } from "react";

import cx from "classnames";

import { convertMoney } from "@app/helpers/util.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppSelector } from "@app/redux/store";

import styles from "./BtnGacha.module.scss";

interface BtnGachaProps {
  className?: string;
  numberGachaRoll: number | string;
  numberPointRoll: number;
  handleClick: (numberGacha: number | string, numberPoint: number) => void;
  isDisable?: boolean;
}

const BtnGacha = ({
  numberPointRoll,
  numberGachaRoll,
  className,
  handleClick,
  isDisable,
}: BtnGachaProps) => {
  const {
    auth: { isMaintenanceGacha },
  } = useAppSelector((state: RootState) => state);

  return (
    <div
      aria-hidden="true"
      className={cx(styles.btnGacha, className, {
        [styles.btnGacha1]: numberGachaRoll === 1,
        [styles.btnGacha10]: numberGachaRoll === 10,
        [styles.btnGachaAll]: numberGachaRoll === "all",
        [styles.isDisable]: isMaintenanceGacha || isDisable,
      })}
      onClick={() => {
        if (!isDisable && !isMaintenanceGacha) {
          handleClick(numberGachaRoll, numberPointRoll);
        }
      }}
    >
      <div className="flex-center">
        <span className={styles.btnPointText}>
          {numberGachaRoll === 1 && "1回引く"}
          {numberGachaRoll === 10 && "10回引く"}
          {numberGachaRoll === "all" && "すべて引く"}
        </span>
        <div className="pl-3">
          <div className={cx(styles.btnPoint, "mx-auto")}>
            <span className="font-12 mr-2 icon-point-icon">
              <span className="path1" />
              <span className="path2" />
            </span>
            {convertMoney(numberPointRoll)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BtnGacha);
