import { memo } from "react";

import Link from "antd/lib/typography/Link";
import cx from "classnames";
import { useLocation } from "react-router-dom";

import HomeIcon from "@app/assets/images/home_icon.svg";
import PackIcon from "@app/assets/images/pack_icon.svg";
import userIcon from "@app/assets/images/user_icon.svg";
import Image from "@app/components/atoms/Image/Image";
import { AuthPathsEnum } from "@app/features/auth/auth";
import { useAppSelector } from "@app/redux/store";

import { ShopsPathsEnum } from "../../shops";
import styles from "./NavigatorMobile.module.scss";

const Items = [
  { icon: HomeIcon, title: "トップ", href: ShopsPathsEnum.SHOP_LIST },
  {
    icon: PackIcon,
    title: "獲得所持\nカード一覧",
    href: ShopsPathsEnum.SHOP_LIST_USER,
  },
  {
    icon: userIcon,
    title: "マイページ",
    href: AuthPathsEnum.MY_PAGE,
  },
];

const NavigatorMobile = () => {
  const location = useLocation();
  const isLogin = useAppSelector(state => state.auth.accessToken);

  const isActiveMenuItem = (pathName: string) => {
    if (pathName !== ShopsPathsEnum.SHOP_LIST) {
      return !!pathName.length && location.pathname.startsWith(pathName);
    }
    return pathName === location.pathname;
  };

  if (!isLogin) {
    return null;
  }

  return (
    <div className={cx(styles.tabMobile, "d-flex")}>
      {Items.map(item => (
        <Link
          key={item.title}
          href={item.href}
          className={cx(
            isActiveMenuItem(item.href) && styles.active,
            styles.tab,
            "flex-direction-column text-center flex-1 font-10 font-weight-bold"
          )}
        >
          <Image src={item.icon} />
          <span
            className={cx(
              isActiveMenuItem(item.href) && styles.active,
              "white-space-pre mt-2"
            )}
          >
            {item.title}
          </span>
        </Link>
      ))}
    </div>
  );
};

export default memo(NavigatorMobile);
