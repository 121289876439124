import React, { memo, useState, useEffect, FC, useRef } from "react";

import cx from "classnames";

import styles from "./Image.module.scss";

interface ImageProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  defaultImg?: string;
  className?: string;
}

const Image: FC<ImageProps> = ({ src, defaultImg, className, ...rest }) => {
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const observer = new window.IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setUrl(src);
        const imgDefaultLoader = new window.Image();
        imgDefaultLoader.src = src ?? "";
        imgDefaultLoader.onload = () => {
          setIsLoading(false);
          if (imageRef.current) {
            imageRef.current.src = imgDefaultLoader.src;
          }
        };
        if (ref?.current) {
          observer.unobserve(ref.current);
        }
      }
    });
    if (ref?.current) {
      observer.observe(ref.current);
    }
  }, [src]);

  const handleError = () => {
    setUrl(defaultImg);
    setIsLoading(false);
  };

  const handleLoaded = () => {
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <div ref={ref} className={cx(className, styles.imageLoading)} />
      )}
      <img
        {...rest}
        ref={imageRef}
        src={url}
        alt={rest.alt}
        loading="lazy"
        className={cx(className, styles.image, {
          [styles.hidden]: isLoading,
        })}
        onError={handleError}
        onLoad={handleLoaded}
      />
    </>
  );
};

export default memo(Image);
