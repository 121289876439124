import { memo, ReactNode } from "react";

import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";

import Logo from "@app/assets/images/logo.png";
import Footer from "@app/components/molecules/Footer/Footer";

import styles from "./MaintenanceLayout.module.scss";

type MaintenanceLayoutProps = {
  children: ReactNode;
};

const MaintenanceLayout = ({ children }: MaintenanceLayoutProps) => {
  const { md } = useBreakpoint();

  return (
    <>
      <div className={cx(styles.header)}>
        <div className="container">
          <img src={Logo} width={md ? 138 : 99} alt="logo" />
        </div>
      </div>
      <div className={cx(styles.container)}>
        <div className={cx(styles.main)}>{children}</div>
      </div>
      <Footer />
    </>
  );
};

export default memo(MaintenanceLayout);
