import { memo } from "react";

import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Typography from "antd/lib/typography/Typography";
import cx from "classnames";
import { Link } from "react-router-dom";

import Logo from "@app/assets/images/logo.png";
import Button from "@app/components/atoms/Button/Button";

import { PointExchangesPathsEnum } from "../../point-exchanges";
import styles from "./BankTransferExpirationScreen.module.scss";

const BankTransferExpirationScreen = () => {
  const { md } = useBreakpoint();
  return (
    <>
      <div className="text-center mt-15">
        <Link to="/">
          <img alt="" className={cx(styles.logo)} src={Logo} />
        </Link>
      </div>
      <div
        className={cx(
          "text-center mt-15 font-weight-medium",
          md ? "font-13" : "font-12"
        )}
      >
        <Typography>URLの有効期限が切れました。</Typography>
        <Typography>ログイン画面からやり直してください。</Typography>
      </div>

      <div className="text-center mt-10">
        <Button
          type="primary"
          danger
          shape="round"
          withRightArrow
          to={PointExchangesPathsEnum.POINT_EXCHANGES}
        >
          ポイントを購入
        </Button>
      </div>
    </>
  );
};

export default memo(BankTransferExpirationScreen);
