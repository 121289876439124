import { memo, useState } from "react";

import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { compile } from "path-to-regexp";
import { useHistory } from "react-router-dom";

import ConfirmDialog from "@app/components/molecules/ConfirmDialog/ConfirmDialog";
import { MODE_ACTIONS } from "@app/constants/constants";
import { UserAddress } from "@app/features/auth/types/auth.types";
import { convertPhoneNumber, getUserAddress } from "@app/helpers/util.helper";
import { useAppDispatch } from "@app/redux/store";

import {
  deleteUserAddress,
  updateUserAddress,
  UserAddressesPathsEnum,
} from "../../user-addresses";
import styles from "./CardAddress.module.scss";

interface CardAddressProps {
  userAddress?: UserAddress;
  hasMoreAction?: boolean;
  isCardInDeleteDialog?: boolean;
  isCardInOrderDialog?: boolean;
  handleReload?: () => void;
}

const CardAddress = ({
  userAddress,
  hasMoreAction = true,
  isCardInDeleteDialog = false,
  isCardInOrderDialog = false,
  handleReload,
}: CardAddressProps) => {
  const [isOpenConfirmDeleteDialog, setIsOpenConfirmDeleteDialog] =
    useState<boolean>(false);
  const { md } = useBreakpoint();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleChangeUserAddressCardDefault = () => {
    if (!userAddress || userAddress.is_default) return;
    dispatch(
      updateUserAddress({
        userAddressId: String(userAddress.id),
        data: { ...userAddress, is_default: true },
      })
    ).then(() => handleReload?.());
  };

  const handleDeleteUserAddressCard = () => {
    if (!userAddress) return;
    dispatch(deleteUserAddress(String(userAddress.id))).then(() => {
      setIsOpenConfirmDeleteDialog(false);
      handleReload?.();
    });
  };

  return (
    <>
      <div
        className={cx(styles.cardAddressBox, {
          [styles.cardSelected]:
            userAddress?.is_default && !isCardInDeleteDialog,
          [styles.cardInDeleteDialog]: isCardInDeleteDialog,
          [styles.cardInOrderDialog]: isCardInOrderDialog,
        })}
      >
        {hasMoreAction && (
          <>
            <span
              className={
                userAddress?.is_default
                  ? "icon-checked-icon"
                  : "icon-check-icon"
              }
              onClick={handleChangeUserAddressCardDefault}
              aria-hidden
            />
            <div className={cx(styles.iconHeader)}>
              <span
                className="icon-edit-icon"
                aria-hidden
                onClick={() =>
                  history.push(
                    compile(UserAddressesPathsEnum.CREATE_USER_ADDRESSES)({
                      mode: MODE_ACTIONS.EDIT,
                      id: userAddress?.id,
                    })
                  )
                }
              />
              <span
                className="icon-trash-icon"
                onClick={() => setIsOpenConfirmDeleteDialog(true)}
                aria-hidden
              />
            </div>
          </>
        )}
        <div
          className={cx(
            styles.cardBody,
            !hasMoreAction && styles.hasOnlyContentPadding
          )}
        >
          <div className={cx(styles.cardTitle)}>
            {`${userAddress?.firstname ?? "-"}  ${userAddress?.lastname ?? ""}`}
          </div>
          <div className="mb-4">
            <div className={cx(styles.titleWithDots)}>ご住所</div>
            <div>
              {userAddress?.zipcode &&
                `〒${convertPhoneNumber(userAddress?.zipcode)}`}
            </div>
            {getUserAddress(userAddress)}
          </div>
          <div className={cx(styles.titleWithDots)}>電話番号</div>
          {convertPhoneNumber(userAddress?.tel ?? 0)}
        </div>
        {hasMoreAction && (
          <div
            className={cx(
              styles.status,
              userAddress?.is_default ? "status-selected-color" : "status-color"
            )}
          >
            <div>{userAddress?.is_default ? "選択中" : "未選択"}</div>
          </div>
        )}
      </div>
      <ConfirmDialog
        open={isOpenConfirmDeleteDialog}
        handleCancel={() => setIsOpenConfirmDeleteDialog(false)}
        handleSubmit={handleDeleteUserAddressCard}
        title="登録済住所の削除"
        width={md ? 504 : 333}
        className={cx(styles.root)}
      >
        <div className="flex-justify-center">
          <div className={cx(styles.title)}>
            下記の住所を削除します よろしいですか？
          </div>
        </div>
        <div className={md ? "mt-7" : "mt-5"}>
          <CardAddress
            hasMoreAction={false}
            userAddress={userAddress}
            isCardInDeleteDialog
          />
        </div>
      </ConfirmDialog>
    </>
  );
};

export default memo(CardAddress);
