import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { AuthEndpointsEnum } from "../constants/auth.endpoints";
import {
  AuthUserDataDef,
  LoginDataDef,
  RefreshTokenDataDef,
  CreateUserDataDef,
  UpdateUserDataDef,
  TokenValidDataDef,
  ResetPasswordDataDef,
} from "../types/auth.types";

const loginApi = (data: LoginDataDef): Promise<AxiosResponse> => {
  const url = AuthEndpointsEnum.LOGIN;
  return api.post(url, data);
};

const getCurrentUserApi = (): Promise<AxiosResponse> => {
  const url = AuthEndpointsEnum.CURRENT_USER;
  return api.get(url);
};

const refreshTokenApi = (data: RefreshTokenDataDef): Promise<AxiosResponse> => {
  const url = AuthEndpointsEnum.REFRESH_TOKEN;
  return api.post(url, data);
};

const logoutApi = (): Promise<AxiosResponse> => {
  return api.post(AuthEndpointsEnum.LOGOUT);
};

const updateUserApi = (data: UpdateUserDataDef): Promise<AxiosResponse> => {
  const url = AuthEndpointsEnum.UPDATE_USER;
  if (data.image) {
    const formData = new FormData();
    formData.append("image", data.image);
    return api.post(url, formData, {
      headers: {
        "Content-Type": `multipart/form-data`,
        "cache-control": "no-cache",
      },
      params: {
        _method: "PATCH",
      },
      transformRequest: () => {
        return formData;
      },
    });
  }

  return api.patch(url, data);
};

const createUserApi = (data: CreateUserDataDef): Promise<AxiosResponse> => {
  const url = AuthEndpointsEnum.CREATE_USER;
  return api.post(url, data);
};

const checkTokenValidApi = (
  data: TokenValidDataDef
): Promise<AxiosResponse> => {
  const url = AuthEndpointsEnum.CHECK_TOKEN.replace(":token", data.token);
  return api.get(url);
};

const forgotPasswordApi = (data: AuthUserDataDef): Promise<AxiosResponse> => {
  const url = AuthEndpointsEnum.FORGOT_PASSWORD;
  return api.post(url, data);
};

const temporaryRegisterApi = (
  data: AuthUserDataDef
): Promise<AxiosResponse> => {
  const url = AuthEndpointsEnum.TEMPORAPY_REGISTER;
  return api.post(url, data);
};

const getUserInfoApi = (): Promise<AxiosResponse> => {
  const url = AuthEndpointsEnum.GET_USER_INFO;
  return api.get(url);
};

const resetPasswordApi = (
  data: ResetPasswordDataDef
): Promise<AxiosResponse> => {
  const url = AuthEndpointsEnum.RESET_PASSWORD;
  return api.post(url, data);
};

const checkTokenResetPasswordApi = (token: string): Promise<AxiosResponse> => {
  const url = AuthEndpointsEnum.CONFIRM_RESET_PASSWORD.replace(":token", token);
  return api.get(url);
};

const verifyEmailApi = (token: string): Promise<AxiosResponse> => {
  const url = AuthEndpointsEnum.VERIFY_EMAIL;
  return api.post(url, {
    confirmation_token: token,
  });
};

const resendConfirmationApi = (token: string): Promise<AxiosResponse> => {
  const url = AuthEndpointsEnum.RESEND_CONFIRMATION;
  return api.post(url, {
    confirmation_token: token,
  });
};

const getListSettingsApi = (): Promise<AxiosResponse> => {
  const url = AuthEndpointsEnum.GET_LIST_SETTINGS;
  return api.get(url);
};

const authApi = {
  loginApi,
  refreshTokenApi,
  logoutApi,
  getCurrentUserApi,
  updateUserApi,
  createUserApi,
  checkTokenValidApi,
  forgotPasswordApi,
  temporaryRegisterApi,
  getUserInfoApi,
  resetPasswordApi,
  checkTokenResetPasswordApi,
  verifyEmailApi,
  resendConfirmationApi,
  getListSettingsApi,
};
export default authApi;
