import { RouteComponentProps } from "react-router-dom";

import { LOCAL_STORAGE_BACK_PAGE_KEY } from "../../../constants/constants";
import { PointExchangesPathsEnum } from "./point-exchanges.paths";

export const PAYMENT_STATUS = {
  FAIL: "fail",
  SUCCESS: "success",
};

export const LIMIT_REQUEST_PAYMENT = 60;

export const handleDirectPointPage = (
  history: RouteComponentProps["history"]
) => {
  localStorage.setItem(
    LOCAL_STORAGE_BACK_PAGE_KEY,
    `${window.location.pathname}${window.location.search}`
  );
  history.push(PointExchangesPathsEnum.POINT_EXCHANGES);
};

export const METHOD_SELECTED = {
  CREDIT: 1,
  BANK: 2,
};

export const TRANSFER_BANK_STATUS = {
  OPEN: "open",
  PAID: "paid",
  FAIL: "failed",
};

export const PAYMENT_TYPE_ID = {
  PAYPAL: 3,
  CREDIT_GMO: 4,
};

export const PAYMENT_TYPE_DEFAULT = 0;

export enum PAYMENT_DEFAULT_ENUM {
  GMO,
}
