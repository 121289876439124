import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import Logo from "@app/assets/images/logo.png";
import Button from "@app/components/atoms/Button/Button";
import Image from "@app/components/atoms/Image/Image";
import { CARD_STATUS } from "@app/features/gacha-histories/constants/gacha-histories.constants";

import { ShopsPathsEnum } from "../../shops";
import styles from "./DeliverySuccessScreen.module.scss";

const DeliverySuccessScreen = () => {
  const { md } = useBreakpoint();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const { state } = useLocation<{ path: string }>();

  return (
    <div className={cx(styles.root)}>
      <div className={cx(styles.containerBox)}>
        <div className={cx(styles.container)}>
          <Link to="/">
            <Image key={Number(md)} className={cx(styles.logo)} src={Logo} />
          </Link>
          <div className={cx(styles.title)}>商品を購入しました。</div>
          <div className={md ? "font-15" : "font-11"}>
            この度はご利用いただきありがとうございました。
            <br />
            商品は原則14営業日以内に発送いたします。
            <br />
            またのご利用お待ちしております。
          </div>
        </div>
      </div>
      <div className={cx(styles.btnBox)}>
        <div className={cx(styles.btnBoxWidth)}>
          <Button
            shape="round"
            className={cx(styles.btnBig, "yellow")}
            width="100%"
            onClick={() =>
              history.push(
                `${ShopsPathsEnum.SHOP_LIST_USER_DETAILS.replace(
                  ":id",
                  id
                )}?stt=${CARD_STATUS.SOLD}`
              )
            }
          >
            獲得カード所持一覧へ
          </Button>
          <Button
            width="100%"
            shape="round"
            className={cx(styles.btnBig, "gray")}
            onClick={() => history.push(ShopsPathsEnum.SHOP_LIST)}
          >
            ＴＯＰページへ戻る
          </Button>
          {state?.path && (
            <Button
              className={cx(styles.btnBig, "gray mt-4")}
              shape="round"
              onClick={() => {
                window.location.pathname = state.path;
              }}
            >
              結果ページへ戻る
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeliverySuccessScreen;
