import { memo } from "react";

import ReactHelmet from "react-helmet";

const Helmet = ({
  title = "オリパオンラインガチャ｜ポケカ通販おすすめオリパ｜トレカボールガチャ",
  description = "有名ショップのオリジナルカードパックでポケカゲット！不要カードはポイント変換してリトライ可能。準備中のワンピース他、人気カードを随時追加致します。",
}: {
  title?: string;
  description?: string;
}) => {
  const url = window.location.href;

  return (
    <ReactHelmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />
    </ReactHelmet>
  );
};

export default memo(Helmet);
