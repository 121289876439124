import { memo } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { AuthPathsEnum, LOCATION_STATE_SUCCESS } from "../../auth";
import TemplateAuth from "../../components/TemplateAuth/TemplateAuth";
import styles from "../ForgotPasswordScreen/ForgotPasswordScreen.module.scss";

const ForgotPasswordSuccessScreen = () => {
  const location = useLocation();
  const history = useHistory();
  if (location.state !== LOCATION_STATE_SUCCESS) {
    history.push(AuthPathsEnum.FORGOT_PASSWORD);
  }

  return (
    <TemplateAuth title="パスワード再設定" className={styles.successContainer}>
      <div className={styles.successBox}>
        <div>
          {
            "ご入力いただいたメールアドレス宛に、パスワード再設定\nのメールを送信しました。"
          }
        </div>
        <div>メールの記載のURLよりパスワードの再設定を行なってください。</div>
        <div>URLリンクの有効期限は1時間となっております。</div>
        <div>有効期限が切れた場合、再度[パスワードの再設定画面]にて</div>
        <div>
          {
            "メールアドレスをご入力からやり直していただく必要がございますので\nあらかじめご了承ください。"
          }
        </div>
      </div>
    </TemplateAuth>
  );
};

export default memo(ForgotPasswordSuccessScreen);
