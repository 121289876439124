import { memo } from "react";

import cx from "classnames";

import Image from "@app/components/atoms/Image/Image";

import styles from "./Card.module.scss";

const Card = ({
  type,
  isSelected,
}: {
  type: "normal" | "gold" | "sliver" | "vip";
  isSelected?: boolean;
}) => {
  return (
    <div className={cx(isSelected ? styles.selected : styles[type])}>
      <div className="content">
        <Image src="/card.png" />
      </div>
    </div>
  );
};

export default memo(Card);
