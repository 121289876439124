import { memo } from "react";

import BankTransfer from "../../Components/BankTransfer/BankTransfer";

const BankTransferSuccessScreen = () => {
  return (
    <BankTransfer
      title="お振込が完了しました。"
      content={`振込が確認され次第1~3営業日以内でPOINTを反映いたします。\n土日祝日の振込は反映自体が遅れる可能性がありますので、あらかじめご了承ください。`}
    />
  );
};

export default memo(BankTransferSuccessScreen);
