/* eslint-disable no-nested-ternary */
/* eslint-disable no-irregular-whitespace */
import { useEffect, useState, useCallback } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Col, Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";

import LabelBighit from "@app/assets/images/LabelTypeCard/label_bighit.svg";
import LabelBighitSp from "@app/assets/images/LabelTypeCard/label_bighit_sp.svg";
import LabelHazure from "@app/assets/images/LabelTypeCard/label_hazure_card.svg";
import LabelHazureSp from "@app/assets/images/LabelTypeCard/label_hazure_sp.svg";
import LabelLastOne from "@app/assets/images/LabelTypeCard/label_last_one.svg";
import LabelLastOneSp from "@app/assets/images/LabelTypeCard/label_last_one_sp.svg";
import LabelMediumhitSp from "@app/assets/images/LabelTypeCard/label_medium_sp.svg";
import LabelMediumhit from "@app/assets/images/LabelTypeCard/label_mediumhit.svg";
import LabelSmallhit from "@app/assets/images/LabelTypeCard/label_smallhit.svg";
import LabelSmallhitSp from "@app/assets/images/LabelTypeCard/label_smallhit_sp.svg";
import NoImage from "@app/assets/images/no_image_card.png";
import CardZoom from "@app/components/atoms/CardZoom/CardZoom";
import Image from "@app/components/atoms/Image/Image";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { getNameCard, isExistCardImage } from "@app/helpers/util.helper";
import { useAppDispatch } from "@app/redux/store";

import {
  CARD_TYPE,
  getListCard,
  MAX_CARD_BIG_HIT,
  MAX_CARD_MEDIUM_HIT_PC,
  MAX_CARD_MEDIUM_HIT_SP,
  MAX_CARD_SMALL_FAIL,
} from "../../shops";
import { Card, ListCard } from "../../types/shops.types";
import ZoomDialog from "../ZoomDialog/ZoomDialog";
import styles from "./ListTypeCard.module.scss";

interface ListCardProps {
  packId: string;
  shopId: string;
}
const ListTypeCard = ({ packId, shopId }: ListCardProps) => {
  const { xs, sm, md } = useBreakpoint();

  const [isOpenZoomDialog, setIsOpenZoomDialog] = useState<boolean>(false);
  const [cardSelected, setCardSelected] = useState<Card | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasCard, setHasCard] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const [listCardLastOne, setListCardLastOne] = useState<ListCard | null>(null);
  const [listCardBigHit, setListCardBigHit] = useState<ListCard | null>(null);
  const [listCardMediumHit, setListCardMediumHit] = useState<ListCard | null>(
    null
  );
  const [listCardSmallHit, setListCardSmallHit] = useState<Card[]>([]);
  const [listCardFail, setListCardFail] = useState<Card[]>([]);

  const randomRecord = (data: ListCard) => {
    return [...data.cards].sort(() => Math.random() - 0.5);
  };

  useEffect(() => {
    if (md !== undefined) {
      setIsLoading(true);
      const fetchData = () => {
        dispatch(
          getListCard({
            shopId,
            packId,
            params: {
              types: [CARD_TYPE.LAST_ONE],
              use_page: false,
            },
          })
        )
          .then(unwrapResult)
          .then(data => {
            setListCardLastOne(data);
            setIsLoading(false);
          });
        dispatch(
          getListCard({
            shopId,
            packId,
            params: {
              types: [CARD_TYPE.BIG_HIT],
              page: 1,
              limit: MAX_CARD_BIG_HIT,
              use_page: true,
            },
          })
        )
          .then(unwrapResult)
          .then(data => {
            setListCardBigHit(data);
            setIsLoading(false);
          });
        dispatch(
          getListCard({
            shopId,
            packId,
            params: {
              types: [CARD_TYPE.MEDIUM_HIT],
              page: 1,
              limit: md ? MAX_CARD_MEDIUM_HIT_PC : MAX_CARD_MEDIUM_HIT_SP,
              use_page: true,
            },
          })
        )
          .then(unwrapResult)
          .then(data => {
            setIsLoading(false);
            setListCardMediumHit(data);
          });
        dispatch(
          getListCard({
            shopId,
            packId,
            params: {
              types: [CARD_TYPE.SMALL_HIT],
              page: 1,
              use_page: true,
              limit: MAX_CARD_SMALL_FAIL,
            },
          })
        )
          .then(unwrapResult)
          .then(data => {
            setIsLoading(false);
            setListCardSmallHit(randomRecord(data));
          });
        dispatch(
          getListCard({
            shopId,
            packId,
            params: {
              types: [CARD_TYPE.FAIL],
              page: 1,
              use_page: true,
              limit: MAX_CARD_SMALL_FAIL,
            },
          })
        )
          .then(unwrapResult)
          .then(data => {
            setIsLoading(false);
            setListCardFail(data.cards);
          });
      };

      dispatch(
        getListCard({
          shopId,
          packId,
          params: {
            page: 1,
            use_page: true,
          },
        })
      )
        .then(unwrapResult)
        .then(data => {
          if (data.cards?.length) {
            fetchData();
            setHasCard(true);
          } else {
            setHasCard(false);
            setIsLoading(false);
          }
        });
    }
  }, [dispatch, md, packId, shopId]);

  const fetchMoreCardMediumhitData = useCallback(() => {
    if (!listCardMediumHit?.cards.length) return;
    const cardMediumLimit = md
      ? MAX_CARD_MEDIUM_HIT_PC
      : MAX_CARD_MEDIUM_HIT_SP;
    dispatch(
      getListCard({
        shopId,
        packId,
        params: {
          types: [CARD_TYPE.MEDIUM_HIT],
          page:
            Math.round(listCardMediumHit?.cards.length / cardMediumLimit) + 1,
          limit: cardMediumLimit,
          use_page: true,
        },
      })
    )
      .then(unwrapResult)
      .then(data => {
        setListCardMediumHit({
          ...listCardMediumHit,
          cards: [...(listCardMediumHit?.cards ?? []), ...data.cards],
        } as ListCard);
      });
  }, [listCardMediumHit, packId, shopId, md, dispatch]);

  const fetchMoreCardBighitData = useCallback(() => {
    if (!listCardBigHit?.cards.length) return;
    dispatch(
      getListCard({
        shopId,
        packId,
        params: {
          types: [CARD_TYPE.BIG_HIT],
          page: Math.round(listCardBigHit?.cards.length / MAX_CARD_BIG_HIT) + 1,
          limit: MAX_CARD_BIG_HIT,
          use_page: true,
        },
      })
    )
      .then(unwrapResult)
      .then(data => {
        setListCardBigHit({
          ...listCardBigHit,
          cards: [...(listCardBigHit?.cards ?? []), ...data.cards],
        } as ListCard);
      });
  }, [dispatch, listCardBigHit, packId, shopId]);

  return (
    <>
      <div className={cx(styles.root)}>
        <div className={cx(styles.containerBox)}>
          <div className={cx(styles.container)}>
            {!isLoading ? (
              <>
                {!hasCard ? (
                  <div>
                    <p
                      className={`text-center text-primary font-${
                        md ? 16 : 14
                      } mt-15`}
                    >
                      検索結果がありません。
                    </p>
                  </div>
                ) : (
                  <>
                    {!!listCardLastOne?.cards?.length && (
                      <>
                        <div
                          className={cx(
                            "text-center",
                            sm ? "mt-4 mb-6" : "my-3"
                          )}
                        >
                          <Image src={xs ? LabelLastOneSp : LabelLastOne} />
                        </div>

                        <Row
                          className="full-width"
                          gutter={[{ xs: 2, md: 14 }, 0]}
                          justify="center"
                        >
                          {listCardLastOne?.cards?.map(item => (
                            <Col
                              className="flex-justify-center"
                              key={item.id}
                              span={3}
                            >
                              <CardZoom
                                isShowButtonZoom={isExistCardImage(
                                  item.card_images,
                                  item.type
                                )}
                                className="big"
                                imageUrl={item.image_url}
                                title={getNameCard(item, true)}
                                handleZoomCard={() => {
                                  setIsOpenZoomDialog(true);
                                  setCardSelected(item);
                                }}
                              />
                            </Col>
                          ))}
                        </Row>
                      </>
                    )}
                    {!!listCardBigHit?.cards?.length && (
                      <>
                        <div
                          className={cx(
                            "text-center",
                            sm ? "mt-4 mb-6" : "my-3"
                          )}
                        >
                          <Image src={xs ? LabelBighitSp : LabelBighit} />
                        </div>
                        <InfiniteScroll
                          scrollThreshold={0.3}
                          dataLength={listCardBigHit?.cards.length ?? 0}
                          next={fetchMoreCardBighitData}
                          hasMore={
                            listCardBigHit?.cards?.length <
                            listCardBigHit?.page_info?.total_count
                          }
                          loader={<LoadingSpinner />}
                        >
                          <div className={cx(styles.bigCardBox)}>
                            <Row
                              className="full-width"
                              gutter={[{ xs: 2, md: 14 }, 0]}
                              justify="center"
                            >
                              {listCardBigHit?.cards?.map(item => (
                                <Col
                                  className="flex-justify-center"
                                  key={item.id}
                                  span={3}
                                >
                                  <CardZoom
                                    isShowButtonZoom={isExistCardImage(
                                      item.card_images,
                                      item.type
                                    )}
                                    className="big"
                                    imageUrl={item.image_url}
                                    title={getNameCard(item, true)}
                                    handleZoomCard={() => {
                                      setIsOpenZoomDialog(true);
                                      setCardSelected(item);
                                    }}
                                  />
                                </Col>
                              ))}
                            </Row>
                          </div>
                        </InfiniteScroll>
                      </>
                    )}
                    {!!listCardMediumHit?.cards?.length && (
                      <>
                        <div
                          className={cx(
                            "text-center",
                            sm ? "mt-6 mb-11" : "mt-3 mb-4"
                          )}
                        >
                          <Image src={xs ? LabelMediumhitSp : LabelMediumhit} />
                        </div>
                        <InfiniteScroll
                          scrollThreshold={0.3}
                          dataLength={listCardMediumHit?.cards.length ?? 0}
                          next={fetchMoreCardMediumhitData}
                          hasMore={
                            listCardMediumHit?.cards?.length <
                            listCardMediumHit?.page_info?.total_count
                          }
                          loader={<LoadingSpinner />}
                        >
                          <Row
                            gutter={[{ xs: 2, md: 6 }, 0]}
                            className={cx(styles.bigCardBox)}
                          >
                            {listCardMediumHit?.cards?.map(item => (
                              <Col
                                span={xs ? 6 : 7}
                                className={cx(styles.mediumCardImgBox)}
                                key={item.id}
                              >
                                <CardZoom
                                  isShowButtonZoom={isExistCardImage(
                                    item.card_images,
                                    item.type
                                  )}
                                  className="medium"
                                  title={getNameCard(item)}
                                  imageUrl={item.image_url}
                                  handleZoomCard={() => {
                                    setIsOpenZoomDialog(true);
                                    setCardSelected(item);
                                  }}
                                />
                              </Col>
                            ))}
                          </Row>
                        </InfiniteScroll>
                      </>
                    )}

                    {!!listCardSmallHit?.length && (
                      <>
                        <div
                          className={cx(
                            "text-center",
                            sm ? "mt-4 mb-6" : "mt-1 mb-4"
                          )}
                        >
                          <Image src={xs ? LabelSmallhitSp : LabelSmallhit} />
                        </div>
                        <div className={cx(styles.cardBoxOverFlow)}>
                          {listCardSmallHit?.map(item => (
                            <Image
                              key={item.id}
                              className={cx(styles.smallCardImg)}
                              src={item.image_url ?? NoImage}
                            />
                          ))}
                        </div>
                      </>
                    )}
                    {!!listCardFail?.length && (
                      <>
                        <div
                          className={cx("text-center", sm ? "mb-6" : "mb-4")}
                        >
                          <Image src={xs ? LabelHazureSp : LabelHazure} />
                        </div>
                        <div className={cx(styles.cardBoxOverFlow, "mb-2")}>
                          {listCardFail?.map(item => (
                            <Image
                              key={item.id}
                              className={cx(styles.smallCardImg)}
                              src={item.image_url ?? NoImage}
                            />
                          ))}
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </div>
      </div>
      {cardSelected && isOpenZoomDialog && (
        <ZoomDialog
          isOpenDialog={isOpenZoomDialog}
          handleCancel={() => setIsOpenZoomDialog(false)}
          card={cardSelected}
        />
      )}
    </>
  );
};

export default ListTypeCard;
