/* eslint-disable @typescript-eslint/no-explicit-any */
import camelCase from "lodash.camelcase";

export type ObjectType = {
  [key: string]: unknown;
};
export const isArray = (d: any) => Array.isArray(d);
export const isObject = (d: any) =>
  d === Object(d) && !isArray(d) && typeof d !== "function";

const toCamelCase = (d: any, filter?: boolean) => {
  if (isObject(d)) {
    const o: any = {};
    Object.keys(d).forEach(k => {
      o[camelCase(k)] = toCamelCase(d[k], true);
    });

    return o;
  }
  if (isArray(d)) {
    return d.map((o: any) => toCamelCase(o, true));
  }

  if (filter && d === "") {
    return null;
  }

  return d;
};

export default toCamelCase;
