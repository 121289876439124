/* eslint-disable no-irregular-whitespace */
import { Typography } from "antd";
import cx from "classnames";

import Helmet from "@app/components/molecules/Helmet/Helmet";
import { PAGE_INFO } from "@app/constants/constants";

import styles from "../StaticPage.module.scss";

const PrivacyPolicyScreen = () => {
  return (
    <>
      <Helmet
        title={PAGE_INFO["UA-0051"].title}
        description={PAGE_INFO["UA-0051"].description}
      />
      <div className={cx(styles.root, "container pt-5 pb-10")}>
        <Typography.Title level={2}>プライバシーポリシー</Typography.Title>
        <Typography>
          本プライバシーポリシーは、株式会社Aja State（以下「当社」といいま
          す。）が取得する個人情報の取り扱いについて定めています。当社は、本プラ
          イバシーポリシー、
          個人情報の保護に関する法律（以下「個人情報保護法」と
          いいます。）、関係法令及び
          ガイドライン等を遵守し、個人情報を適切に取り 扱います。
        </Typography>
        <Typography.Title level={3}>1. 個人情報の取得について</Typography.Title>
        <Typography>
          当社は、当社サービスの提供その他事業活動に際し、当社サービスの利用者（以下
          「ユーザー」といいます。）その他の個人から、適正に個人情報を取得いたします。
          <br />
          個人情報の取得にあたっては、利用目的を明確にし、偽りその他不正の手段により個人
          情報を取得することはいたしません。
        </Typography>
        <Typography.Title level={3}>2. 個人情報の利用について</Typography.Title>
        <Typography>
          ユーザーの個人情報は次に定める目的を達成するために必要な範囲で利用し、当該目的
          以外で利用することはいたしません。当社は、以下に定められていない目的で個人情報
          を利用する場合、あらかじめご本人の同意を得た上で行います。
        </Typography>
        <ol className="mt-4">
          <li>当社サービスを提供するため</li>
          <li>本人確認又は本人確認のための認証サービスを利用するため</li>
          <li>
            当社サービスにおけるお客様へのお支払い又はお客様への利用料金の請求のため
          </li>
          <li>ご意見、ご要望、お問い合わせなどに対応するため</li>
          <li>
            当社サービスのアンケート、キャンペーン、イベントなどを実施するため
          </li>
          <li>当社又は当社に広告を委託した第三者の広告を実施するため</li>
          <li>
            当社サービスの利用状況、実施施策などマーケティング調査、分析のため
          </li>
          <li>当社サービスの品質改善、サービス向上のための調査、分析のため</li>
          <li>当社の新サービス、新機能を企画するための調査、分析のため</li>
          <li>
            当社サービスのメンテナンス、重要なお知らせ等の必要に応じた連絡の
            ため
          </li>
          <li>
            当社サービス又はネットワーク等のシステム障害、不具合、事故発生時
            の調査及び対応のため
          </li>
          <li>本サービス利用によるユーザー間のトラブル解決に利用するため</li>
          <li>
            クレジットカードの第三者による不正利用の調査又は、刑事事件による調査及び対応のため
          </li>
        </ol>
        <Typography.Title level={3}>
          3. 個人情報の安全管理について
        </Typography.Title>
        <Typography>
          当社は、個人情報を正確かつ最新の内容に保つよう努めるとともに、合理的かつ適切な
          安全管理措置を講じるよう努めます。
        </Typography>
        <Typography.Title level={3}>
          4. 個人情報の委託・共同利用について
        </Typography.Title>
        <Typography>
          当社は、利用目的の達成に必要な範囲内において、個人情報の取扱いの全部又は一部を
          第三者に委託する場合があります。この場合、当社は、委託先としての適格性を十分審
          査するとともに、契約にあたって守秘義務に関する事項等を定め、委託先に対する必要
          かつ適切な監督を行います。
          また、当社は、個人情報の全部又は一部を個人情報保護
          法の定めに基づいて共同利用することがあります。
        </Typography>
        <Typography.Title level={3}>
          5. 個人情報の第三者提供について
        </Typography.Title>
        <Typography>
          当社は、次に定める場合を除き、個人情報をあらかじめご本人の同意を得ることなく、
          第三者に提供いたしません。
        </Typography>
        <ol className="mt-4">
          <li>
            人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を
            得ることが困難である場合
          </li>
          <li>
            公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であっ
            て、本人の同意を得ることが困難である場合
          </li>
          <li>
            国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂
            行することに対して協力する必要がある場合であって、本人の同意を得ることに
            より当該事務の遂行に支障を及ぼすおそれがある場合
          </li>
          <li>
            業務を円滑に遂行するため、利用目的の達成に必要な範囲内において個人情報の
            取扱いの全部又は一部を委託する場合
          </li>
          <li>
            合併その他の事由による事業の承継に伴って個人情報が提供される場合
          </li>
          <li>
            個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同
            して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用
            目的及び当該個人情報の管理について責任を有する者の氏名又は名称について、
            あらかじめ本人に通知し、又は本人が容易に知り得る状態に置いた場合
          </li>
          <li>その他法令で認められる場合</li>
        </ol>
        <Typography.Title level={3}>
          6. 個人情報の開示・訂正等について
        </Typography.Title>
        <Typography>
          当社は、ご本人から自己の個人情報についての開示の請求がある場合、速やかに開示を
          いたします。但し、開示することにより次のいずれかに該当する場合は、その全部又は
          一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知
          します。
        </Typography>
        <ol className="mt-4">
          <li>
            ご本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
          </li>
          <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
          <li>その他法令に違反することとなる場合</li>
        </ol>
        <Typography>
          個人情報の内容に誤りがあり、ご本人から訂正、追加又は削除の請求がある場合、調査
          の上、速やかにこれらの請求に対応いたします。当社の個人情報の取り扱いにつきまして、上記の請求、お問い合わせ等がございましたら、後記のお問い合わせ先までご連
          絡くださいますようお願い申し上げます。その際、ご本人であることが確認できない場
          合には、上記の請求には応じられません。
        </Typography>
        <Typography.Title level={3}>7. 個人情報の利用停止等</Typography.Title>
        <Typography>
          当社は、ご本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理
          由又は不正の手段により取得されたものであるという理由により、その利用の停止又は
          消去を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報
          の利用停止等を行い、その旨ご本人に通知します。但し、個人情報の利用停止等に多額
          の費用を有する場合その他利用停止等を行うことが困難な場合であって、ご本人の権利
          利益を保護するために必要なこれに代わるべき措置をとることができる場合は当該代替
          策を講じます。
        </Typography>
        <Typography.Title level={3}>
          8. プライバシーポリシーの変更
        </Typography.Title>
        <Typography>
          本方針のプライバシーポリシーは改定する場合があります。プライバシーポリシーを改
          定した場合には、その旨を当社サイト上で告知しますので、定期的にご確認下さいます
          ようお願いいたします。
          変更後の本方針については、当社が別途定める場合を除いて、
          当サイトに掲載した時から効力を生じるものとします。
        </Typography>
        <Typography.Title level={3}>9. お問い合わせ先</Typography.Title>
        <Typography>
          当社の個人情報の取扱いに関するお問い合わせは下記までご連絡ください。
        </Typography>
        <Typography className="mt-4">
          トレカボール
          <br />
          住所記載　811-0001
          <br />
          福岡県福岡市中央天神３丁目6番30号　天神ふじビル2階
          <br />
          TEL　08091453873
          <br />
          お問い合わせは こちら
          <br />
          2024年1月18日制定
        </Typography>
      </div>
    </>
  );
};

export default PrivacyPolicyScreen;
