import { memo } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { AuthPathsEnum, LOCATION_STATE_SUCCESS } from "../../auth";
import TemplateAuth from "../../components/TemplateAuth/TemplateAuth";
import styles from "../TemporaryRegisterScreen/TemporaryRegisterScreen.module.scss";

const TemporaryRegisterSuccessScreen = () => {
  const location = useLocation();
  const history = useHistory();

  if (location.state !== LOCATION_STATE_SUCCESS) {
    history.push(AuthPathsEnum.TEMPORAPY_REGISTER);
  }

  return (
    <TemplateAuth title="仮登録完了" className={styles.successContainer}>
      <div className={styles.successBox}>
        <div>
          {
            "ご入力いただいたメールアドレス宛てに、\n仮登録のメールを送信いたしました。"
          }
        </div>
        <div>※会員登録は完了しておりませんのでご注意ください。</div>
        <br />
        <div>URLリンクの有効期限は1時間となっております。</div>
        <div>有効期限が切れた場合、再度[パスワードの再設定画面]にて</div>
        <div>
          メールアドレスをご入力からやり直していただく必要がございますので
        </div>
        <div>あらかじめご了承ください。</div>
        <br />
        <div>
          {
            "※メールが届かない場合は、ご登録の際に入力されたメールアドレス\nが誤っている可能性があります。"
          }
        </div>
        <div>
          {
            "しばらく経ってもメールが届かない場合は、\nアドレスをご確認のうえ再度登録手続きをしてください。"
          }
        </div>
        <div>メールに記載のURLよりパスワードの再設定を行ってください。</div>
      </div>
    </TemplateAuth>
  );
};

export default memo(TemporaryRegisterSuccessScreen);
