export enum AuthEndpointsEnum {
  LOGIN = "/login",
  REFRESH_TOKEN = "/login",
  LOGOUT = "/logout",
  CURRENT_USER = "/users/current",
  CREATE_USER = "/users/create",
  UPDATE_USER = "/users",
  CHECK_TOKEN = "/users/confirmation/:token",
  FORGOT_PASSWORD = "/send-reset-password",
  TEMPORAPY_REGISTER = "/users/temporary",
  GET_USER_INFO = "/users/mypage",
  RESET_PASSWORD = "/reset-password",
  CONFIRM_RESET_PASSWORD = "/reset-password/:token",
  VERIFY_EMAIL = "/users/verify",
  RESEND_CONFIRMATION = "/users/resend-confirmation",
  GET_LIST_SETTINGS = "/settings",
}
